import { IObjectDef } from "./../types/object";

export function getObjectsById(objects: IObjectDef[] | undefined): Record<string, IObjectDef> {
  return (objects ?? []).reduce((acc: Record<string, IObjectDef>, obj: IObjectDef) => {
    return {
      ...acc,
      [obj.resource_id]: obj,
    };
  }, {});
}

export function getObjectsFromIds(objectIds: string[], objectMap: Record<string, IObjectDef>): IObjectDef[] {
  return objectIds.flatMap((objectId: string) => {
    return objectMap[objectId] ? [objectMap[objectId]] : [];
  });
}
