export interface IDashboardUIContext {
  contentSizes: IDashboardContentSizes;
  contentRenderTypes: IDashboardContentRenderTypes;
}

export interface IDashboardContentSizes {
  [reportId: string]: DashboardContentSize;
}

export enum DashboardContentSize {
  SMALL = "small",
  MEDIUM = "medium",
  MEDIUM_HALF = "medium_half",
  MEDIUM_TWO_THIRDS = "medium_two_thirds",
  LARGE = "large",
}

export interface IDashboardContentRenderTypes {
  [reportId: string]: DashboardContentRenderType;
}

export enum DashboardContentRenderType {
  CHART = "chart",
  TABLE = "table",
}
