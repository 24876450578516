import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { ITimestampMetadata } from "../types/common";
import { ILastSeenFilter } from "./../types/recordFilters";

dayjs.extend(isBetween);

export function isWithinLastSeenFilter(record: ITimestampMetadata, filter: ILastSeenFilter): boolean {
  if (filter.last_seen_gte && filter.last_seen_lte) {
    return dayjs(record.updated_at).isBetween(dayjs(filter.last_seen_gte), dayjs(filter.last_seen_lte));
  } else if (filter.last_seen_lte) {
    return dayjs(record.updated_at).isBefore(dayjs(filter.last_seen_lte));
  } else if (filter.last_seen_gte) {
    return dayjs(record.updated_at).isAfter(dayjs(filter.last_seen_gte));
  } else {
    return true;
  }
}
