import { IObjectDef } from "../types/object";
import { IAliasedExpression, IExpression } from "./../conversions/ReportConversions";
import { IColumnSettings, IReportQuery } from "./../types/report";

export function getAllExpressionsFromReportQuery(query: Partial<IReportQuery> | undefined): IExpression[] {
  return [...(query?.row_grouping ?? []), ...(query?.col_grouping ?? []), ...(query?.projections ?? []), ...(query?.rowFilters ?? [])];
}

export function getAllAliasedExpressionsFromReportQuery(query: Partial<IReportQuery> | undefined): IAliasedExpression[] {
  return [...(query?.row_grouping ?? []), ...(query?.col_grouping ?? []), ...(query?.projections ?? [])];
}

export function buildExpressionNameLookup(allColumnSettings: Record<string, IColumnSettings>): Record<string, string> {
  return Object.entries(allColumnSettings).reduce((acc: Record<string, string>, [alias, columnSettings]: [string, IColumnSettings]) => {
    if (columnSettings.displayName) {
      acc[alias] = columnSettings.displayName;
    }
    return acc;
  }, {});
}

export function buildReportTitleFromQuery(query: IReportQuery, object: IObjectDef): string {
  const groupByAliases: string[] = query.row_grouping.flatMap((group: IAliasedExpression) => (group.alias ? [group.alias] : []));
  if (groupByAliases.length > 0) {
    return groupByAliases[groupByAliases.length - 1];
  } else {
    return object.resource_name;
  }
}
