import { IAccountWithAppInstances, IBaseAccountWithAppInstances } from "./../types/settings";
import { Identifier } from "react-admin";
import { AllDash } from "../types";
import { IBasePlatformAppInstance, IPlatformAppInstance } from "../types/settings";
import { Conversions } from "./Conversions";

//-------------------------------------------------------------------------------
// Response Interfaces
//-------------------------------------------------------------------------------

export interface IGetPlatformAppInstancesResponse {
  accounts_with_app_instances: IBaseAccountWithAppInstances[];
}

export interface ICreatePlatformAppInstanceResponse {
  app_instance_create: IBasePlatformAppInstance;
}

//-------------------------------------------------------------------------------
// Request Interfaces
//-------------------------------------------------------------------------------

export interface ICreatePlatformAppInstanceRequest {
  payload: Partial<IBasePlatformAppInstance>;
}

export interface IUpdatePlatformAppInstanceRequest {
  resource_id: Identifier;
  payload: Partial<IPlatformAppInstance>;
}

interface IDeletePlatformAppInstanceRequest {
  resource_id: Identifier;
}

//-------------------------------------------------------------------------------
// Conversions
//-------------------------------------------------------------------------------

export class PlatformAppInstanceConversions implements Conversions {
  public fromResponseMany(response: IGetPlatformAppInstancesResponse): IAccountWithAppInstances[] {
    return (response.accounts_with_app_instances ?? []).map((accountWithAppInstance: IBaseAccountWithAppInstances) => {
      return {
        ...accountWithAppInstance,
        id: accountWithAppInstance.account_id,
      };
    });
  }

  public fromResponse(response: IBaseAccountWithAppInstances): IAccountWithAppInstances {
    return {
      ...response,
      id: response.account_id,
    };
  }

  public fromCreateResponse(response: IBasePlatformAppInstance[]): IPlatformAppInstance {
    return response.map((appInstance: IBasePlatformAppInstance) => {
      return {
        ...appInstance,
        id: appInstance.resource_id,
      };
    })[0];
  }

  public toRequest(platformAppInstance: IPlatformAppInstance): IBasePlatformAppInstance {
    return platformAppInstance;
  }

  public toUpdateRequest(id: Identifier, dashboard: Partial<IPlatformAppInstance>): IUpdatePlatformAppInstanceRequest {
    return {
      resource_id: id,
      payload: this.toRequestPartial(dashboard),
    };
  }

  public toCreateRequest(platformAppInstance: Partial<IPlatformAppInstance>): ICreatePlatformAppInstanceRequest {
    return {
      payload: this.toRequestPartial(platformAppInstance),
    };
  }

  public toDeleteRequest(id: Identifier): IDeletePlatformAppInstanceRequest {
    return {
      resource_id: id,
    };
  }

  private toRequestPartial(platformAppInstance: Partial<IPlatformAppInstance>): Partial<IPlatformAppInstance> {
    return platformAppInstance;
  }
}
