import { adminUrl, analyticsUrl, jobOwnersUrl, matchesUrl } from "findem-helpers";
import { fetchUtils, GetManyParams, Identifier, Options, RaRecord } from "react-admin";
import { USER_IMPERSONATION_QUERY_PARAM } from "../components/UserImpersonation/UserImpersonationSwitcher";
import { IFilterValuesResponse } from "../types/filterValues";
import { IBasePlatformAcl, IOrgPrefs, IPlatformAcl } from "../types/settings";
import { IAtsUser } from "../types/users";
import { IBaseOktaSSOConfig, IBaseOrgPrefs, IOktaSSOConfig } from "./../types/settings";

export const apiUrl = `${analyticsUrl}/analytics/api`;
export const httpClient = fetchUtils.fetchJson;

const REQUEST_OPTIONS: Options = {
  credentials: "include",
  redirect: "manual",
};

export default {
  getAcls: async (): Promise<{ data: IPlatformAcl[]; total: number }> => {
    return httpClient(`${adminUrl}/admin/api/roles`, REQUEST_OPTIONS).then(({ json }: { json: IBasePlatformAcl[] }) => {
      return {
        data: json
          .map((baseAcl: IBasePlatformAcl) => {
            return {
              ...baseAcl,
              id: baseAcl._id,
            };
          })
          .filter((acl: IPlatformAcl, index: number, self: IPlatformAcl[]) => {
            return (
              index ===
              self.findIndex((value: IPlatformAcl) => {
                return acl.role_name == value.role_name;
              })
            );
          }),
        total: json.length,
      };
    });
  },
  getOrgPrefs: async (joid?: string): Promise<{ data: IOrgPrefs }> => {
    return httpClient(`${jobOwnersUrl}/hm/api/org/prefs${joid ? `?joid=${joid}` : ""}`, REQUEST_OPTIONS).then(({ json }: { json: IBaseOrgPrefs }) => {
      return {
        data: { ...json, id: json.account_id },
      };
    });
  },
  getOktaSSOConfig: async (): Promise<{ data: IOktaSSOConfig | RaRecord }> => {
    return httpClient(`${matchesUrl}/auth/okta-sso-configs?r=${Math.floor(Math.random() * 10000)}`, REQUEST_OPTIONS).then(({ json }: { json: IBaseOktaSSOConfig | null }) => {
      return {
        data: json ? { ...json, id: json.client_id } : { id: "" },
      };
    });
  },
  getAtsUsers: async (): Promise<{ data: IAtsUser[]; total: number }> => {
    const searchParams: URLSearchParams = new URLSearchParams(location.search);
    const impersonationUser: string | null = searchParams.get(USER_IMPERSONATION_QUERY_PARAM);

    return httpClient(`${matchesUrl}/hm/api/ats-analytics${impersonationUser ? `?joid=${impersonationUser}` : ""}`, {
      method: "POST",
      credentials: "include",
    }).then(({ json }: { json: { all_recruiters: IAtsUser[] } }) => {
      const uniqueRecruiters: IAtsUser[] = Object.values(
        (json?.all_recruiters ?? []).reduce((acc: Record<string, IAtsUser>, recruiter: IAtsUser) => {
          if (!acc[recruiter.id] || (!acc[recruiter.id].findem_uid && recruiter.findem_uid)) {
            acc[recruiter.id] = recruiter;
          }
          return acc;
        }, {})
      );
      return { data: uniqueRecruiters, total: uniqueRecruiters.length };
    });
  },
  getFilterValues: async (params: GetManyParams): Promise<{ data: IFilterValuesResponse[] }> => {
    return await Promise.all(
      params.ids.map(async (id: Identifier) => {
        return httpClient(`${analyticsUrl}/analytics/api/helpers/${id}`, {
          method: "GET",
          ...REQUEST_OPTIONS,
        }).then(({ json }: { json: IFilterValuesResponse }) => json);
      })
    ).then((responses: IFilterValuesResponse[]) => {
      return {
        data: [
          responses.reduce(
            (acc: IFilterValuesResponse, current: IFilterValuesResponse) => {
              acc.object_filter_vals.push(...current.object_filter_vals);
              return acc;
            },
            { object_filter_vals: [], id: "" }
          ),
        ],
      };
    });
  },
};
