import { IInsightsReport, IInsightsStudy } from "../../insights/insightsTypes";
import { InsightsBaseConversions } from "./InsightsBaseConversions";

export class StudyConversions extends InsightsBaseConversions<IInsightsStudy> {
  public fromResponseMany(response: { studies: IInsightsStudy[] }): IInsightsStudy[] {
    return (response.studies ?? []).map((resource: IInsightsStudy) => {
      return this.fromResponse(resource);
    });
  }

  public fromResponse(response: IInsightsStudy): IInsightsStudy {
    return {
      ...response,
      id: response.resource_id,
      study_sections: response.study_sections.map((section) => {
        return {
          ...section,
          section_reports: (section.section_reports ?? []).map((value: any) => {
            try {
              return JSON.parse(value);
            } catch (e) {
              return value;
            }
          }),
        };
      }),
    };
  }

  public toRequestPartial(request: Partial<IInsightsStudy>): Partial<IInsightsStudy> {
    return {
      ...request,
      id: request.resource_id,
      ...(request.study_sections && {
        study_sections: request.study_sections.map((section) => {
          return {
            ...section,
            section_reports: (section.section_reports ?? []).map((value: any) => {
              try {
                return JSON.stringify(value);
              } catch (e) {
                return value;
              }
            }),
          };
        }),
      }),
    };
  }

  public fromCreateResponse(response: { study_create: IInsightsStudy }): IInsightsStudy {
    return this.fromResponse(response.study_create);
  }
}
