import {
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  fetchUtils,
  GetListParams,
  GetManyParams,
  GetOneParams,
  Identifier,
  RaRecord,
  UpdateManyParams,
  UpdateParams,
} from "react-admin";
import { Resources } from "../types/resources";
import { analyticsUrl } from "findem-helpers";
import analyticsDataProviderInternal from "./analyticsDataProviderInternal";

//------------------------------------------------------------------------------------------------------------------------------------
// Constants
//------------------------------------------------------------------------------------------------------------------------------------

export const IGNORE_IMPERSONATION_FLAG: string = "ignore_impersonation";
export const ALLOW_INACTIVE_USER_IMPERSONATION_FLAG: string = "allow_inactive_user_impersonation";

export const apiUrl = `${analyticsUrl}/analytics/api`;
export const httpClient = fetchUtils.fetchJson;

//------------------------------------------------------------------------------------------------------------------------------------
// Primary Functions
//------------------------------------------------------------------------------------------------------------------------------------

export default {
  //----------------------------------------------------------------------------------------------------------------------
  // Create
  //----------------------------------------------------------------------------------------------------------------------

  create: async (resource: Resources, params: CreateParams): Promise<{ data: RaRecord }> =>
    analyticsDataProviderInternal.create(resource, params).then((record: RaRecord) => ({ data: record })),

  //----------------------------------------------------------------------------------------------------------------------
  // Read
  //----------------------------------------------------------------------------------------------------------------------

  getOne: async (resource: Resources, params: GetOneParams): Promise<{ data: RaRecord }> =>
    analyticsDataProviderInternal.getOne(resource, params).then((record: RaRecord) => ({ data: record })),

  getMany: async (resource: Resources, params: GetManyParams): Promise<{ data: RaRecord[] }> =>
    analyticsDataProviderInternal.getMany(resource, params).then((records: RaRecord[]) => ({ data: records })),

  getList: async (resource: Resources, params: GetListParams): Promise<{ data: RaRecord[]; total: number }> =>
    analyticsDataProviderInternal.getList(resource, params).then((records: RaRecord[]) => ({
      data: records,
      total: records.length,
    })),

  //----------------------------------------------------------------------------------------------------------------------
  // Update
  //----------------------------------------------------------------------------------------------------------------------

  update: async (resource: Resources, params: UpdateParams): Promise<{ data: RaRecord }> =>
    analyticsDataProviderInternal.update(resource, params).then((record: RaRecord) => ({ data: record })),

  updateMany: async (resource: Resources, params: UpdateManyParams): Promise<{ data: RaRecord[] }> =>
    analyticsDataProviderInternal.updateMany(resource, params).then((records: RaRecord[]) => ({ data: records })),

  //----------------------------------------------------------------------------------------------------------------------
  // Delete
  //----------------------------------------------------------------------------------------------------------------------

  delete: async (resource: Resources, params: DeleteParams): Promise<{ data: Identifier }> =>
    analyticsDataProviderInternal.delete(resource, params).then((identifier: Identifier) => ({ data: identifier })),

  deleteMany: async (resource: Resources, params: DeleteManyParams): Promise<{ data: { id: Identifier }[] }> =>
    analyticsDataProviderInternal.deleteMany(resource, params).then((identifiers: Identifier[]) => ({ data: identifiers.map((id: Identifier) => ({ id })) })),
};
