import { InputBaseComponentProps, InputLabelProps, Select, TextField, TextFieldProps, Typography, TypographyProps } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { TextField as RaTextField, SelectInput, SelectInputProps, TextInput, TextInputProps } from "react-admin";
import SortableList from "react-easy-sort";
import CsvDownloadButton, { CsvDownloadProps } from "react-json-to-csv";
import styled, { css } from "styled-components";
import { colors } from "../constants";
import React from "react";

export const DEFAULT_TEXT_INPUT_PROPS: InputBaseComponentProps = {
  autoComplete: "off",
  form: {
    autoComplete: "off",
  },
};

export const DEFAULT_TEXT_INPUT_LABEL_PROPS: Partial<InputLabelProps> = { shrink: true };

export const SubtleTextInput = styled(TextInput)<TextInputProps>`
  display: flex;
  flex: 1 1 auto;
  margin-right: 2rem;

  label.Mui-disabled {
    display: none;
  }

  .Mui-disabled {
    fieldset {
      border: none;
    }
  }

  p.Mui-disabled {
    display: none;
  }
`;

export const SubtleSelectInput = styled(SelectInput)<SelectInputProps>`
  min-width: unset;

  label {
    display: none;
  }

  fieldset {
    top: 0;

    legend {
      display: none;
    }
  }

  p {
    display: none;
  }
`;

export const SubtleSelect = styled(Select)`
  .MuiSelect-select.MuiSelect-select {
    font-weight: 500;
    padding: 4px 24px 4px 8px;
  }

  &:hover {
    background-color: #ececec;
  }

  .MuiSelect-icon {
    right: 2px;
  }

  min-width: unset;

  label {
    display: none;
  }

  fieldset {
    display: none;

    legend {
      display: none;
    }
  }
`;

export const TitleTextInput = styled(SubtleTextInput)<TextInputProps>`
  input {
    font-size: 24px;
    font-weight: 500;

    &:disabled {
      color: rgba(0, 0, 0, 0.87);
      -webkit-text-fill-color: inherit;
    }
  }
`;

export const SubtitleTextInput = styled(SubtleTextInput)<TextInputProps>`
  input {
    color: #5b5b5b;
    font-size: 16px;

    &:disabled {
      color: #5b5b5b;
      -webkit-text-fill-color: inherit;
    }
  }
`;

export const SearchableTextField = styled(TextField)<TextFieldProps>`
  fieldset {
    border-radius: 20px;
    color: ${colors["grey-500"]};
  }
`;

export const SelectableText = styled(Typography)<TypographyProps>`
  cursor: pointer;
  padding: 0.8rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;

  font-size: 14px;

  &:hover {
    background-color: #ececec;
  }
`;

export const SelectableRaTextField = styled(RaTextField)`
  cursor: pointer;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;

  font-size: 14px;

  &:hover {
    background-color: #ececec;
  }
`;

export const BorderlessTextField = styled(TextField)<TextFieldProps>`
  display: flex;
  flex: 1 1 auto;

  font-size: 14px;

  input {
    font-size: 14px;
  }

  fieldset {
    border: none;
  }
`;

export const StyledDataGrid = styled(DataGrid)<{
  $hasBulkActions: boolean;
}>`
  display: flex,
  flex: 1 1 auto;

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .MuiDataGrid-columnHeaders {
    background-color: ${colors["grey-100"]};
  }

  .MuiTablePagination-displayedRows {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .MuiTablePagination-selectLabel {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .MuiDataGrid-columnHeaders {
    height: 60px !important;
    min-height: 60px !important;
    max-height: 60px !important;

    margin-bottom: 4px;
  }

  .MuiDataGrid-virtualScroller {
    margin-top: 60px !important;
  }

  ${({ $hasBulkActions }) =>
    $hasBulkActions &&
    css`
      .MuiDataGrid-columnHeaders {
        display: none;
      }

      .MuiDataGrid-virtualScroller {
        margin-top: 0 !important;
      }
    `}
`;

export const StyledSortableList = styled(SortableList)`
  .sortable {
    cursor: pointer;
  }
`;

export const StyledCSVDownloadButtonWrapper = React.forwardRef((props: CsvDownloadProps, ref) => {
  return <StyledCSVDownloadButton {...props} />;
});

export const StyledCSVDownloadButton = styled(CsvDownloadButton)`
  display: flex;
  background: inherit;
  border-radius: 32px;
  border: none;
  marginleft: 8px;
  margintop: 2px;
  padding: 7px 8px;

  cursor: pointer;

  &:hover {
    background: ${colors["grey-300"]};
  }
`;
