import React from "react";

interface IEmbeddedContext {
  isEmbedded: boolean;
  isEmbeddedIndividualResource: boolean;
}

const EmbeddedContext = React.createContext<IEmbeddedContext>({ isEmbedded: false, isEmbeddedIndividualResource: false });
export const Consumer = EmbeddedContext.Consumer;

export default EmbeddedContext;
