import { Identifier } from "react-admin";
import { IFunction } from "../types/functions";
import { Conversions } from "./Conversions";

//-------------------------------------------------------------------------------
// Response Interfaces
//-------------------------------------------------------------------------------

export interface IGetFunctionsResponse {
  functions: IFunction[];
}

export interface IRaFunction extends IFunction {
  id: string;
}

//-------------------------------------------------------------------------------
// Conversions
//-------------------------------------------------------------------------------

export class FunctionConversions implements Conversions {
  public fromResponseMany(response: IGetFunctionsResponse): IRaFunction[] {
    return (response.functions ?? []).map((func: IFunction) => {
      return this.fromResponse(func);
    });
  }

  public fromResponse(response: IFunction): IRaFunction {
    return {
      ...response,
      id: response.function_id,
    };
  }

  public fromCreateResponse(response: any): any {
    throw new Error("Conversion not implemented");
  }

  public toRequest(resource: any): any {
    throw new Error("Conversion not implemented");
  }

  public toUpdateRequest(id: Identifier, resource: any): any {
    throw new Error("Conversion not implemented");
  }

  public toCreateRequest(resource: any): any {
    throw new Error("Conversion not implemented");
  }

  public toDeleteRequest(id: Identifier): any {
    throw new Error("Conversion not implemented");
  }
}
