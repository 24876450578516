import dayjs from "dayjs";
import { IAliasedExpression, IColumnIdentifier, IExpressionType, IJoinType, IRelation, IRelationType } from "../conversions/ReportConversions";
import { IFunction } from "../types/functions";
import { IMacro } from "../types/macros";
import { IColumnDef, IColumnFilterValueSourceType, IColumnOwnerType, IColumnValueType, IObjectDef } from "../types/object";
import { IColumnFormat, IColumnSettings, IReportQuery } from "../types/report";
import { getObjectsById } from "./objectUtils";
import { getAllAliasedExpressionsFromReportQuery } from "./reportQueryUtils";
import { decodeString } from "./textUtils";

export const INLINED_CANDIDATE_RELATION_ALIAS: string = "inlined_candidate_relation";

export function generateColumnDefFromMacro(macro: IMacro, objectId: string): IColumnDef {
  return {
    obj_id: objectId,
    col_id: macro.ref,
    col_name: decodeString(macro.name),
    col_owner_type: IColumnOwnerType.User,
    value_def: {
      col_value_type: IColumnValueType.Boolean,
      col_bucket_id: `cxt_data.macro_data.${macro.ref}`,
    },
    filter_def: {
      value_source_type: IColumnFilterValueSourceType.FreeText,
      valid_values: [],
    },
  };
}

export function getObjectDefsFromRelation(relation: IRelation, objectMap: Record<string, IObjectDef>): IObjectDef[] {
  switch (relation.kind) {
    case IRelationType.TableIdentifier:
      return objectMap[relation.source_obj_id] ? [objectMap[relation.source_obj_id]] : [];
    case IRelationType.JoinExpression:
      return [...getObjectDefsFromRelation(relation.left, objectMap), ...getObjectDefsFromRelation(relation.right, objectMap)];
    case IRelationType.AliasedRelation:
      return [...getObjectDefsFromRelation(relation.relation, objectMap)];
    case IRelationType.TableFunction:
      return relation.relations.flatMap((childRelation) => getObjectDefsFromRelation(childRelation, objectMap));
    case IRelationType.InlinedTableIdentifier:
      return [relation.inlined_obj];
    default:
      return [];
  }
}

//---------------------------------------------------------------------------------------------------------------------
// Value Formatting
//---------------------------------------------------------------------------------------------------------------------

export interface IExpressionFormatMap {
  [alias: string]: (value: any) => string;
}

export function formatDate(value: any): string {
  return value && dayjs(Number(value)).isValid() ? dayjs(Number(value)).format("MM/DD/YYYY") : value ?? "";
}

export function formatBoolean(value: any): string {
  // TODO - localize
  if (String(value) === "0") {
    return "False";
  } else if (String(value) === "1") {
    return "True";
  } else {
    return value;
  }
}

export function getExpressionFormatMap(query: IReportQuery, objectMap: Record<string, IObjectDef>, columnSettings?: Record<string, IColumnSettings>): IExpressionFormatMap {
  const objectDefs: IObjectDef[] = query.objects ? getObjectDefsFromRelation(query.objects, objectMap) : [];

  const reportQueryObjectMap: Record<string, IObjectDef> = getObjectsById(objectDefs);

  const allExpressions: IAliasedExpression[] = getAllAliasedExpressionsFromReportQuery(query);

  return allExpressions.reduce((acc: IExpressionFormatMap, expression: IAliasedExpression) => {
    if (expression.expr.kind === IExpressionType.ColumnIdentifier) {
      const columnDef: IColumnDef | undefined = reportQueryObjectMap[expression.expr.col.obj_id]?.default_cols?.find(
        (colDef: IColumnDef) => colDef.col_id === (expression.expr as IColumnIdentifier).col.col_id
      );

      if (expression.alias) {
        if (columnDef?.value_def.col_value_type === IColumnValueType.Date) {
          acc[expression.alias] = formatDate;
        } else if (columnDef?.value_def.col_value_type === IColumnValueType.Boolean) {
          acc[expression.alias] = formatBoolean;
        }
      }
    }

    if (expression.alias && columnSettings?.[expression.alias]?.format === IColumnFormat.Date) {
      acc[expression.alias] = formatDate;
    }

    return acc;
  }, {});
}

export function getRelationSummary(relation: IRelation, objectMap: Record<string, IObjectDef>, platFunctions: IFunction[]): string {
  const summary: string = getRelationSummaryHelper(relation, objectMap, platFunctions);
  return summary.startsWith("(") && summary.endsWith(")") ? summary.slice(1, -1) : summary;
}

export function getRelationSummaryHelper(relation: IRelation, objectMap: Record<string, IObjectDef>, platFunctions: IFunction[]): string {
  switch (relation.kind) {
    case IRelationType.TableIdentifier:
      return objectMap[relation.source_obj_id].resource_name;
    case IRelationType.JoinExpression:
      let joinString: string;

      if (relation.type === IJoinType.LeftOuter) {
        joinString = "->";
      } else if (relation.type === IJoinType.RightOuter) {
        joinString = "<-";
      } else if (relation.type === IJoinType.Inner) {
        joinString = "-";
      } else {
        joinString = relation.type;
      }

      return `(${getRelationSummaryHelper(relation.left, objectMap, platFunctions)} ${joinString} ${getRelationSummaryHelper(relation.right, objectMap, platFunctions)})`;
    case IRelationType.AliasedRelation:
      return objectMap[relation.alias]?.resource_name ?? getRelationSummaryHelper(relation.relation, objectMap, platFunctions);
    case IRelationType.TableFunction:
      const tableFunction: IFunction | undefined = platFunctions.find((func: IFunction) => func.function_id === relation.id);
      const tableFunctionName: string = tableFunction ? tableFunction.name : "Unknown Table Function";
      return `${tableFunctionName}(${relation.relations
        .map((subRelation: IRelation) => {
          const summary: string = getRelationSummaryHelper(subRelation, objectMap, platFunctions);
          return summary.startsWith("(") && summary.endsWith(")") ? summary.slice(1, -1) : summary;
        })
        .join(", ")})`;
    case IRelationType.InlinedTableIdentifier:
      return objectMap[relation.inlined_obj.resource_id].resource_name;
    default:
      return "Unknown relation type";
  }
}

export function findRelationWithId(relation: IRelation, id: string): IRelation | undefined {
  switch (relation.kind) {
    case IRelationType.TableIdentifier:
      if (relation.source_obj_id === id) {
        return relation;
      } else {
        return undefined;
      }
    case IRelationType.JoinExpression:
      return findRelationWithId(relation.left, id) || findRelationWithId(relation.right, id);
    case IRelationType.AliasedRelation:
      if (relation.alias === id) {
        return relation;
      } else {
        return findRelationWithId(relation.relation, id);
      }
    case IRelationType.TableFunction:
      return relation.relations.reduce((acc: IRelation | undefined, subRelation: IRelation) => acc || findRelationWithId(subRelation, id), undefined);
    case IRelationType.InlinedTableIdentifier:
      if (relation.inlined_obj.resource_id === id) {
        return relation;
      } else {
        return undefined;
      }
  }
}
