import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./app/App";
import { IEmbedMode, IEmbedModeType } from "./types/embed";
import { parseEmbedProps } from "./utils/embedUtils";
import { isFindemDomain } from "findem-helpers";

const scriptTag: HTMLOrSVGScriptElement | null = document.currentScript;
const scriptProps: DOMStringMap = scriptTag?.dataset ?? {};
const bindElementId: string | undefined = scriptProps["bindElementId"];

const isEmbedded: boolean = !!scriptProps["embedded"]; // @deprecated
const reportId: string | undefined = scriptProps["reportId"]; // @deprecated
const dashboardId: string | undefined = scriptProps["dashboardId"]; // @deprecated

const embedModeType: string | undefined = scriptProps["embedModeType"] ?? process.env.REACT_APP_PLATFORM_EMBED_MODE_TYPE;
const embedProps: string = scriptProps["embedProps"] ?? process.env.REACT_APP_PLATFORM_EMBED_PROPS ?? "{}";
const displayOptions: string = scriptProps["displayOptions"] ?? process.env.REACT_APP_PLATFORM_EMBED_DISPLAY_OPTIONS ?? "{}";

let embedMode: IEmbedMode | undefined;

if (reportId) {
  // Backwards compatability for legacy embedding, will be removed after app-next is updated
  embedMode = parseEmbedProps(IEmbedModeType.ReportId, reportId, displayOptions);
} else if (dashboardId) {
  // Backwards compatability for legacy embedding, will be removed after app-next is updated
  embedMode = parseEmbedProps(IEmbedModeType.DashboardId, dashboardId, displayOptions);
} else if (isEmbedded) {
  // Backwards compatability for legacy embedding, will be removed after app-next is updated
  embedMode = parseEmbedProps(IEmbedModeType.App, undefined, displayOptions);
} else if (embedModeType) {
  embedMode = parseEmbedProps(embedModeType as IEmbedModeType, embedProps, displayOptions);
}

const router = createBrowserRouter([
  {
    path: "/*",
    element: (
      <CookiesProvider>
        <App embedMode={embedMode} />
      </CookiesProvider>
    ),
  },
]);

if (isEmbedded) {
  // If the platform application is embedded, we are unable to receive any navigation events
  // from the parent application's router. This is to support the case where we are currently
  // in a platform sub-route & navigation is triggered from the parent app sidebar to go back
  // to the root.
  window.addEventListener("message", (event: MessageEvent<any>) => {
    const { origin, data } = event;

    if (!isFindemDomain(origin)) {
      return;
    }

    if (data.type === "platform-navigation" && data.navigationPath && data.navigationPath !== router.state.location.pathname) {
      router.navigate(String(data.navigationPath));
    }
  });
}

const root = ReactDOM.createRoot(document.getElementById(bindElementId ?? "platform-analytics-root") as HTMLElement);

root.render(<RouterProvider router={router} />);
