import { isSmbApp, matchesUrl } from "findem-helpers";
import { AuthProvider } from "react-admin";
import { ICurrentUser } from "./types/users";

export const REFETCH_USER_EVENT: string = "refetch_user";
export const LOGOUT_EVENT: string = "logout";

export enum UserAuthFields {
  USER_ID = "userId",
  USER_NAME = "userName",
  USER_EMAIL = "userEmail",
  USER_ROLES = "userRoles",
}

export const authProvider: AuthProvider = {
  login: async ({ username, password }: { username: string; password: string }) => {
    try {
      // TODO: find a better way to retrieve the csrf token for sending to /auth/login POST.
      const loginPage: Response = await fetch(`${matchesUrl}/auth/login`, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
      });
      const loginPageBody: string = await loginPage.text();
      const csrfToken: RegExpMatchArray | null = loginPageBody.match(`(?<=name="_csrf" value=")([^"]*)`);
      const response = await fetch(`${matchesUrl}/auth/login`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: username, password, api: true, remember: "remember", _csrf: csrfToken![0] }),
      });
      const authResponse = await response.json();
      window.dispatchEvent(new Event(REFETCH_USER_EVENT));
    } catch {
      throw new Error("Network error");
    }
  },
  logout: async () => {
    window.dispatchEvent(new Event(LOGOUT_EVENT));
    const request = new Request(`${matchesUrl}/auth/logout?api=true`, {
      method: "GET",
      credentials: "include",
    });
    try {
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      await response.json();
      return Promise.resolve();
    } catch {
      return Promise.reject();
    }
  },
  checkError: async (error) => {
    const status = error.status;
    if (status === 401 || status === 302 || status === 0) {
      await authProvider.logout({});
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: async () => {
    try {
      const response = await fetch(`${matchesUrl}/api/user_info`, {
        method: "GET",
        cache: "no-cache",
        credentials: "include",
      });
      const userInfoResponse: ICurrentUser = await response.json();
      if (userInfoResponse && !!userInfoResponse.uid) {
        // Only allow Findem users for SMB Application
        if (isSmbApp() && !userInfoResponse.email.includes('@findem.ai')) {
          return Promise.reject();
        }

        localStorage.setItem(UserAuthFields.USER_ID, userInfoResponse.uid);
        localStorage.setItem(UserAuthFields.USER_NAME, userInfoResponse.name);
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    } catch {
      return Promise.reject();
    }
  },
  getPermissions: () => {
    return Promise.resolve();
  },
  getIdentity: () => {
    const id: string | null = localStorage.getItem(UserAuthFields.USER_ID);
    const name: string | null = localStorage.getItem(UserAuthFields.USER_NAME);

    if (id && name) {
      return Promise.resolve({
        id: id,
        fullName: name,
      });
    } else {
      return Promise.reject();
    }
  },
};
