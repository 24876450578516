import { merge } from "lodash";
import { DEFAULT_AD_LIB, DEFAULT_DASHBOARD_DISPLAY_OPTIONS, DEFAULT_STATIC_DASHBOARD } from "../embed/static/defaultData";
import { IReportQueriesEmbeddedContainerProps } from "../reports/Embed/ReportQueriesEmbeddedContainer";
import { IEmbedDisplayOptions, IEmbedMode, IEmbedModeType } from "../types/embed";
import { IReportQuery } from "../types/report";
import { DEFAULT_AD_LIB_DISPLAY_OPTIONS } from "./../embed/static/defaultData";

export function parseEmbedProps(type: IEmbedModeType, embedProps?: string, displayOptionsString?: string): IEmbedMode {
  if (!embedProps) {
    return { type: IEmbedModeType.App };
  }

  const displayOptions: IEmbedDisplayOptions | undefined = displayOptionsString ? parseDisplayOptions(displayOptionsString) : undefined;

  switch (type) {
    case IEmbedModeType.App:
      return { type: IEmbedModeType.App };

    case IEmbedModeType.DashboardId:
      return { type: IEmbedModeType.DashboardId, dashboardId: embedProps, ...displayOptions };

    case IEmbedModeType.ReportId:
      return { type: IEmbedModeType.ReportId, reportId: embedProps };

    case IEmbedModeType.ReportQueries:
      const parsedProps: IReportQueriesEmbeddedContainerProps | IReportQuery[] = JSON.parse(embedProps);
      if (Array.isArray(parsedProps)) {
        return { type: IEmbedModeType.ReportQueries, reportQueries: parsedProps, ...displayOptions };
      } else {
        return { type: IEmbedModeType.ReportQueries, ...parsedProps, ...displayOptions };
      }

    case IEmbedModeType.Insights:
      return { type: IEmbedModeType.Insights };

    case IEmbedModeType.InsightsStudy:
      return { type: IEmbedModeType.InsightsStudy, ...JSON.parse(embedProps), ...(displayOptions ? displayOptions : {}) };

    case IEmbedModeType.InsightsStudyCard:
      return { type: IEmbedModeType.InsightsStudyCard, ...JSON.parse(embedProps), ...(displayOptions ? displayOptions : {}) };

    case IEmbedModeType.InsightsTemplatesList:
      return { type: IEmbedModeType.InsightsTemplatesList, ...JSON.parse(embedProps), ...(displayOptions ? displayOptions : {}) };

    case IEmbedModeType.StaticDashboard:
      return {
        type: IEmbedModeType.StaticDashboard,
        dashboard: merge({ ...DEFAULT_STATIC_DASHBOARD }, JSON.parse(embedProps)),
        ...(displayOptions ? merge({ ...DEFAULT_DASHBOARD_DISPLAY_OPTIONS }, displayOptions) : {}),
      };

    case IEmbedModeType.StaticAdlib:
      return {
        type: IEmbedModeType.StaticAdlib,
        adlib: merge({ ...DEFAULT_AD_LIB }, JSON.parse(embedProps)),
        ...(displayOptions ? merge({ ...DEFAULT_AD_LIB_DISPLAY_OPTIONS }, displayOptions) : {}),
      };

    case IEmbedModeType.OutreachAnalysis:
      return { type: IEmbedModeType.OutreachAnalysis, ...JSON.parse(embedProps) };
  }
}

export function parseDisplayOptions(displayOptionsString: string): IEmbedDisplayOptions {
  return JSON.parse(displayOptionsString);
}

export function isStaticEmbedMode(embedMode: IEmbedMode): boolean {
  return embedMode.type === IEmbedModeType.StaticDashboard || embedMode.type === IEmbedModeType.StaticAdlib;
}
