import { IStudyTemplate } from "../../insights/insightsTypes";
import { InsightsBaseConversions } from "./InsightsBaseConversions";

export class StudyTemplateConversions extends InsightsBaseConversions<IStudyTemplate> {
  public fromResponseMany(response: { study_templates: IStudyTemplate[] }): IStudyTemplate[] {
    return (response.study_templates ?? []).map((resource: IStudyTemplate) => {
      return this.fromResponse(resource);
    });
  }

  public fromResponse(response: IStudyTemplate): IStudyTemplate {
    return {
      ...response,
      id: response.resource_id,
      study_sections: response.study_sections.map((section) => {
        return {
          ...section,
          section_reports: (section.section_reports ?? []).map((value: any) => {
            try {
              return JSON.parse(value);
            } catch (e) {
              return value;
            }
          }),
        };
      }),
    };
  }

  public fromCreateResponse(response: { study_template_create: IStudyTemplate }): IStudyTemplate {
    return this.fromResponse(response.study_template_create);
  }
}
