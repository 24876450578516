import { flatten } from "lodash";
import { Identifier } from "react-admin";
import { IObjectDef } from "../types/object";
import { IAdvancedReportQueryOptions, IColumnEntry, IICPRequirement, IReportQuery, IReportQueryOptions } from "../types/report";
import { IPlatformResource } from "../types/resources";
import { doAllColumnExpressionsMatch, getExpressionColEntry } from "../utils/expressionUtils";
import { instanceOfTimeFilledTableFunction } from "../utils/functionUtils";
import { IRowFilter, Report } from "./../types/report";
import { Conversions } from "./Conversions";
var booleanParser = require("boolean-parser");

//-------------------------------------------------------------------------------
// Constants
//-------------------------------------------------------------------------------

export const POSITIVE_INF: string = "inf";
export const NEGATIVE_INF: string = "-inf";

//-------------------------------------------------------------------------------
// Response Interfaces
//-------------------------------------------------------------------------------

export interface IReportsResponse {
  reports: IReportResponse[];
}

export interface IReportResponse extends IPlatformResource {
  uid: string;

  // Query definition of the report
  report_query: IReportQueryResponse;

  // Report UI Blob
  report_ui_ctx?: any;

  // Metadata
  created_at: string;
  updated_at: string;

  analysis_result: IReportAnalysisResult;
}

export interface IReportAnalysisResult {
  parametrized_expressions: { parameter_name: string; binding_type: IExpressionType }[];
}

export interface IReportQueryResponse {
  namespace: string;
  objects: IRelation;
  projections: IAliasedExpression[];
  filters?: IFilter;
  row_grouping: IAliasedExpression[];
  col_grouping?: IAliasedExpression[];
  row_ordering?: ISortExpression[];

  distinct_rows?: boolean;
  options?: IReportQueryOptions;
  advanced_options?: IAdvancedReportQueryOptions;
}

interface ICreateReportResponse {
  report_create: IReportResponse;
}

//-------------------------------------------------------------------------------
// Report Query Interfaces
//-------------------------------------------------------------------------------

export interface IFilter {
  iicp_requirements?: IICPRequirement[];
  row_filter?: IBooleanExpression; // for db
}

//-------------------------------------------------------------------------------
// Filter Expressions
//-------------------------------------------------------------------------------

export type IBooleanExpression = IRelationalBooleanExpression | ISetMembershipBooleanExpression | IBinaryLogicalExpression;

export interface IRelationalBooleanExpression {
  kind: IExpressionType.RelationalBooleanExpression;
  op: IRelationalOp;
  left: IExpression;
  right: IExpression;
}

export interface ISetMembershipBooleanExpression {
  kind: IExpressionType.SetMembershipBooleanExpression;
  expr: IExpression;
  values: ILiteralExpression[] | IParameterizedArrayExpression;
}

export interface IBinaryLogicalExpression {
  kind: IExpressionType.BinaryLogicalExpression;
  op: IBooleanOp;
  left: IBooleanExpression;
  right: IBooleanExpression;
}

export enum IRelationalOp {
  Is = "is",
  IsNot = "is not",
  Equals = "eq",
  NotEquals = "ne",
  LessThan = "lt",
  LessThanOrEquals = "leq",
  GreaterThan = "gt",
  GreaterThanOrEquals = "geq",
}

export enum IBooleanOp {
  And = "and",
  Or = "or",
}

//-------------------------------------------------------------------------------
// Column Expressions
//-------------------------------------------------------------------------------

export type IExpression =
  | IColumnIdentifier
  | IBinaryExpression
  | ILiteralExpression
  | IFunctionExpression
  | IInlineExpression
  | IRelationalBooleanExpression
  | ISetMembershipBooleanExpression
  | IBinaryLogicalExpression
  | IAliasedExpression
  | IAggregateExpression
  | ICountAggregateExpression
  | IProjectionExpression
  | INullExpression
  | IParameterizedExpression
  | IParameterizedArrayExpression
  | ILiteralArrayExpression
  | IBindExpression;

export interface IColumnIdentifier {
  kind: IExpressionType.ColumnIdentifier;
  col: IColumnEntry;
}

export interface IBinaryExpression {
  kind: IExpressionType.BinaryExpression;
  op: IBinaryArithmeticOp;
  left: IExpression;
  right: IExpression;
}

export interface ILiteralExpression {
  kind: IExpressionType.LiteralExpression;
  value: string | number | boolean;
}

export interface ILiteralArrayExpression {
  kind: IExpressionType.LiteralArrayExpression;
  values: Array<ILiteralExpression | INullExpression>;
}

export interface IFunctionExpression {
  kind: IExpressionType.FunctionExpression;
  alias?: string;
  id: string;
  operands: IExpression[];
}

export interface IInlineExpression {
  kind: IExpressionType.InlineExpression;
  value: string;
  source_obj_columns: IColumnEntry[];
  alias?: string;
}

export interface ICountAggregateExpression {
  kind: IExpressionType.CountAggregateExpression;
}

export enum IBinaryArithmeticOp {
  Add = "add",
  Subtract = "sub",
  Multiply = "mul",
  Divide = "div",
}

export interface IAliasedExpression<T extends IExpression = IExpression> {
  kind: IExpressionType.AliasedExpression;
  expr: T;
  alias?: string;
}

export interface IColumnExpression {
  kind: IExpressionType.ColumnExpression;
  expr: IExpression;
  alias?: string;
}

export interface IProjectionExpression {
  kind: IExpressionType.ProjectionExpression;
  col: IAliasedExpression;
  stats?: IStat[];
}

export enum IStat {
  Min = "min",
  Max = "max",
  Sum = "sum",
  Avg = "avg",
}

export interface INullExpression {
  kind: IExpressionType.NullExpression;
}

export interface IAggregateExpression {
  kind: IExpressionType.AggregateExpression;
  function: IAggregateFunctionType;
  expr: IExpression;
  distinct?: boolean;
}

export enum IAggregateFunctionType {
  Count = "count",
  Min = "min",
  Max = "max",
  Sum = "sum",
  Avg = "avg",
  None = "none", // Internal - don't sent to BE
}

export interface IParameterizedExpression {
  kind: IExpressionType.ParameterizedExpression;
  parameter_name: string;
  default_parameter_value: IExpression;
}

export interface IParameterizedArrayExpression {
  kind: IExpressionType.ParameterizedArrayExpression;
  parameter_name: string;
  default_parameter_value: IExpression;
}

export interface IBindExpression {
  kind: IExpressionType.BindExpression;
  parameter_name: string;
  parameter_value: IExpression;
}

export enum IExpressionType {
  ColumnIdentifier = "column_identifier",
  BinaryExpression = "binary_expression",
  LiteralExpression = "literal_expression",
  FunctionExpression = "function_expression",
  InlineExpression = "inline_expression",
  RelationalBooleanExpression = "relational_expression",
  SetMembershipBooleanExpression = "set_expression",
  BinaryLogicalExpression = "binary_logical_expression",
  AliasedExpression = "aliased_expression",
  CountAggregateExpression = "count_aggregate_expression",
  ProjectionExpression = "projection_expression", // deprecated
  ParameterizedExpression = "parameterized_expression",
  ParameterizedArrayExpression = "parameterized_array_expression",
  LiteralArrayExpression = "literal_array_expression",
  BindExpression = "bind_expression",
  AggregateExpression = "aggregate_expression",
  NullExpression = "null_expression",
  ColumnExpression = "column_expression", // deprecated
}

//-------------------------------------------------------------------------------
// Objects/Joins
//-------------------------------------------------------------------------------

export type IRelation = ITableIdentifier | IInlinedTableIdentifier | IJoinExpression | ITableFunction | IAliasedRelation;

export interface IAliasedRelation {
  kind: IRelationType.AliasedRelation;
  alias: string;
  relation: IRelation;
}

export interface IAliasedTableFunctionRelation extends IAliasedRelation {
  kind: IRelationType.AliasedRelation;
  alias: string;
  relation: ITableFunction;
}

export interface ITableIdentifier {
  kind: IRelationType.TableIdentifier;
  source_obj_id: string;
}

export interface IInlinedTableIdentifier {
  kind: IRelationType.InlinedTableIdentifier;
  name: string;
  inlined_obj: IObjectDef;
}

export interface IJoinExpression {
  kind: IRelationType.JoinExpression;
  left: IRelation;
  right: IRelation;
  type: IJoinType;
  condition: IRelationalJoinCondition | IBinaryLogicalJoinConidition;
}

export interface IRelationalJoinCondition {
  kind: IExpressionType.RelationalBooleanExpression;
  op: IRelationalOp.Equals;
  left: IColumnIdentifier;
  right: IColumnIdentifier;
}

export interface IBinaryLogicalJoinConidition {
  kind: IExpressionType.BinaryLogicalExpression;
  op: IBooleanOp.And;
  left: IRelationalJoinCondition | IBinaryLogicalJoinConidition;
  right: IRelationalJoinCondition | IBinaryLogicalJoinConidition;
}

export interface ITableFunction {
  kind: IRelationType.TableFunction;
  id: string;
  relations: IRelation[];
  operands?: { [key: string]: IExpression[] }; // additional operands for this table function
}

export enum IRelationType {
  TableIdentifier = "table_identifier",
  InlinedTableIdentifier = "inlined_table_identifier",
  JoinExpression = "join_expression",
  TableFunction = "table_function",
  AliasedRelation = "aliased_relation",
}
export enum IJoinType {
  Inner = "inner",
  LeftOuter = "left_outer",
  RightOuter = "right_outer",
  FullOuter = "full_outer",
}

//-------------------------------------------------------------------------------
// Sort Interfaces
//-------------------------------------------------------------------------------

export interface ISortExpression {
  expr: IAliasedExpression;
  order: ISortOrder;
}

export enum ISortOrder {
  Ascending = "asc",
  Descending = "desc",
}

//-------------------------------------------------------------------------------
// Request Interfaces
//-------------------------------------------------------------------------------

interface ICreateReportRequest {
  payload: IPartialReportResponse;
}

interface IUpdateReportRequest {
  resource_id: Identifier;
  payload: IPartialReportResponse;
}

interface IDeleteReportRequest {
  resource_id: Identifier;
}

interface IPartialReportResponse {
  report_owner_id?: string;
  resource_id?: string;
  resource_name?: string;
  resource_description?: string;
  public?: string;
  org_public?: string;
  report_query?: Partial<IReportQueryResponse>;
  report_ui_ctx?: any;
  created_at?: string;
  updated_at?: string;
}

//-------------------------------------------------------------------------------
// Conversions
//-------------------------------------------------------------------------------

interface IRowFilterLogic {
  booleanString: string;
  rowFilters: IRowFilter[];
}

export class ReportConversions implements Conversions {
  public fromResponseMany(response: IReportsResponse): Report[] {
    return (response.reports ?? []).map((report: IReportResponse) => {
      return this.fromResponse(report);
    });
  }

  public fromResponse(response: IReportResponse): Report {
    return {
      id: response.resource_id,
      ownerId: response.uid,
      resource_id: response.resource_id,
      resource_name: response.resource_name,
      resource_description: response.resource_description ?? "",
      resource_type: response.resource_type,
      schema_version: response.schema_version,
      access_info: response.access_info,
      updated_at: response.updated_at,
      created_at: response.created_at,
      query: fromReportQueryResponse(response.report_query),
      tags: [],
      report_ui_ctx: response.report_ui_ctx,
      analysis_result: response.analysis_result,
    };
  }

  public fromCreateResponse(response: ICreateReportResponse): Report {
    return this.fromResponse(response.report_create);
  }

  public toRequest(report: Report): Partial<IReportResponse> {
    return {
      uid: report.ownerId,
      resource_id: report.id as string,
      resource_name: report.resource_name,
      resource_description: report.resource_description,
      created_at: "",
      updated_at: "",
      report_query: toReportQueryRequest(report.query),
      report_ui_ctx: report.report_ui_ctx,
    };
  }

  public toUpdateRequest(id: Identifier, report: Partial<Report>): IUpdateReportRequest {
    return {
      resource_id: id,
      payload: this.toReportRequestPartial(report),
    };
  }

  public toCreateRequest(report: Partial<Report>): ICreateReportRequest {
    return {
      payload: this.toReportRequestPartial(report),
    };
  }

  public toDeleteRequest(id: Identifier): IDeleteReportRequest {
    return {
      resource_id: id,
    };
  }

  private toReportRequestPartial(report: Partial<Report>): IPartialReportResponse {
    return {
      ...(report.id && { resource_id: report.id.toString() }),
      ...(report.resource_name && { resource_name: report.resource_name }),
      ...(report.resource_description && { resource_description: report.resource_description }),
      ...(report.public && { public: report.public }),
      ...(report.org_public && { public: report.org_public }),
      ...(report.query && { report_query: toReportQueryRequest(report.query) }),
      ...(report.report_ui_ctx && { report_ui_ctx: report.report_ui_ctx }),
    };
  }
}

//------------------------------------------------------------------------------------------------
// Conversions from Response -> FE Model
//------------------------------------------------------------------------------------------------

export function fromReportQueryResponse(response: IReportQueryResponse): IReportQuery {
  const relation: IRelation = response.objects;

  let rowFilterLogic: IRowFilterLogic;

  if (
    relation.kind === IRelationType.AliasedRelation &&
    relation.relation.kind === IRelationType.TableFunction &&
    instanceOfTimeFilledTableFunction(relation.relation) &&
    relation.relation.operands.row_filter?.length > 0
  ) {
    // If the top level relation is of type ITimeFilledTableFunction, the row filters are passed as operands to the table function.
    // Otherwise, if the filters cause no data to be returned for the subgroups, there will be gaps in the trend chart (which defeats the purpose of this table function).
    // This change parses them back to the top level rowFilters, so the UI can interact with them in the same manner as regular filters.
    rowFilterLogic = fromRowFilteringResponseHelper(relation.relation.operands.row_filter[0]);
  } else {
    rowFilterLogic = fromRowFilteringResponse(response?.filters);
  }

  return {
    namespace: response.namespace,
    objects: response.objects,
    projections: response.projections,
    row_grouping: response.row_grouping,
    row_ordering: response.row_ordering,
    rowFilters: rowFilterLogic.rowFilters,
    booleanLogic: rowFilterLogic.booleanString,
    indexFilters: response.filters?.iicp_requirements,
    options: response.options,
    advanced_options: response.advanced_options,
  };
}

export function fromRowFilteringResponse(filters: IFilter | undefined): IRowFilterLogic {
  if (filters?.row_filter) {
    return fromRowFilteringResponseHelper(filters.row_filter);
  } else {
    return { booleanString: "", rowFilters: [] };
  }
}

export function fromRowFilteringResponseHelper(booleanExpression: IBooleanExpression): IRowFilterLogic {
  const rowFilterLogic: IRowFilterLogic = parseBooleanExpression(booleanExpression);
  const parsedBoolean: string[][] = booleanParser.parseBooleanQuery(rowFilterLogic.booleanString);
  // Get parts of the parsed boolean which are not all directly AND'd together
  const simplifiedParsedBoolean: string[][] = simplifyParsedBoolean(parsedBoolean);
  const booleanString: string = convertToBooleanString(simplifiedParsedBoolean);
  return { booleanString: booleanString, rowFilters: rowFilterLogic.rowFilters };
}

/**
 * Parses a single boolean expression into an array of IRowFilter + a boolean string representing the logic used to combine the row filters.
 *
 * The boolean string will contain a structure like "(1 AND 2) OR 3", where the numbers represent the index (1-based) of the row filter.
 *
 * @param booleanExpression
 * @param index
 * @returns
 */
export function parseBooleanExpression(booleanExpression: IBooleanExpression, index: number = 1): IRowFilterLogic {
  switch (booleanExpression.kind) {
    case IExpressionType.RelationalBooleanExpression:
    case IExpressionType.SetMembershipBooleanExpression:
      return {
        booleanString: `${index}`,
        rowFilters: [booleanExpression],
      };
    case IExpressionType.BinaryLogicalExpression:
      const colEntry: IColumnEntry | undefined = getExpressionColEntry(booleanExpression);
      if (colEntry && doAllColumnExpressionsMatch(booleanExpression, colEntry)) {
        // If all boolean expressions are for the same column entry, we can keep them as a single token.
        return {
          booleanString: `${index}`,
          rowFilters: [booleanExpression],
        };
      } else {
        const leftLogic: IRowFilterLogic = parseBooleanExpression(booleanExpression.left, index);
        const rightLogic: IRowFilterLogic = parseBooleanExpression(booleanExpression.right, index + leftLogic.rowFilters.length);
        return {
          booleanString: `(${leftLogic.booleanString}) ${booleanExpression.op === IBooleanOp.And ? "AND" : "OR"} (${rightLogic.booleanString})`,
          rowFilters: [...leftLogic.rowFilters, ...rightLogic.rowFilters],
        };
      }

    default:
      return {
        booleanString: "",
        rowFilters: [],
      };
  }
}

/**
 * Finds common elements in all sub-arrays.
 */
function findCommonElements(arrays: string[][]): string[] {
  if (arrays.length === 0) return [];
  let commonElements = arrays[0];

  for (let i = 1; i < arrays.length; i++) {
    commonElements = commonElements.filter((element) => arrays[i].includes(element));
  }

  return commonElements;
}

/**
 * Removes common elements from each sub-array.
 */
function removeCommonElements(arrays: string[][], commonElements: string[]): string[][] {
  return arrays.map((subArray) => subArray.filter((element) => !commonElements.includes(element))).filter((subArray) => subArray.length > 0);
}

/**
 * Simplifies the parsed boolean array by removing elements that exist in all sub-arrays.
 */
function simplifyParsedBoolean(parsedBoolean: string[][]): string[][] {
  const commonElements = findCommonElements(parsedBoolean);
  return removeCommonElements(parsedBoolean, commonElements);
}

/**
 * Converts a simplified parsed boolean array into a string representation.
 */
function convertToBooleanString(simplifiedBoolean: string[][]): string {
  if (simplifiedBoolean.length === 0) return "";

  // Map each sub-array to a string of OR'd elements
  const orGroups = simplifiedBoolean.map((subArray) => subArray.join(" AND "));

  // Join all OR groups with AND
  return orGroups.length > 1 ? `(${orGroups.join(") OR (")})` : orGroups[0];
}

//------------------------------------------------------------------------------------------------
// Conversions from FE Model -> Request
//------------------------------------------------------------------------------------------------

export function toReportQueryRequest(query: IReportQuery): IReportQueryResponse {
  let relation: IRelation = query.objects;
  let rowFilters: IRowFilter[] = query.rowFilters;

  if (relation.kind === IRelationType.AliasedRelation && relation.relation.kind === IRelationType.TableFunction && instanceOfTimeFilledTableFunction(relation.relation)) {
    // If the top level relation is of type ITimeFilledTableFunction, we want the row filters to be passed as operands to the table function.
    // Otherwise, if the filters cause no data to be returned for the subgroups, there will be gaps in the trend chart (which defeats the purpose of this table function).
    relation = {
      ...relation,
      relation: {
        ...relation.relation,
        operands: {
          ...relation.relation.operands,
          row_filter: [buildBooleanExpression(rowFilters, query.booleanLogic)],
        },
      },
    };
    rowFilters = [];
  }

  return {
    namespace: query.namespace,
    objects: relation,
    projections: query.projections,
    row_grouping: query.row_grouping,
    col_grouping: query.col_grouping,
    row_ordering: query.row_ordering,
    filters: toRowFilteringResponse(rowFilters, query.booleanLogic, query.indexFilters),
    ...(query.distinct_rows && { distinct_rows: query.distinct_rows }),
    options: query.options,
    advanced_options: query.advanced_options,
  };
}

export function toRowFilteringResponse(rowFilters: IRowFilter[] | undefined, booleanLogic: string, indexFilter: IICPRequirement[] | undefined): IFilter | undefined {
  if (!rowFilters || rowFilters.length === 0) {
    return indexFilter ? { iicp_requirements: indexFilter } : undefined;
  } else {
    return {
      row_filter: buildBooleanExpression(rowFilters, booleanLogic),
      ...(indexFilter && { iicp_requirements: indexFilter }),
    };
  }
}

/**
 * Combines an array of IBooleanExpression into a single IBooleanExpression, with the operation specified
 *
 * @param booleanExpressions
 * @param operation
 * @returns
 */
export function combineBooleanExpressions(booleanExpressions: IBooleanExpression[], operation: IBooleanOp): IBooleanExpression {
  if (booleanExpressions.length === 1) {
    return booleanExpressions[0];
  }

  var booleanExp: Partial<IBooleanExpression> = {
    kind: IExpressionType.BinaryLogicalExpression,
    op: operation,
  };

  booleanExpressions.reduce((acc: Partial<IBinaryLogicalExpression>, expression: IBooleanExpression, index: number) => {
    if (!acc.left) {
      acc.left = expression;
    } else if (index === booleanExpressions.length - 1) {
      acc.right = expression;
    } else {
      acc.right = {
        kind: IExpressionType.BinaryLogicalExpression,
        op: operation,
        left: expression,
      } as IBinaryLogicalExpression;

      return acc.right;
    }

    return acc;
  }, booleanExp);

  return booleanExp as IBooleanExpression;
}

/**
 * Takes an array of row filters & a boolean string & combines them into a IBooleanExpression.
 *
 * The boolean string will contain a structure like "(1 AND 2) OR 3", where the numbers represent the index (1-based) of the row filter.
 *
 * If the boolean string is empty, the row filters will be combined with AND.
 *
 * @param rowFilters
 * @param booleanString
 * @returns
 */
export function buildBooleanExpression(rowFilters: IRowFilter[], booleanString: string): IBooleanExpression {
  if (rowFilters.length === 1) {
    return rowFilters[0];
  }

  if (booleanString) {
    const parsedBoolean: string[][] = booleanParser.parseBooleanQuery(booleanString);
    const parsedBooleanSet: Set<string> = new Set(flatten(parsedBoolean));

    const filtersNotInBoolean: IBooleanExpression[] = rowFilters.flatMap((exp: IBooleanExpression, index: number) => {
      if (parsedBooleanSet.has((index + 1).toString())) {
        return [];
      } else {
        return [exp];
      }
    });

    const topLevelBoolean: string[] = findCommonElements(parsedBoolean);
    const sparseNestedBoolean: string[][] = removeCommonElements(parsedBoolean, topLevelBoolean);

    const topLevelBooleanWithExpressions: IBooleanExpression[] = topLevelBoolean.map((value: string) => {
      return rowFilters[Number(value) - 1];
    });

    const sparseNestedBooleanWithExpressions: IBooleanExpression[][] = sparseNestedBoolean.map((ands: string[]) => {
      return ands.map((value: string) => rowFilters[Number(value) - 1]);
    });

    const topLevelAndsCombined: IBooleanExpression | undefined =
      topLevelBooleanWithExpressions.length > 0 ? (combineBooleanExpressions(topLevelBooleanWithExpressions, IBooleanOp.And) as IBooleanExpression) : undefined;

    const sparseNestedAndsExpressions: IBooleanExpression[] = sparseNestedBooleanWithExpressions.map((ands: IBooleanExpression[]) => {
      return combineBooleanExpressions(ands, IBooleanOp.And) as IBooleanExpression;
    });

    const sparseNestedAndsCombined: IBooleanExpression = combineBooleanExpressions(sparseNestedAndsExpressions, IBooleanOp.Or) as IBooleanExpression;

    const combinedBooleanLogic: IBooleanExpression = combineBooleanExpressions(
      [...(topLevelAndsCombined ? [topLevelAndsCombined] : []), sparseNestedAndsCombined],
      IBooleanOp.And
    ) as IBooleanExpression;

    if (filtersNotInBoolean.length > 0) {
      return combineBooleanExpressions([...filtersNotInBoolean, combinedBooleanLogic], IBooleanOp.And);
    } else {
      return combinedBooleanLogic;
    }
  } else {
    return combineBooleanExpressions(rowFilters, IBooleanOp.And);
  }
}
