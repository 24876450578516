import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../../constants";
import ObjectContext from "../../../context/objectContext";
import { IColumnDef, IObjectDef } from "../../../types/object";

interface INestedColumnSelectorHeaderProps {
  nestedSelectedColumn: IColumnDef | undefined;
}

export const NestedColumnSelectorHeader = (props: INestedColumnSelectorHeaderProps) => {
  const { nestedSelectedColumn } = props;

  const objectMap: Record<string, IObjectDef> = React.useContext(ObjectContext);

  return (
    <>
      {nestedSelectedColumn && nestedSelectedColumn.ext_link ? (
        <Stack direction="column" bgcolor={colors["grey-100"]}>
          <Stack direction="row" alignItems="center" justifyContent="center" p="0.64rem">
            <Typography variant="subtitle1">{objectMap[nestedSelectedColumn.ext_link.obj_id]?.resource_name ?? nestedSelectedColumn.col_name}</Typography>
          </Stack>
          <Divider flexItem />
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};
