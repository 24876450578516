import { Suspense, lazy } from "react";
import { IEmbedMode, IEmbedModeType } from "../types/embed";
import AppProviders from "./AppProviders";
import { EmbeddedAppProviders } from "./EmbeddedAppProviders";

const MainApp = lazy(() => import("./MainApp"));
const ReportEmbeddedContainer = lazy(() => import("../reports/ReportEmbeddedContainer"));
const DashboardEmbeddedContainer = lazy(() => import("../alldashboards/DashboardEmbeddedContainer"));
const ReportQueriesEmbeddedContainer = lazy(() => import("../reports/Embed/ReportQueriesEmbeddedContainer"));
const StaticDashboardEmbeddedContainer = lazy(() => import("../embed/static/StaticDashboardEmbeddedContainer"));
const InsightsApp = lazy(() => import("../insights/InsightsApp"));
const EmbeddedStudyWrapper = lazy(() => import("../insights/studies/embed/EmbeddedStudyWrapper"));
const EmbeddedStudyCard = lazy(() => import("../insights/studies/embed/EmbeddedStudyCard"));
const StaticAdlib = lazy(() => import("../embed/static/adlib/StaticAdlib"));
const EmbeddedTemplatesList = lazy(() => import("../insights/studies/embed/EmbeddedTemplatesList"));
const OutreachAnalytics = lazy(() => import("../reports/OutreachAnalytics/OutreachAnalyticsEmbedWrapper"));

interface IAppProps {
  embedMode?: IEmbedMode;
}

const App = (props: IAppProps) => {
  const { embedMode } = props;

  return (
    <AppProviders embedMode={embedMode}>
      {!embedMode || embedMode.type === IEmbedModeType.App || embedMode.type === IEmbedModeType.Insights ? (
        <>
          {(!embedMode || embedMode.type === IEmbedModeType.App) && (
            <Suspense>
              <MainApp embedMode={embedMode} />
            </Suspense>
          )}
          {embedMode?.type === IEmbedModeType.Insights && (
            <Suspense>
              <InsightsApp />
            </Suspense>
          )}
        </>
      ) : (
        <EmbeddedAppProviders embedMode={embedMode}>
          {embedMode.type === IEmbedModeType.ReportId && (
            <Suspense>
              <ReportEmbeddedContainer reportId={embedMode.reportId} />
            </Suspense>
          )}
          {embedMode.type === IEmbedModeType.DashboardId && (
            <Suspense>
              <DashboardEmbeddedContainer dashboardId={embedMode.dashboardId} hideHeader={embedMode.hideHeader} />
            </Suspense>
          )}
          {embedMode.type === IEmbedModeType.ReportQueries && (
            <Suspense>
              <ReportQueriesEmbeddedContainer {...embedMode} />
            </Suspense>
          )}
          {embedMode.type === IEmbedModeType.StaticDashboard && (
            <Suspense>
              <StaticDashboardEmbeddedContainer {...embedMode} />
            </Suspense>
          )}
          {embedMode.type === IEmbedModeType.StaticAdlib && (
            <Suspense>
              <StaticAdlib {...embedMode} />
            </Suspense>
          )}
          {embedMode.type === IEmbedModeType.InsightsStudy && (
            <Suspense>
              <EmbeddedStudyWrapper {...embedMode} />
            </Suspense>
          )}
          {embedMode.type === IEmbedModeType.InsightsStudyCard && (
            <Suspense>
              <EmbeddedStudyCard {...embedMode} />
            </Suspense>
          )}
          {embedMode.type === IEmbedModeType.InsightsTemplatesList && (
            <Suspense>
              <EmbeddedTemplatesList {...embedMode} />
            </Suspense>
          )}
          {embedMode.type === IEmbedModeType.OutreachAnalysis && (
            <Suspense>
              <OutreachAnalytics />
            </Suspense>
          )}
        </EmbeddedAppProviders>
      )}
    </AppProviders>
  );
};

export default App;
