import SearchIcon from "@mui/icons-material/Search";
import { Box, Divider, InputAdornment, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { BorderlessTextField } from "../../common/Common.styled";

interface ISearchableListProps {
  onSearchChange: (searchString: string) => void;

  header?: ReactNode;
  children: ReactNode;
}

export const SearchableList = (props: ISearchableListProps) => {
  const { onSearchChange, header, children } = props;

  const handleSearch = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSearchChange(event.target.value.toLowerCase());
    },
    [onSearchChange]
  );

  return (
    <Box minWidth="25.6rem" overflow="hidden">
      <Box sx={{ display: "flex", flexDirection: "column", position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
        {header}
        <Box mt="0.64rem" mb="0.64rem">
          <BorderlessTextField
            size="small"
            variant="outlined"
            placeholder="Search..."
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Divider />
      </Box>
      <Box maxHeight={header ? "44.8rem" : "48rem"} overflow="auto">
        {children}
      </Box>
    </Box>
  );
};
