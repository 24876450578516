import CloseIcon from "@mui/icons-material/Close";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { useGetList, useTranslate } from "react-admin";
import { QueryClient, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { colors } from "../../constants";
import LoggedInUserContext from "../../context/loggedInUserContext";
import UserContext from "../../context/userContext";
import { ALLOW_INACTIVE_USER_IMPERSONATION_FLAG, IGNORE_IMPERSONATION_FLAG } from "../../dataProvider/customDataProvider";
import { Resources } from "../../types/resources";
import { IPlatformAccount, IPlatformUser } from "../../types/settings";
import { AutocompleteSelectField } from "../SelectField/AutocompleteSelectField";
import { IMenuItem } from "../SelectField/SelectField";
import { HIDE_IMPERSONATION_BANNER_QUERY_PARAM } from "./UserImpersonationOverlay";
import { EndSessionButton } from "./UserImpersonationSwitcher.styled";

export const USER_IMPERSONATION_QUERY_PARAM: string = "target_user_id";

interface IUserImpersonationSwitcherProps {
  targetUserId: string;
}

export const UserImpersonationSwitcher = (props: IUserImpersonationSwitcherProps) => {
  const { targetUserId } = props;

  const translate = useTranslate();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [queryParams, setSearchParams] = useSearchParams();

  const loggedInUser = React.useContext(LoggedInUserContext);

  const { user, setUser } = React.useContext(UserContext);

  const { data: platformAccounts, isLoading: isAccountsLoading } = useGetList<IPlatformAccount>(Resources.PLATFORM_ACCOUNTS, { meta: { [IGNORE_IMPERSONATION_FLAG]: true } });

  const { data: platformUsers, isLoading: isUsersLoading } = useGetList<IPlatformUser>(Resources.PLATFORM_USERS, { meta: { [IGNORE_IMPERSONATION_FLAG]: true } });

  const allowInactiveUserImpersonation: boolean = queryParams.has(ALLOW_INACTIVE_USER_IMPERSONATION_FLAG);

  const selectedUser: IPlatformUser | undefined = React.useMemo(() => {
    return (platformUsers ?? []).find((platformUser: IPlatformUser) => platformUser.resource_id === targetUserId);
  }, [targetUserId, platformUsers]);

  const selectedAccountId: string | undefined = React.useMemo(() => {
    return selectedUser?.account_id;
  }, [selectedUser]);

  const accountsWithUsers: string[] = React.useMemo(() => {
    return Array.from(
      new Set((platformUsers ?? []).flatMap((platformUser: IPlatformUser) => (allowInactiveUserImpersonation || platformUser.local.active ? [platformUser.account_id] : [])))
    );
  }, [platformUsers, allowInactiveUserImpersonation]);

  const accountMenuItems: IMenuItem[] = React.useMemo(() => {
    return (platformAccounts ?? [])
      .filter((account: IPlatformAccount) => accountsWithUsers.includes(account.account_id))
      .map((account: IPlatformAccount) => {
        return {
          key: account.account_id,
          name: `${account.company_name} (${account.account_id})`,
        };
      });
  }, [platformAccounts, accountsWithUsers]);

  const userMenuItems: IMenuItem[] = React.useMemo(() => {
    return (platformUsers ?? [])
      .filter((user: IPlatformUser) => user.account_id === selectedAccountId && (allowInactiveUserImpersonation || user.local.active))
      .map((user: IPlatformUser) => {
        return {
          key: user.resource_id,
          name: user.resource_name,
        };
      });
  }, [selectedAccountId, platformUsers, allowInactiveUserImpersonation]);

  const selectedAccountMenuItem: IMenuItem | null = React.useMemo(() => {
    return (accountMenuItems ?? []).find((menuItem: IMenuItem) => menuItem.key === selectedAccountId) ?? null;
  }, [accountMenuItems, selectedAccountId]);

  const selectedUserMenuItem: IMenuItem | null = React.useMemo(() => {
    return (userMenuItems ?? []).find((menuItem: IMenuItem) => menuItem.key === targetUserId) ?? null;
  }, [userMenuItems, targetUserId]);

  const handleAccountSelectionChange = React.useCallback(
    async (selected: string) => {
      const userInAccount: IPlatformUser | undefined = platformUsers?.find((user: IPlatformUser) => user.account_id === selected);
      if (userInAccount) {
        queryParams.set(USER_IMPERSONATION_QUERY_PARAM, userInAccount.resource_id);
        navigate({ pathname: `/${Resources.DASHBOARDS}`, search: queryParams.toString() });
      }
    },
    [platformUsers, queryParams, user]
  );

  const handleUserSelectionChange = React.useCallback(
    async (selected: string) => {
      const userInAccount: IPlatformUser | undefined = platformUsers?.find((user: IPlatformUser) => user.resource_id === selected);
      if (userInAccount) {
        queryParams.set(USER_IMPERSONATION_QUERY_PARAM, userInAccount.resource_id);
        navigate({ pathname: `/${Resources.DASHBOARDS}`, search: queryParams.toString() });
      }
    },
    [queryParams, platformUsers]
  );

  const handleClickEndSession = React.useCallback(() => {
    queryParams.delete(USER_IMPERSONATION_QUERY_PARAM);
    queryParams.delete(HIDE_IMPERSONATION_BANNER_QUERY_PARAM);
    navigate({ pathname: `/${Resources.DASHBOARDS}`, search: queryParams.toString() });
    setUser(loggedInUser);
    invalidateQueries(queryClient);
  }, [queryParams, loggedInUser]);

  return (
    <Stack flex="1 1 auto" direction="row" alignItems="center" justifyContent="space-between" m="0 48px">
      <Stack direction="row" alignItems="center" spacing={1}>
        <WarningRoundedIcon sx={{ color: colors["grey-100"] }} />
        <Typography sx={{ color: colors["grey-100"] }}>{translate("impersonation.workingIn")}</Typography>
        <Box width="220px" sx={{ filter: "invert(1)" }} pt="1px">
          {isAccountsLoading || isUsersLoading ? (
            <Skeleton sx={{ transform: "none" }} />
          ) : (
            <AutocompleteSelectField selectedValue={selectedAccountMenuItem} options={accountMenuItems} variant="standard" onSelectChange={handleAccountSelectionChange} />
          )}
        </Box>
        <Typography sx={{ color: colors["grey-100"] }}>{translate("generic.as")}</Typography>
        <Box width="220px" sx={{ filter: "invert(1)" }} pt="1px">
          {isUsersLoading ? (
            <Skeleton sx={{ transform: "none" }} />
          ) : (
            <AutocompleteSelectField selectedValue={selectedUserMenuItem} options={userMenuItems} variant="standard" onSelectChange={handleUserSelectionChange} />
          )}
        </Box>
      </Stack>
      <Stack direction="row">
        <EndSessionButton onClick={handleClickEndSession}>
          {translate("impersonation.endSession")}
          <Box display="flex" alignItems="center" ml="6px">
            <CloseIcon sx={{ fontSize: "20px" }} />
          </Box>
        </EndSessionButton>
      </Stack>
    </Stack>
  );
};

export function invalidateQueries(queryClient: QueryClient): void {
  queryClient.invalidateQueries(Resources.DASHBOARDS);
  queryClient.invalidateQueries(Resources.REPORTS);
  queryClient.invalidateQueries(Resources.OBJECTS);
  queryClient.invalidateQueries(Resources.PLATFORM_APPS);
  queryClient.invalidateQueries(Resources.PLATFORM_APP_INSTANCES);
  queryClient.invalidateQueries(Resources.PLATFORM_ROLES);
  queryClient.invalidateQueries(Resources.DATA_IMPORT);
  queryClient.invalidateQueries(Resources.PLATFORM_USERS);
}
