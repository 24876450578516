import { TIME_FILLED_TABLE_ALIAS } from "../constants/functions";
import {
  IAliasedTableFunctionRelation,
  IBinaryLogicalJoinConidition,
  IExpressionType,
  IJoinExpression,
  IJoinType,
  IRelation,
  IRelationalJoinCondition,
  IRelationalOp,
  IRelationType,
  ITableIdentifier,
} from "../conversions/ReportConversions";
import { IObjectDef } from "../types/object";
import { IAliasedRelation, IColumnIdentifier, IExpression } from "./../conversions/ReportConversions";
import { IColumnDef } from "./../types/object";
import { IColumnEntry } from "./../types/report";
import { containsAllElements } from "./arrayUtils";
import { getColumnEntriesFromExpressions } from "./expressionUtils";

export function buildJoinExpression(column: IColumnDef, leftTable: IObjectDef, rightTable: IObjectDef): IJoinExpression {
  if (!column.ext_link) {
    throw new Error(`Cannot build a join from column ${column.col_id} with no ext_link`);
  }

  return {
    kind: IRelationType.JoinExpression,
    left: {
      kind: IRelationType.TableIdentifier,
      source_obj_id: leftTable.resource_id,
    },
    right: {
      kind: IRelationType.TableIdentifier,
      source_obj_id: rightTable.resource_id,
    },
    type: IJoinType.Inner,
    condition: {
      kind: IExpressionType.RelationalBooleanExpression,
      op: IRelationalOp.Equals,
      left: {
        kind: IExpressionType.ColumnIdentifier,
        col: {
          col_id: column.col_id,
          obj_id: leftTable.resource_id,
        },
      },
      right: {
        kind: IExpressionType.ColumnIdentifier,
        col: {
          col_id: column.ext_link.col_id,
          obj_id: column.ext_link.obj_id,
        },
      },
    },
  };
}

/**
 * If the {newRelation} is an {IAliasedRelation}, replaces the {obj_id} in the join condition with the alias.
 *
 * @param newRelation
 * @param originalCondition
 * @param isLeft - true if the {newRelation} is associated with the left join condition. false if associated with the right.
 * @returns
 */
export function updateJoinCondition(newRelation: IRelation, originalCondition: IRelationalJoinCondition, isLeft: boolean): IRelationalJoinCondition {
  if (newRelation.kind === IRelationType.AliasedRelation) {
    if (isLeft) {
      return {
        ...originalCondition,
        left: {
          ...originalCondition.left,
          col: {
            ...originalCondition.left.col,
            obj_id: newRelation.alias,
          },
        },
      };
    } else {
      return {
        ...originalCondition,
        right: {
          ...originalCondition.right,
          col: {
            ...originalCondition.right.col,
            obj_id: newRelation.alias,
          },
        },
      };
    }
  } else {
    return originalCondition;
  }
}

export function checkJoinIdentifierEquality(a: IColumnIdentifier, b: IColumnIdentifier): boolean {
  return a.col.col_id === b.col.col_id && (a.col.obj_id.includes(b.col.obj_id) || b.col.obj_id.includes(a.col.obj_id));
}

/**
 * Attempts to combine two {IJoinExpression} based on the objects used within each.
 *
 * General logic:
 * - If one of the left/right params of the joins is a superset of the other, combine that relation with the other join.
 *   Ex: If leftJoin.left contains all objects of rightJoin.left, combine leftJoin.left with rightJoin.
 * - Take the join condition of the side which had the superset, and update the obj_id of the column identifiers to the alias of the combined relation.
 * - Take the new relation & create a new join expression with the other side of the join & the updated condition.
 *
 *
 * @param leftJoin
 * @param rightJoin
 * @param idToObjectMap
 * @returns
 */
export function combineJoinExpressions(leftJoin: IJoinExpression, rightJoin: IJoinExpression, idToObjectMap: Record<string, IObjectDef>): IJoinExpression {
  // Left join objects
  const leftLeftObjIds: string[] = getObjectIdsFromRelation(leftJoin.left);
  const leftRightObjIds: string[] = getObjectIdsFromRelation(leftJoin.right);

  // Right join objects
  const rightLeftObjIds: string[] = getObjectIdsFromRelation(rightJoin.left);
  const rightRightObjIds: string[] = getObjectIdsFromRelation(rightJoin.right);

  if (leftJoin.condition.kind === IExpressionType.RelationalBooleanExpression && rightJoin.condition.kind === IExpressionType.RelationalBooleanExpression) {
    if (leftLeftObjIds.every((val: string) => rightLeftObjIds.includes(val))) {
      // leftJoin.left contains a subset of rightJoin.left
      const leftRelation: IRelation = combineRelations(rightJoin.left, leftJoin, idToObjectMap);
      const updatedCondition: IRelationalJoinCondition = updateJoinCondition(leftRelation, rightJoin.condition, true);

      return {
        kind: IRelationType.JoinExpression,
        left: leftRelation,
        right: rightJoin.right,
        type: IJoinType.Inner,
        condition: updatedCondition,
      };
    } else if (leftLeftObjIds.every((val: string) => rightRightObjIds.includes(val))) {
      // leftJoin.left contains a subset of rightJoin.right
      const rightRelation: IRelation = combineRelations(rightJoin.right, leftJoin, idToObjectMap);
      const updatedCondition: IRelationalJoinCondition = updateJoinCondition(rightRelation, rightJoin.condition, false);

      return {
        kind: IRelationType.JoinExpression,
        left: rightJoin.left,
        right: rightRelation,
        type: IJoinType.Inner,
        condition: updatedCondition,
      };
    } else if (leftRightObjIds.every((val: string) => rightLeftObjIds.includes(val))) {
      // leftJoin.right contains a subset of rightJoin.left
      const leftRelation: IRelation = combineRelations(leftJoin, rightJoin.left, idToObjectMap);
      const updatedCondition: IRelationalJoinCondition = updateJoinCondition(leftRelation, rightJoin.condition, true);

      return {
        kind: IRelationType.JoinExpression,
        left: leftRelation,
        right: rightJoin.right,
        type: IJoinType.Inner,
        condition: updatedCondition,
      };
    } else if (leftRightObjIds.every((val: string) => rightRightObjIds.includes(val))) {
      // leftJoin.right contains a subset of rightJoin.right
      const rightRelation: IRelation = combineRelations(leftJoin, rightJoin.right, idToObjectMap);
      const updatedCondition: IRelationalJoinCondition = updateJoinCondition(rightRelation, rightJoin.condition, false);

      return {
        kind: IRelationType.JoinExpression,
        left: leftJoin.left,
        right: rightRelation,
        type: IJoinType.Inner,
        condition: updatedCondition,
      };
    } else if (rightLeftObjIds.every((val: string) => leftLeftObjIds.includes(val))) {
      // rightJoin.left contains a subset of leftJoin.left
      const leftRelation: IRelation = combineRelations(leftJoin.left, rightJoin, idToObjectMap);
      const updatedCondition: IRelationalJoinCondition = updateJoinCondition(leftRelation, leftJoin.condition, true);

      return {
        kind: IRelationType.JoinExpression,
        left: leftRelation,
        right: leftJoin.right,
        type: IJoinType.Inner,
        condition: updatedCondition,
      };
    } else if (rightLeftObjIds.every((val: string) => leftRightObjIds.includes(val))) {
      // rightJoin.left contains a subset of leftJoin.right
      const rightRelation: IRelation = combineRelations(leftJoin.right, rightJoin, idToObjectMap);
      const updatedCondition: IRelationalJoinCondition = updateJoinCondition(rightRelation, leftJoin.condition, false);

      return {
        kind: IRelationType.JoinExpression,
        left: leftJoin.left,
        right: rightRelation,
        type: IJoinType.Inner,
        condition: updatedCondition,
      };
    } else if (rightRightObjIds.every((val: string) => leftLeftObjIds.includes(val))) {
      // rightJoin.right contains a subset of leftJoin.left
      const leftRelation: IRelation = combineRelations(leftJoin.left, rightJoin, idToObjectMap);
      const updatedCondition: IRelationalJoinCondition = updateJoinCondition(leftRelation, leftJoin.condition, true);

      return {
        kind: IRelationType.JoinExpression,
        left: leftRelation,
        right: leftJoin.right,
        type: IJoinType.Inner,
        condition: updatedCondition,
      };
    } else if (rightRightObjIds.every((val: string) => leftRightObjIds.includes(val))) {
      // rightJoin.right contains a subset of leftJoin.right
      const rightRelation: IRelation = combineRelations(leftJoin.right, rightJoin, idToObjectMap);
      const updatedCondition: IRelationalJoinCondition = updateJoinCondition(rightRelation, leftJoin.condition, false);

      return {
        kind: IRelationType.JoinExpression,
        left: leftJoin.left,
        right: rightRelation,
        type: IJoinType.Inner,
        condition: updatedCondition,
      };
    }
  }

  return {
    kind: IRelationType.JoinExpression,
    left: combineRelations(leftJoin.left, rightJoin, idToObjectMap),
    right: leftJoin.right,
    type: IJoinType.Inner,
    condition: leftJoin.condition,
  };
}

export function combineRelations(leftRelation: IRelation, rightRelation: IRelation, idToObjectMap: Record<string, IObjectDef>): IRelation {
  const leftObjIds: string[] = getObjectIdsFromRelation(leftRelation);
  const rightObjIds: string[] = getObjectIdsFromRelation(rightRelation);

  if (
    leftRelation.kind === IRelationType.AliasedRelation &&
    leftRelation.alias === TIME_FILLED_TABLE_ALIAS &&
    leftRelation.relation.kind === IRelationType.TableFunction &&
    leftRelation.relation.relations.length === 2
  ) {
    // This is checking to see if the "main" relation is wrapped in the relations/table functions which handle filling in gaps in a time series.
    return {
      ...leftRelation,
      relation: {
        ...leftRelation.relation,
        relations: [leftRelation.relation.relations[0], combineRelations(leftRelation.relation.relations[1], rightRelation, idToObjectMap)],
      },
    };
  } else if (leftRelation.kind === IRelationType.AliasedRelation && leftRelation.relation.kind === IRelationType.TableFunction && rightObjIds.includes(leftRelation.alias)) {
    return replaceRelation(rightRelation, leftRelation.alias, leftRelation);
  } else if (leftRelation.kind === IRelationType.TableIdentifier || (containsAllElements(rightObjIds, leftObjIds) && !containsTableFunction(leftRelation))) {
    return wrapJoinRelation(rightRelation);
  } else if (rightRelation.kind === IRelationType.TableIdentifier || (containsAllElements(leftObjIds, rightObjIds) && !containsTableFunction(rightRelation))) {
    return wrapJoinRelation(leftRelation);
  } else if (leftRelation.kind === IRelationType.JoinExpression && rightRelation.kind === IRelationType.JoinExpression) {
    return wrapJoinRelation(combineJoinExpressions(leftRelation, rightRelation, idToObjectMap));
  } else if (leftRelation.kind === IRelationType.AliasedRelation) {
    return wrapJoinRelation(combineRelations(leftRelation.relation, rightRelation, idToObjectMap));
  } else if (rightRelation.kind === IRelationType.AliasedRelation) {
    return wrapJoinRelation(combineRelations(leftRelation, rightRelation.relation, idToObjectMap));
  } else {
    throw new Error(`Missing implementation for relation types ${leftRelation.kind} ${rightRelation.kind}`);
  }
}

export function replaceRelation(mainRelation: IRelation, prevRelationId: string, newRelation: IRelation): IRelation {
  switch (mainRelation.kind) {
    case IRelationType.TableIdentifier:
      if (mainRelation.source_obj_id === prevRelationId) {
        return newRelation;
      } else {
        return mainRelation;
      }
    case IRelationType.JoinExpression:
      return {
        ...mainRelation,
        left: replaceRelation(mainRelation.left, prevRelationId, newRelation),
        right: replaceRelation(mainRelation.right, prevRelationId, newRelation),
      };
    case IRelationType.AliasedRelation:
      if (mainRelation.alias === prevRelationId) {
        return newRelation;
      } else {
        return {
          ...mainRelation,
          relation: replaceRelation(mainRelation.relation, prevRelationId, newRelation),
        };
      }
    case IRelationType.TableFunction:
      return { ...mainRelation, relations: mainRelation.relations.flatMap((childRelation) => replaceRelation(childRelation, prevRelationId, newRelation)) };
    case IRelationType.InlinedTableIdentifier:
      return mainRelation;
  }
}

export function buildAliasedRelationFromJoin(join: IJoinExpression): IAliasedRelation {
  const objectIds: string[] = Array.from(new Set(getObjectIdsFromRelation(join)));
  return {
    kind: IRelationType.AliasedRelation,
    relation: join,
    alias: objectIds.join("_"),
  };
}

export function wrapJoinRelation(relation: IRelation): IRelation {
  if (relation.kind === IRelationType.JoinExpression) {
    return buildAliasedRelationFromJoin(relation);
  } else {
    return relation;
  }
}

export function getObjectIdsFromRelation(relation: IRelation): string[] {
  switch (relation.kind) {
    case IRelationType.TableIdentifier:
      return [relation.source_obj_id];
    case IRelationType.JoinExpression:
      return [...getObjectIdsFromRelation(relation.left), ...getObjectIdsFromRelation(relation.right)];
    case IRelationType.AliasedRelation:
      if (relation.relation.kind === IRelationType.TableFunction) {
        // Alias wrapping the table function is used to reference the table function
        return [relation.alias, ...getObjectIdsFromRelation(relation.relation)];
      } else {
        return getObjectIdsFromRelation(relation.relation);
      }
    case IRelationType.TableFunction:
      return relation.relations.flatMap((childRelation) => getObjectIdsFromRelation(childRelation));
    case IRelationType.InlinedTableIdentifier:
      return [relation.inlined_obj.resource_id];
    default:
      return [];
  }
}

export function getObjectDefsFromRelation(relation: IRelation, idToObjectMap: Record<string, IObjectDef>): IObjectDef[] {
  switch (relation.kind) {
    case IRelationType.TableIdentifier:
      return idToObjectMap[relation.source_obj_id] ? [idToObjectMap[relation.source_obj_id]] : [];
    case IRelationType.JoinExpression:
      return [...getObjectDefsFromRelation(relation.left, idToObjectMap), ...getObjectDefsFromRelation(relation.right, idToObjectMap)];
    case IRelationType.AliasedRelation:
      if (relation.relation.kind === IRelationType.TableIdentifier) {
        const underlyingObject: IObjectDef | undefined = idToObjectMap[relation.relation.source_obj_id];
        return underlyingObject
          ? [
              {
                ...underlyingObject,
                resource_id: relation.alias,
                resource_name: relation.alias,
                default_cols: underlyingObject.default_cols.map((col: IColumnDef) => ({ ...col, obj_id: relation.alias })),
              },
            ]
          : [];
      } else {
        return getObjectDefsFromRelation(relation.relation, idToObjectMap);
      }
    case IRelationType.TableFunction:
      return relation.relations.flatMap((childRelation) => getObjectDefsFromRelation(childRelation, idToObjectMap));
    case IRelationType.InlinedTableIdentifier:
      return idToObjectMap[relation.inlined_obj.resource_id] ? [idToObjectMap[relation.inlined_obj.resource_id]] : [];
    default:
      return [];
  }
}

export function getBaseObjectIdFromRelation(relation: IRelation): string | undefined {
  switch (relation.kind) {
    case IRelationType.TableIdentifier:
      return relation.source_obj_id;
    case IRelationType.JoinExpression:
      return getBaseObjectIdFromRelation(relation.left);
    case IRelationType.AliasedRelation:
      return getBaseObjectIdFromRelation(relation.relation);
    case IRelationType.TableFunction:
      return relation.relations.length > 0 ? getBaseObjectIdFromRelation(relation.relations[0]) : undefined;
    case IRelationType.InlinedTableIdentifier:
      return relation.inlined_obj.resource_id;
  }
}

export function containsTableFunction(relation: IRelation): boolean {
  switch (relation.kind) {
    case IRelationType.InlinedTableIdentifier:
    case IRelationType.TableIdentifier:
      return false;
    case IRelationType.JoinExpression:
      return containsTableFunction(relation.left) || containsTableFunction(relation.right);
    case IRelationType.AliasedRelation:
      return containsTableFunction(relation.relation);
    case IRelationType.TableFunction:
      return true;
  }
}

export function getColumnIdToColumnNameMap(objects: IObjectDef[]): Record<string, string> {
  return objects.reduce((objAcc: Record<string, string>, object: IObjectDef) => {
    return object.default_cols.reduce((colAcc: Record<string, string>, col: IColumnDef) => {
      colAcc[col.col_id] = col.col_name;
      return colAcc;
    }, objAcc);
  }, {});
}

export function getColumnIdToColumnDefMap(objects: IObjectDef[]): Record<string, IColumnDef> {
  return objects.reduce((objAcc: Record<string, IColumnDef>, object: IObjectDef) => {
    return object.default_cols.reduce((colAcc: Record<string, IColumnDef>, col: IColumnDef) => {
      colAcc[col.col_id] = col;
      return colAcc;
    }, objAcc);
  }, {});
}

/**
 * Removes unused {IRelation} from {relation} passed in, determined by the objects used in the {expressions}.
 * If no objects are used, returns the base object relation.
 *
 * @param expressions all expressions used in the report query
 * @param relation the relation used in the report query
 * @returns
 */
export function simplifyRelation(expressions: IExpression[], relation: IRelation, idToObjectMap: Record<string, IObjectDef>): IRelation {
  const columnEntries: IColumnEntry[] = getColumnEntriesFromExpressions(expressions);
  const objectIds: string[] = getObjectIdsFromRelation(relation);

  const unusedObjectIds: string[] = objectIds.filter((objectId: string) => {
    return columnEntries.every((columnEntry: IColumnEntry) => columnEntry.obj_id !== objectId);
  });

  const baseObjectId: string | undefined = getBaseObjectIdFromRelation(relation);

  return (
    simplifyRelationHelper(unusedObjectIds, relation, idToObjectMap, baseObjectId) ??
    (baseObjectId ? { kind: IRelationType.TableIdentifier, source_obj_id: baseObjectId } : relation)
  );
}

export function simplifyRelationHelper(unusedObjectIds: string[], relation: IRelation, idToObjectMap: Record<string, IObjectDef>, baseObjectId?: string): IRelation | undefined {
  if (
    relation.kind === IRelationType.AliasedRelation &&
    relation.alias === TIME_FILLED_TABLE_ALIAS &&
    relation.relation.kind === IRelationType.TableFunction &&
    relation.relation.relations.length === 2
  ) {
    // Check to see if the "main" relation is wrapped in the relations/table functions which handle filling in gaps in a time series.
    if (unusedObjectIds.includes(TIME_FILLED_TABLE_ALIAS)) {
      // If the TIME_FILLED_TABLE_ALIAS relations are no longer used, return the main relation
      const aliasedTableFunctionRelation: IAliasedTableFunctionRelation | undefined = getAliasedTableFunction(relation.relation.relations[1]);
      if (aliasedTableFunctionRelation?.relation?.relations?.[0]?.kind === IRelationType.TableIdentifier) {
        return replaceRelation(relation.relation.relations[1], aliasedTableFunctionRelation.alias, aliasedTableFunctionRelation.relation.relations[0]);
      } else {
        return relation.relation.relations[1];
      }
    } else {
      // Otherwise, attempt to simplify the main relation
      const simplifiedRelation: IRelation | undefined = simplifyRelationHelper(unusedObjectIds, relation.relation.relations[1], idToObjectMap, baseObjectId);
      return simplifiedRelation
        ? {
            ...relation,
            relation: {
              ...relation.relation,
              relations: [relation.relation.relations[0], simplifiedRelation],
            },
          }
        : undefined;
    }
  }
  if (relation.kind === IRelationType.JoinExpression) {
    const leftRelation: IRelation | undefined = simplifyRelationHelper(unusedObjectIds, relation.left, idToObjectMap, baseObjectId);
    const rightRelation: IRelation | undefined = simplifyRelationHelper(unusedObjectIds, relation.right, idToObjectMap, baseObjectId);

    if (leftRelation && rightRelation) {
      if (leftRelation === relation.left && rightRelation === relation.right) {
        // Relation is the same as before, return the original
        return relation;
      } else {
        const { left: leftConditionObjectIds, right: rightConditionObjectIds } = getObjectIdsFromJoinCondition(relation.condition);

        const leftRelationObjectIds: string[] = getObjectIdsFromRelation(leftRelation);
        const leftRelationColumnIds: string[] = getColumnIdsFromObjectIds(leftRelationObjectIds, idToObjectMap);

        const rightRelationObjectIds: string[] = getObjectIdsFromRelation(rightRelation);
        const rightRelationColumnIds: string[] = getColumnIdsFromObjectIds(rightRelationObjectIds, idToObjectMap);

        if (
          leftConditionObjectIds.every((val: string) => leftRelationObjectIds.includes(val)) &&
          rightConditionObjectIds.every((val: string) => rightRelationObjectIds.includes(val))
        ) {
          // Join condition is still valid for the relation
          return {
            kind: IRelationType.JoinExpression,
            left: leftRelation,
            right: rightRelation,
            type: IJoinType.Inner,
            condition: relation.condition,
          };
        } else if (
          relation.condition.kind === IExpressionType.RelationalBooleanExpression &&
          leftRelationObjectIds.every((objId: string) => (relation.condition as IRelationalJoinCondition).left.col.obj_id.includes(objId)) &&
          leftRelationColumnIds.includes(relation.condition.left.col.col_id) &&
          rightRelationObjectIds.every((objId: string) => (relation.condition as IRelationalJoinCondition).right.col.obj_id.includes(objId)) &&
          rightRelationColumnIds.includes(relation.condition.right.col.col_id) &&
          (leftRelation.kind === IRelationType.AliasedRelation || leftRelation.kind === IRelationType.TableIdentifier || leftRelation.kind === IRelationType.JoinExpression) &&
          (rightRelation.kind === IRelationType.AliasedRelation || rightRelation.kind === IRelationType.TableIdentifier || rightRelation.kind === IRelationType.JoinExpression)
        ) {
          // Join condition is valid with some modification to the obj_ids
          const [leftIdentifier, updatedLeftRelation] = getRelationIdentifier(leftRelation);
          const [rightIdentifier, updatedRightRelation] = getRelationIdentifier(rightRelation);

          return {
            kind: IRelationType.JoinExpression,
            left: updatedLeftRelation,
            right: updatedRightRelation,
            type: IJoinType.Inner,
            condition: {
              ...relation.condition,
              left: {
                ...relation.condition.left,
                col: {
                  ...relation.condition.left.col,
                  obj_id: leftIdentifier,
                },
              },
              right: {
                ...relation.condition.right,
                col: {
                  ...relation.condition.right.col,
                  obj_id: rightIdentifier,
                },
              },
            },
          };
        } else {
          // Join condition is invalid for the relation, return the original
          return relation;
        }
      }
    } else if (leftRelation) {
      return leftRelation;
    } else if (rightRelation) {
      return rightRelation;
    } else {
      return undefined;
    }
  } else if (relation.kind === IRelationType.AliasedRelation) {
    if (relation.relation.kind === IRelationType.TableFunction) {
      // Alias wrapping the table function is used to reference the table function
      return unusedObjectIds.includes(relation.alias) ? undefined : relation;
    } else {
      return simplifyRelationHelper(unusedObjectIds, relation.relation, idToObjectMap, baseObjectId);
    }
  } else if (relation.kind === IRelationType.TableIdentifier) {
    return unusedObjectIds.includes(relation.source_obj_id) && relation.source_obj_id !== baseObjectId ? undefined : relation;
  } else {
    return relation;
  }
}

export function getColumnIdsFromObjectIds(objectIds: string[], idToObjectMap: Record<string, IObjectDef>): string[] {
  return objectIds.flatMap((objectId: string) => idToObjectMap[objectId]?.default_cols?.map((col: IColumnDef) => col.col_id) ?? []);
}

/**
 * Returns a tuple of an ID for the relation that can be used in a join condition + the associated relation.
 * If the relation pased in is a IJoinExpression, it is converted to a IAliasedRelation, so the alias can be used as the ID.
 * @param relation
 * @returns
 */
export function getRelationIdentifier(relation: IAliasedRelation | IJoinExpression | ITableIdentifier): [string, IAliasedRelation | ITableIdentifier] {
  if (relation.kind === IRelationType.AliasedRelation) {
    return [relation.alias, relation];
  } else if (relation.kind === IRelationType.JoinExpression) {
    const aliasedRelation: IAliasedRelation = buildAliasedRelationFromJoin(relation);
    return [aliasedRelation.alias, aliasedRelation];
  } else {
    return [relation.source_obj_id, relation];
  }
}

export function getObjectIdsFromJoinCondition(condition: IRelationalJoinCondition | IBinaryLogicalJoinConidition): { left: string[]; right: string[] } {
  if (condition.kind === IExpressionType.RelationalBooleanExpression) {
    return { left: [condition.left.col.obj_id], right: [condition.right.col.obj_id] };
  } else {
    const left = getObjectIdsFromJoinCondition(condition.left);
    const right = getObjectIdsFromJoinCondition(condition.right);
    return { left: Array.from(new Set([...left.left, ...right.left])), right: Array.from(new Set([...left.right, ...right.right])) };
  }
}

/**
 * In the {IRelation} tree, if there is a {IAliasedRelation} which directly contains a {ITableFunction} as the child relation,
 * return it as a {IAliasedTableFunctionRelation}.
 */
export function getAliasedTableFunction(relation: IRelation): IAliasedTableFunctionRelation | undefined {
  switch (relation.kind) {
    case IRelationType.JoinExpression:
      return getAliasedTableFunction(relation.left) ?? getAliasedTableFunction(relation.right);
    case IRelationType.AliasedRelation:
      return relation.relation.kind === IRelationType.TableFunction ? (relation as IAliasedTableFunctionRelation) : getAliasedTableFunction(relation.relation);
    case IRelationType.TableFunction:
      return undefined;
    case IRelationType.TableIdentifier:
    default:
      return undefined;
  }
}

/**
 * Traverses the {mainRelation} to find an {IAliasedRelation} which has the same alias as {oldRelation}.
 * If it is found as part of a {IJoinExpression}, replace it with the {newRelation} & update the condition.
 * If it is found directly as part of an {IAliasedRelation}, replace it.
 * If it cannot be found, return undefined.
 */
export function replaceAliasedRelation(
  mainRelation: IRelation,
  oldRelation: IAliasedRelation,
  newRelation: IAliasedRelation,
  condition: IRelationalJoinCondition | IBinaryLogicalJoinConidition
): IRelation | undefined {
  switch (mainRelation.kind) {
    case IRelationType.JoinExpression:
      if (mainRelation.left.kind === IRelationType.AliasedRelation && mainRelation.left.alias === oldRelation.alias) {
        return {
          ...mainRelation,
          left: newRelation,
          condition: condition,
        };
      } else if (mainRelation.right.kind === IRelationType.AliasedRelation && mainRelation.right.alias === oldRelation.alias) {
        return {
          ...mainRelation,
          right: newRelation,
          condition: condition,
        };
      } else {
        return replaceAliasedRelation(mainRelation.left, oldRelation, newRelation, condition) ?? replaceAliasedRelation(mainRelation.right, oldRelation, newRelation, condition);
      }
    case IRelationType.AliasedRelation:
      return mainRelation.alias === oldRelation.alias ? newRelation : replaceAliasedRelation(mainRelation.relation, oldRelation, newRelation, condition);
    case IRelationType.TableFunction:
      return undefined;
    case IRelationType.TableIdentifier:
    default:
      return undefined;
  }
}
