import { UseFormSetValue } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { ITimestampToDateOptions, TIME_BUCKETS_COLUMN_ALIAS, TIME_FILLED_TABLE_ALIAS, TIME_SERIES_ALIAS } from "../constants/functions";
import { TIME_RANGE_END_PARAMETER_NAME, TIME_RANGE_START_PARAMETER_NAME } from "../constants/parameterization";
import {
  IAliasedRelation,
  IBinaryLogicalJoinConidition,
  IBooleanOp,
  IColumnIdentifier,
  IExpressionType,
  IFunctionExpression,
  IJoinType,
  ILiteralExpression,
  IParameterizedExpression,
  IRelation,
  IRelationType,
  IRelationalBooleanExpression,
  IRelationalJoinCondition,
  IRelationalOp,
  ITableFunction,
} from "../conversions/ReportConversions";
import { IFunctionExpressionEditorForm } from "../reports/Editors/DerivedColumn/FunctionExpressionInputs";
import { IFunction, ITimeFilledTableFunction } from "../types/functions";
import { IColumnDef, IObjectDef } from "../types/object";
import { ExpressionUsageType, IDerivedColumnInitialInputs, IReportQuery, IRowFilter, ITableFunctionExpressionInitialInputs, Report } from "../types/report";
import { IAggregateExpression, IAggregateFunctionType, IAliasedExpression, IBinaryArithmeticOp, IExpression, IStat } from "./../conversions/ReportConversions";
import { IColumnEntry } from "./../types/report";
import {
  aliasedExpressionHasNumericOutput,
  buildAggregationAlias,
  buildColumnIdentifierFromDef,
  buildCountAliasedExpression,
  combineBooleanExpressions,
  getBaseAliasFromAggregationKey,
  instanceOfIExpression,
  replaceExpressionColIdsWithAlias,
  replaceExpressionObjectIds,
  replaceExpressionsIds,
} from "./expressionUtils";
import { getAliasedTableFunction, getObjectIdsFromRelation, replaceAliasedRelation, simplifyRelationHelper } from "./joinUtils";

//-----------------------------------------------------------------------
// Expression Building
//-----------------------------------------------------------------------

export function buildAggregateAliasedExpressionFromFunction(
  functionId: string,
  alias: string,
  functionParameters: IExpression[],
  aggregationType: IAggregateFunctionType
): IAliasedExpression {
  return {
    kind: IExpressionType.AliasedExpression,
    expr: {
      kind: IExpressionType.AggregateExpression,
      function: aggregationType,
      expr: buildFunctionExpression(functionId, alias, functionParameters),
    },
    alias: alias,
  };
}

export function buildAliasedExpressionFromFunction(functionId: string, alias: string, functionParameters: IExpression[]): IAliasedExpression {
  return {
    kind: IExpressionType.AliasedExpression,
    expr: buildFunctionExpression(functionId, alias, functionParameters),
    alias: alias,
  };
}

export function buildFunctionExpression(functionId: string, alias: string, functionParameters: IExpression[]): IFunctionExpression {
  return {
    kind: IExpressionType.FunctionExpression,
    alias: alias,
    operands: functionParameters,
    id: functionId,
  };
}

export function buildAggregateInlineAliasedExpression(value: string, alias: string, aggregationType: IAggregateFunctionType): IAliasedExpression {
  return {
    kind: IExpressionType.AliasedExpression,
    expr: {
      kind: IExpressionType.AggregateExpression,
      function: aggregationType,
      expr: {
        kind: IExpressionType.InlineExpression,
        value: value,
        source_obj_columns: getInlineExpressionColumnEntries(value),
        alias: alias,
      },
    },
    alias: alias,
  };
}

export function buildInlineExpressionAliasedExpression(value: string, alias: string): IAliasedExpression {
  return {
    kind: IExpressionType.AliasedExpression,
    expr: {
      kind: IExpressionType.InlineExpression,
      value: value,
      source_obj_columns: getInlineExpressionColumnEntries(value),
      alias: alias,
    },
    alias: alias,
  };
}

export function buildBinaryExpressionAliasedExpression(
  left: number | IAliasedExpression | IParameterizedExpression,
  right: number | IAliasedExpression | IParameterizedExpression,
  op: IBinaryArithmeticOp,
  alias: string
): IAliasedExpression {
  return {
    kind: IExpressionType.AliasedExpression,
    alias: alias,
    expr: {
      kind: IExpressionType.BinaryExpression,
      left: buildExpressionFromNumberOrExpression(left),
      right: buildExpressionFromNumberOrExpression(right),
      op: op,
    },
  };
}

export function buildExpressionFromNumberOrExpression(value: number | IAliasedExpression | IParameterizedExpression): IExpression {
  if (typeof value === "number") {
    return {
      kind: IExpressionType.LiteralExpression,
      value: value,
    };
  } else if (value.kind === IExpressionType.ParameterizedExpression) {
    return value;
  } else {
    if (aliasedExpressionHasNumericOutput(value)) {
      return value.expr;
    } else {
      return aliasedExpressionToAggregateExpression(value);
    }
  }
}

export function aliasedExpressionToAggregateExpression(expression: IAliasedExpression): IAggregateExpression {
  return {
    kind: IExpressionType.AggregateExpression,
    function: IAggregateFunctionType.Count,
    expr: expression.expr,
  };
}

function aggregationTypeToStat(aggregationType: IAggregateFunctionType): IStat | undefined {
  switch (aggregationType) {
    case IAggregateFunctionType.Avg:
      return IStat.Avg;
    case IAggregateFunctionType.Sum:
      return IStat.Sum;
    case IAggregateFunctionType.Max:
      return IStat.Max;
    case IAggregateFunctionType.Min:
      return IStat.Min;
    default:
      return undefined;
  }
}

export function statsToAggregationType(stats: IStat[]): IAggregateFunctionType {
  if (stats.length === 0) {
    return IAggregateFunctionType.Count;
  } else {
    return statToAggregationType(stats[0]);
  }
}

export function statToAggregationType(stat: IStat): IAggregateFunctionType {
  switch (stat) {
    case IStat.Avg:
      return IAggregateFunctionType.Avg;
    case IStat.Max:
      return IAggregateFunctionType.Max;
    case IStat.Min:
      return IAggregateFunctionType.Min;
    case IStat.Sum:
    default:
      return IAggregateFunctionType.Sum;
  }
}

//-----------------------------------------------------------------------
// Get IColumnEntry[] from expression value
//-----------------------------------------------------------------------

// TODO: temp hack until UI workflow is redesigned
export function getInlineExpressionColumnEntries(value: string): IColumnEntry[] {
  const matches: RegExpMatchArray | null = value.match(/\".*?\"\.\".*?\"/gm);

  if (matches) {
    const colEntries: IColumnEntry[] = matches.map((match: string) => {
      const [objId, ...rest] = match.split(".");
      const colId: string = rest.join(".");

      return {
        obj_id: objId.replaceAll('"', ""),
        col_id: colId.replaceAll('"', ""),
      };
    });

    return colEntries.filter((colEntry: IColumnEntry, index: number) => {
      const currentIds = colEntries.map((e: IColumnEntry) => `${e.obj_id}-${e.col_id}`);
      return index === currentIds.indexOf(`${colEntry.obj_id}-${colEntry.col_id}`);
    });
  } else {
    return [];
  }
}

//-----------------------------------------------------------------------
// Expression Updating
//-----------------------------------------------------------------------

export function handleInsertNewExpression(
  formulaUsageType: ExpressionUsageType,
  newExpression: IAliasedExpression,
  report: Partial<Report>,
  initialInputs: IDerivedColumnInitialInputs | undefined,
  setValue: UseFormSetValue<Report>
): void {
  var expressionsToUpdate: IExpression[] = [...getExpressionsToUpdate(report, formulaUsageType)];
  let reportQuery: IReportQuery;

  if (initialInputs) {
    var previousExpressions: IAliasedExpression[] = [...getExpressionsToUpdate(report, initialInputs.formulaUsageType)];

    const relatedAggregationExpressions: IAliasedExpression[] = previousExpressions.filter(
      (expression: IAliasedExpression) =>
        expression.expr.kind === IExpressionType.AggregateExpression && initialInputs.alias === getBaseAliasFromAggregationKey(expression.alias ?? "")
    );

    if (initialInputs.formulaUsageType === ExpressionUsageType.COLUMN && formulaUsageType === ExpressionUsageType.COLUMN) {
      expressionsToUpdate = previousExpressions.flatMap((expression: IAliasedExpression, index: number) => {
        if (index === initialInputs.index) {
          return newExpression;
        } else {
          const relatedExpression: IAliasedExpression | undefined = relatedAggregationExpressions.find(
            (relatedExpression: IAliasedExpression) => relatedExpression.alias === expression.alias
          );
          if (relatedExpression && relatedExpression.expr.kind === IExpressionType.AggregateExpression) {
            return {
              kind: IExpressionType.AliasedExpression,
              alias: buildAggregationAlias(newExpression.alias ?? "", relatedExpression.expr.function),
              expr: {
                kind: IExpressionType.AggregateExpression,
                function: relatedExpression.expr.function,
                expr: newExpression.expr,
                distinct: relatedExpression.expr.distinct,
              },
            };
          } else {
            return [expression];
          }
        }
      });
      reportQuery = report.query!;
    } else if (initialInputs.formulaUsageType === ExpressionUsageType.COLUMN) {
      previousExpressions = previousExpressions.filter((expression: IAliasedExpression, index: number) => {
        return !relatedAggregationExpressions.some((relatedExpression: IAliasedExpression) => relatedExpression.alias === expression.alias) && initialInputs.index !== index;
      });
      expressionsToUpdate.push(newExpression);
      reportQuery = getUpdatedReportQuery(initialInputs.formulaUsageType, previousExpressions, report.query!);
    } else if (initialInputs.formulaUsageType !== formulaUsageType) {
      previousExpressions.splice(initialInputs.index, 1);
      expressionsToUpdate.push(newExpression);
      reportQuery = getUpdatedReportQuery(initialInputs.formulaUsageType, previousExpressions, report.query!);
    } else {
      expressionsToUpdate.splice(initialInputs.index, 1, newExpression);
      reportQuery = report.query!;
    }
  } else {
    expressionsToUpdate.push(newExpression);
    expressionsToUpdate.push(buildCountAliasedExpression(newExpression));
    reportQuery = report.query!;
  }

  handleUpdateExpressionValues(formulaUsageType, expressionsToUpdate, reportQuery, setValue);
}

export function handleUpdateExpressionValues(formulaUsage: ExpressionUsageType, expressions: IExpression[], reportQuery: IReportQuery, setValue: UseFormSetValue<Report>): void {
  if (formulaUsage === ExpressionUsageType.COLUMN) {
    setValue("query", { ...reportQuery, projections: expressions as IAliasedExpression[] }, { shouldValidate: true });
  } else if (formulaUsage === ExpressionUsageType.ROW_GROUPING) {
    setValue("query", { ...reportQuery, row_grouping: expressions as IAliasedExpression[] }, { shouldValidate: true });
  } else if (formulaUsage === ExpressionUsageType.COLUMN_GROUPING) {
    setValue("query", { ...reportQuery, col_grouping: expressions as IAliasedExpression[] }, { shouldValidate: true });
  }
}

export function getUpdatedReportQuery(formulaUsage: ExpressionUsageType, expressions: IExpression[], reportQuery: IReportQuery): IReportQuery {
  if (formulaUsage === ExpressionUsageType.COLUMN) {
    return { ...reportQuery, projections: expressions as IAliasedExpression[] };
  } else if (formulaUsage === ExpressionUsageType.ROW_GROUPING) {
    return { ...reportQuery, row_grouping: expressions as IAliasedExpression[] };
  } else {
    return { ...reportQuery, col_grouping: expressions as IAliasedExpression[] };
  }
}

export function getExpressionsToUpdate(report: Partial<Report>, formulaUsageType: ExpressionUsageType): IAliasedExpression[] {
  switch (formulaUsageType) {
    case ExpressionUsageType.ROW_GROUPING:
      return report.query?.row_grouping ?? [];
    case ExpressionUsageType.COLUMN_GROUPING:
      return report.query?.col_grouping ?? [];
    case ExpressionUsageType.COLUMN:
    default:
      return report.query?.projections ?? [];
  }
}

//-----------------------------------------------------------------------
// Table Functions
//-----------------------------------------------------------------------

/**
 * Returns true if the {value} appears as the alias for a {IAliasedRelation} which is a parent of a {ITableFunction}
 */
export function isTableFunctionIdentifier(value: string, relation: IRelation | undefined): boolean {
  return !!relation && getAliasedTableFunction(relation)?.alias === value;
}

/**
 * Returns the {IExpression}s which are used as user inputs to the {ITableFunction}
 */
export function getParametersFromTableFunction(tableFunction: ITableFunction): IExpression[] {
  const expr: IExpression[] | undefined = tableFunction.operands?.expr?.map((expression: IExpression) =>
    expression.kind === IExpressionType.AliasedExpression ? expression.expr : expression
  );
  const k: IExpression[] | undefined = tableFunction.operands?.k;

  return [...(expr ?? []), ...(k ?? [])];
}

/**
 * Builds an {Report} which contains a join with a {ITableFunction} & {IAliasedExpression} from user inputs.
 *
 * Will return undefined if the {ITableFunction} cannot be appropriately joined with the existing {IRelation} on the report.
 *
 * If this is an edit of an existing {ITableFunction}, the {ITableFunction} existing on the report query will be replaced by the one
 * created with the new inputs.
 *
 */
export function createTableFunction(
  form: IFunctionExpressionEditorForm,
  report: Report,
  initialInputs: ITableFunctionExpressionInitialInputs | undefined,
  objectMap: Record<string, IObjectDef>
): { expression: IAliasedExpression; report: Report } | undefined {
  const expr: IExpression[] = form.parameters.flatMap((param: any) => {
    return instanceOfIExpression(param) && param.kind !== IExpressionType.LiteralExpression
      ? [
          {
            kind: IExpressionType.AliasedExpression,
            alias: form.name,
            expr: param.kind === IExpressionType.AliasedExpression ? param.expr : param,
          },
        ]
      : [];
  });

  const k: ILiteralExpression[] = form.parameters.flatMap((param: any) => {
    return instanceOfIExpression(param) && param.kind === IExpressionType.LiteralExpression && !isNaN(Number(param.value))
      ? [
          {
            kind: IExpressionType.LiteralExpression,
            value: Number(param.value),
          },
        ]
      : [];
  });

  // If this is an edit of an existing table function - replace the old ids with the new ids
  const updatedRowFilters: IRowFilter[] = initialInputs
    ? replaceExpressionsIds(report.query.rowFilters, initialInputs.relation.alias, form.name, initialInputs.alias, form.name)
    : report.query.rowFilters;

  const tableFunction: ITableFunction = {
    kind: IRelationType.TableFunction,
    id: form.function,
    relations: initialInputs ? [simplifyRelationHelper([initialInputs.relation.alias], report.query.objects, objectMap) ?? report.query.objects] : [report.query.objects],
    operands: {
      expr: expr,
      k: k,
      row_grouping: report.query.row_grouping,
      row_filter: updatedRowFilters,
    },
  };

  const aliasedTableFunction: IAliasedRelation = {
    kind: IRelationType.AliasedRelation,
    alias: form.name,
    relation: tableFunction,
  };

  const relationBooleanExpressions: IRelationalBooleanExpression[] = report.query.row_grouping.map((group: IAliasedExpression) => {
    return {
      kind: IExpressionType.RelationalBooleanExpression,
      op: IRelationalOp.Equals,
      left: group.expr,
      right: replaceExpressionColIdsWithAlias(replaceExpressionObjectIds(group, form.name)).expr,
    };
  });

  const joinCondition: IRelationalJoinCondition | IBinaryLogicalJoinConidition = combineBooleanExpressions(relationBooleanExpressions, IBooleanOp.And) as
    | IRelationalJoinCondition
    | IBinaryLogicalJoinConidition;

  const joinedRelation: IRelation | undefined = initialInputs
    ? replaceAliasedRelation(report.query.objects, initialInputs.relation, aliasedTableFunction, joinCondition)
    : {
        kind: IRelationType.JoinExpression,
        left: report.query.objects,
        right: aliasedTableFunction,
        type: IJoinType.Inner,
        condition: combineBooleanExpressions(relationBooleanExpressions, IBooleanOp.And) as IRelationalJoinCondition | IBinaryLogicalJoinConidition,
      };

  if (joinedRelation) {
    const newExpression: IAliasedExpression = {
      kind: IExpressionType.AliasedExpression,
      alias: form.name,
      expr: {
        kind: IExpressionType.ColumnIdentifier,
        col: {
          obj_id: form.name,
          col_id: form.name,
        },
      },
    };

    const newReport: Report = {
      ...report,
      query: {
        ...report.query,
        objects: joinedRelation,
        rowFilters: updatedRowFilters,
      },
    };

    return { expression: newExpression, report: newReport };
  } else {
    return undefined;
  }
}

export function createTimeTableFunction(
  relation: IRelation,
  columnDef: IColumnDef,
  timestampToDateFunction: IFunction,
  generateTimeSeriesFunction: IFunction,
  fillMissingValuesFunction: IFunction,
  makeSubqueryFunction: IFunction
): { expression: IAliasedExpression; relation: IRelation } {
  const column: IColumnIdentifier = buildColumnIdentifierFromDef(columnDef);

  const now = Date.now();

  const start = new Date(now);
  start.setMonth(0);
  start.setDate(1);
  start.setUTCHours(0, 0, 0, 0);

  const startDate: IParameterizedExpression = {
    kind: IExpressionType.ParameterizedExpression,
    parameter_name: TIME_RANGE_START_PARAMETER_NAME,
    default_parameter_value: {
      kind: IExpressionType.LiteralExpression,
      value: start.valueOf(),
    },
  };

  const endDate: IParameterizedExpression = {
    kind: IExpressionType.ParameterizedExpression,
    parameter_name: TIME_RANGE_END_PARAMETER_NAME,
    default_parameter_value: {
      kind: IExpressionType.LiteralExpression,
      value: Date.now(),
    },
  };

  const timeSeries: ITableFunction = {
    kind: IRelationType.TableFunction,
    id: generateTimeSeriesFunction.function_id,
    relations: [],
    operands: {
      column_aliases: [
        {
          kind: IExpressionType.AliasedExpression,
          expr: {
            kind: IExpressionType.LiteralExpression,
            value: TIME_BUCKETS_COLUMN_ALIAS,
          },
          alias: TIME_BUCKETS_COLUMN_ALIAS,
        },
      ],
      start: [startDate],
      end: [endDate],
      timeunits: [
        {
          kind: IExpressionType.LiteralExpression,
          value: "milliseconds",
        },
      ],
      output_format: [
        {
          kind: IExpressionType.LiteralExpression,
          value: ITimestampToDateOptions.Weeks,
        },
      ],
    },
  };

  const aliasedTimeSeries: IAliasedRelation = {
    kind: IRelationType.AliasedRelation,
    relation: timeSeries,
    alias: TIME_SERIES_ALIAS,
  };

  const generatedColumnAlias: string = uuidv4();

  const timeFilledTable: IAliasedRelation = {
    kind: IRelationType.AliasedRelation,
    alias: TIME_FILLED_TABLE_ALIAS,
    relation: {
      kind: IRelationType.TableFunction,
      id: fillMissingValuesFunction.function_id,
      relations: [aliasedTimeSeries, replaceRelationWithSubquery(columnDef, relation, timestampToDateFunction, makeSubqueryFunction, startDate, endDate, generatedColumnAlias)],
      operands: {
        dimension_column_exprs: [
          {
            kind: IExpressionType.AliasedExpression,
            expr: {
              kind: IExpressionType.ColumnIdentifier,
              col: {
                obj_id: TIME_SERIES_ALIAS,
                col_id: TIME_BUCKETS_COLUMN_ALIAS,
              },
            },
            alias: TIME_BUCKETS_COLUMN_ALIAS,
          },
        ],
        relation_column_exprs: [
          {
            kind: IExpressionType.AliasedExpression,
            expr: {
              kind: IExpressionType.ColumnIdentifier,
              col: {
                obj_id: columnDef.obj_id,
                col_id: generatedColumnAlias,
              },
            },
            alias: uuidv4(),
          },
        ],
      },
    },
  };

  const newExpression: IAliasedExpression = {
    kind: IExpressionType.AliasedExpression,
    alias: uuidv4(),
    expr: {
      kind: IExpressionType.ColumnIdentifier,
      col: {
        obj_id: TIME_FILLED_TABLE_ALIAS,
        col_id: TIME_BUCKETS_COLUMN_ALIAS,
      },
    },
  };

  return {
    expression: newExpression,
    relation: timeFilledTable,
  };
}

export function replaceRelationWithSubquery(
  columnDef: IColumnDef,
  relation: IRelation,
  timestampToDateFunction: IFunction,
  makeSubqueryFunction: IFunction,
  startDate: IParameterizedExpression,
  endDate: IParameterizedExpression,
  generatedColumnAlias: string
): IRelation {
  const column: IColumnIdentifier = buildColumnIdentifierFromDef(columnDef);

  switch (relation.kind) {
    case IRelationType.TableIdentifier:
      if (relation.source_obj_id === columnDef.obj_id) {
        return {
          kind: IRelationType.AliasedRelation,
          alias: relation.source_obj_id,
          relation: {
            kind: IRelationType.TableFunction,
            id: makeSubqueryFunction.function_id,
            operands: {
              projections: [
                {
                  kind: IExpressionType.AliasedExpression,
                  expr: {
                    kind: IExpressionType.FunctionExpression,
                    id: timestampToDateFunction.function_id,
                    operands: [
                      column,
                      {
                        kind: IExpressionType.LiteralExpression,
                        value: ITimestampToDateOptions.Weeks,
                      },
                      {
                        kind: IExpressionType.LiteralExpression,
                        value: "milliseconds",
                      },
                    ],
                  },
                  alias: generatedColumnAlias,
                },
              ],
              row_filter: [
                {
                  kind: IExpressionType.BinaryLogicalExpression,
                  op: IBooleanOp.And,
                  left: {
                    kind: IExpressionType.RelationalBooleanExpression,
                    op: IRelationalOp.GreaterThanOrEquals,
                    left: column,
                    right: startDate,
                  },
                  right: {
                    kind: IExpressionType.RelationalBooleanExpression,
                    op: IRelationalOp.LessThanOrEquals,
                    left: column,
                    right: endDate,
                  },
                },
              ],
            },
            relations: [relation],
          },
        };
      } else {
        return relation;
      }
    case IRelationType.JoinExpression:
      if (getObjectIdsFromRelation(relation.left).includes(columnDef.obj_id)) {
        return {
          ...relation,
          left: replaceRelationWithSubquery(columnDef, relation.left, timestampToDateFunction, makeSubqueryFunction, startDate, endDate, generatedColumnAlias),
        };
      } else if (getObjectIdsFromRelation(relation.right).includes(columnDef.obj_id)) {
        return {
          ...relation,
          right: replaceRelationWithSubquery(columnDef, relation.right, timestampToDateFunction, makeSubqueryFunction, startDate, endDate, generatedColumnAlias),
        };
      } else {
        return relation;
      }
    case IRelationType.AliasedRelation:
      return {
        ...relation,
        relation: replaceRelationWithSubquery(columnDef, relation.relation, timestampToDateFunction, makeSubqueryFunction, startDate, endDate, generatedColumnAlias),
      };
    case IRelationType.TableFunction:
      return {
        ...relation,
        relations: relation.relations.flatMap((childRelation) =>
          replaceRelationWithSubquery(columnDef, childRelation, timestampToDateFunction, makeSubqueryFunction, startDate, endDate, generatedColumnAlias)
        ),
      };
    default:
      return relation;
  }
}

export function replaceTimeGranularity(
  relation: IRelation,
  makeSubqueryFunction: IFunction,
  timestampToDateFunction: IFunction,
  generateTimeSeriesFunction: IFunction,
  newGranularity: ITimestampToDateOptions
): IRelation {
  switch (relation.kind) {
    case IRelationType.TableIdentifier:
      return relation;
    case IRelationType.JoinExpression:
      return {
        ...relation,
        left: replaceTimeGranularity(relation.left, makeSubqueryFunction, timestampToDateFunction, generateTimeSeriesFunction, newGranularity),
        right: replaceTimeGranularity(relation.right, makeSubqueryFunction, timestampToDateFunction, generateTimeSeriesFunction, newGranularity),
      };
    case IRelationType.AliasedRelation:
      return {
        ...relation,
        relation: replaceTimeGranularity(relation.relation, makeSubqueryFunction, timestampToDateFunction, generateTimeSeriesFunction, newGranularity),
      };
    case IRelationType.TableFunction:
      if (
        relation.id === makeSubqueryFunction.function_id &&
        relation.operands?.projections?.[0]?.kind === IExpressionType.AliasedExpression &&
        relation.operands.projections[0].expr.kind === IExpressionType.FunctionExpression &&
        relation.operands.projections[0].expr.id === timestampToDateFunction.function_id &&
        relation.operands.projections[0].expr.operands.length === 3
      ) {
        return {
          ...relation,
          operands: {
            ...relation.operands,
            projections: [
              {
                ...relation.operands.projections[0],
                expr: {
                  ...relation.operands.projections[0].expr,
                  operands: [
                    relation.operands.projections[0].expr.operands[0],
                    {
                      kind: IExpressionType.LiteralExpression,
                      value: newGranularity,
                    },
                    relation.operands.projections[0].expr.operands[2],
                  ],
                },
              },
            ],
          },
        };
      } else if (relation.id === generateTimeSeriesFunction.function_id) {
        return {
          ...relation,
          operands: {
            ...relation.operands,
            output_format: [
              {
                kind: IExpressionType.LiteralExpression,
                value: newGranularity,
              },
            ],
          },
        };
      } else {
        return {
          ...relation,
          relations: relation.relations.flatMap((childRelation) =>
            replaceTimeGranularity(childRelation, makeSubqueryFunction, timestampToDateFunction, generateTimeSeriesFunction, newGranularity)
          ),
        };
      }

    default:
      return relation;
  }
}

//-----------------------------------------------------------------------
// Get Table Functions
//-----------------------------------------------------------------------

export function getTimeFilledTableFunction(relation: IRelation): ITimeFilledTableFunction | undefined {
  if (relation.kind === IRelationType.AliasedRelation && relation.relation.kind === IRelationType.TableFunction && instanceOfTimeFilledTableFunction(relation.relation)) {
    return relation.relation;
  } else if (relation.kind === IRelationType.TableFunction && instanceOfTimeFilledTableFunction(relation)) {
    return relation;
  }
}

//-----------------------------------------------------------------------
// Instance of Table Functions
//-----------------------------------------------------------------------

export function instanceOfTimeFilledTableFunction(tableFunction: ITableFunction): tableFunction is ITimeFilledTableFunction {
  return (
    !!tableFunction.operands &&
    "dimension_column_exprs" in tableFunction.operands &&
    "relation_column_exprs" in tableFunction.operands &&
    tableFunction.relations.length === 2 &&
    tableFunction.relations[0].kind === IRelationType.AliasedRelation &&
    tableFunction.relations[0].alias === TIME_SERIES_ALIAS
  );
}
