import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Divider, IconButton, ListItem, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import React from "react";
import { useRecordContext } from "react-admin";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { SelectableText } from "../../../common/Common.styled";
import { SearchableList } from "../../../components/SearchableList/SearchableList";
import ObjectContext from "../../../context/objectContext";
import { IAliasedExpression, IExpressionType, IJoinExpression, IRelation, IRelationalOp } from "../../../conversions/ReportConversions";
import { IColumnDef, IColumnValueType, IObjectDef } from "../../../types/object";
import { IColumnEntry, IRowFilter, Report } from "../../../types/report";
import { buildNullRelationalBooleanExpressionFromCol, getExpressionValues, getRelationalOp } from "../../../utils/expressionUtils";
import { isTableFunctionIdentifier } from "../../../utils/functionUtils";
import { buildJoinExpression, combineJoinExpressions, combineRelations, getObjectIdsFromRelation } from "../../../utils/joinUtils";
import { getObjectsFromIds } from "../../../utils/objectUtils";
import { IPopoverContentProps } from "../common/GenericReportField";
import { NestedColumnSelector } from "../common/NestedColumnSelector";
import { NestedColumnSelectorHeader } from "../common/NestedColumnSelectorHeader";
import { AliasedExpressionFilterSelector } from "./AliasedExpressionFilterSelector";
import { AutocompleteFilterInput } from "./FilterInputs/AutoCompleteFilterInput";
import { BooleanFilterInput } from "./FilterInputs/BooleanFilterInput";
import { DateFilterInput } from "./FilterInputs/DateFilterInput";
import { NumericFilterInput } from "./FilterInputs/NumericFilterInput";
import ExpressionNameLookupContext from "../../../context/expressionNameLookupContext";

export interface IFilterSelectorInitialState {
  rowFilter: IRowFilter;
  columnDef?: IColumnDef;
  expression?: IAliasedExpression;
  index: number;
}

interface IFilterSelectorProps extends IPopoverContentProps {
  initialState?: IFilterSelectorInitialState;
}

export const FilterSelector = (props: IFilterSelectorProps) => {
  const { initialState, handleClosePopover } = props;

  const methods = useForm<any>({
    defaultValues: initialState?.rowFilter ?? {},
  });
  const { setValue, handleSubmit } = methods;

  const { setValue: setReportValue, getValues } = useFormContext<Report>();

  const report: Partial<Report> = getValues();
  const objectMap: Record<string, IObjectDef> = React.useContext(ObjectContext);
  const expressionNameLookup: Record<string, string> = React.useContext(ExpressionNameLookupContext);

  const [searchString, setSearchString] = React.useState<string>("");
  const [selectedColumn, setSelectedColumn] = React.useState<IColumnDef | undefined>(initialState?.columnDef);
  const [selectedExpression, setSelectedExpression] = React.useState<IAliasedExpression | undefined>(initialState?.expression);
  const [nestedSelectedColumn, setNestedSelectedColumn] = React.useState<IColumnDef | undefined>();
  const [joinExpression, setJoinExpression] = React.useState<IJoinExpression>();

  const [showAliases, setShowAliases] = React.useState<boolean>(false);
  const [showColumns, setShowColumns] = React.useState<boolean>(false);

  const initialValues: (string | number | boolean)[] | undefined = React.useMemo(() => {
    return initialState ? getExpressionValues(initialState.rowFilter) : undefined;
  }, [initialState]);

  const objectDefs: IObjectDef[] = React.useMemo(() => {
    return report?.query?.objects ? getObjectsFromIds(getObjectIdsFromRelation(report.query.objects), objectMap) : [];
  }, [report, objectMap]);

  const relation: IRelation | undefined = React.useMemo(() => {
    return report.query?.objects;
  }, [report]);

  const nonAggregateAliasedExpressions: IAliasedExpression[] = React.useMemo(() => {
    return (report.query?.row_grouping ?? [])
      .concat(report.query?.projections ?? [])
      .filter(
        (projection: IAliasedExpression) =>
          projection.expr.kind !== IExpressionType.AggregateExpression &&
          (projection.expr.kind !== IExpressionType.ColumnIdentifier || isTableFunctionIdentifier(projection.expr.col.obj_id, report.query?.objects))
      );
  }, [report]);

  React.useEffect(() => {
    if (nonAggregateAliasedExpressions.length === 0) {
      setShowColumns(true);
    }
  }, [nonAggregateAliasedExpressions]);

  const handleSearch = React.useCallback((searchString: string) => {
    setSearchString(searchString);
  }, []);

  const handleSetSelectedColumn = React.useCallback(
    (column: IColumnDef, useExtLink: boolean) => {
      if (column.ext_link && useExtLink) {
        setNestedSelectedColumn(column);
        const columnObjectDef: IObjectDef = nestedSelectedColumn?.ext_link?.obj_id ? objectMap[nestedSelectedColumn.ext_link.obj_id] : objectDefs[0];
        const currentJoin: IJoinExpression = buildJoinExpression(column, columnObjectDef, objectMap[column.ext_link.obj_id]);

        if (joinExpression) {
          setJoinExpression(combineJoinExpressions(joinExpression, currentJoin, objectMap));
        } else {
          setJoinExpression(currentJoin);
        }
      } else {
        setSelectedColumn(column);
      }
    },
    [setValue, objectDefs, joinExpression, objectMap, nestedSelectedColumn]
  );

  const handleRemoveSelectedColumn = React.useCallback(() => {
    setSelectedColumn(undefined);
  }, []);

  const handleFilterApply = React.useCallback(
    (rowFilter: IRowFilter) => {
      const updatedRelation: IRelation = joinExpression ? combineRelations(relation!, joinExpression, objectMap) : relation!;
      const rowFiltersToUpdate: IRowFilter[] = [...(report.query?.rowFilters ?? [])];

      if (initialState) {
        rowFiltersToUpdate.splice(initialState.index, 1, rowFilter);
      } else {
        rowFiltersToUpdate.push(rowFilter);
      }

      setReportValue(
        "query",
        {
          ...report.query!,
          rowFilters: rowFiltersToUpdate,
          objects: updatedRelation,
        },
        { shouldValidate: true }
      );
      handleClosePopover();
    },
    [report, joinExpression, relation, objectMap, initialState, setReportValue, handleClosePopover]
  );

  return (
    <Box sx={{ minWidth: "25.6rem" }}>
      {!selectedColumn && !selectedExpression ? (
        <>
          {showColumns && (
            <>
              <SearchableList onSearchChange={handleSearch} header={<NestedColumnSelectorHeader nestedSelectedColumn={nestedSelectedColumn} />}>
                <NestedColumnSelector column={nestedSelectedColumn} searchString={searchString} handleSetSelectedColumn={handleSetSelectedColumn} />
              </SearchableList>
            </>
          )}
          {showAliases && (
            <>
              {nonAggregateAliasedExpressions.map((alias: IAliasedExpression) => {
                return (
                  <ListItem key={`alias-item-${alias.alias!}`} disableGutters disablePadding>
                    <SelectableText as="div" onClick={() => setSelectedExpression(alias)}>
                      {expressionNameLookup[alias.alias!] ?? alias.alias}
                    </SelectableText>
                  </ListItem>
                );
              })}
            </>
          )}
          {!showColumns && !showAliases && (
            <>
              <ListItem disableGutters disablePadding>
                <SelectableText as="div" onClick={() => setShowColumns(true)}>
                  Object Columns
                </SelectableText>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <SelectableText as="div" onClick={() => setShowAliases(true)}>
                  Custom Column
                </SelectableText>
              </ListItem>
            </>
          )}
        </>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleFilterApply)}>
            {selectedColumn && (
              <FilterValueSelector
                objectId={nestedSelectedColumn?.ext_link?.obj_id ?? objectDefs[0]?.resource_id ?? ""}
                column={selectedColumn}
                initialValues={initialValues}
                initialState={initialState}
                handleRemoveSelectedColumn={handleRemoveSelectedColumn}
                handleClosePopover={handleClosePopover}
              />
            )}
            {selectedExpression && (
              <AliasedExpressionFilterSelector
                expression={selectedExpression}
                initialValues={initialValues}
                initialState={initialState}
                handleRemoveSelectedColumn={handleRemoveSelectedColumn}
                handleClosePopover={handleClosePopover}
              />
            )}
          </form>
        </FormProvider>
      )}
    </Box>
  );
};

function getFilterValueSelectorOptions(valueType: IColumnValueType): FilterValueSelectorOptions[] {
  switch (valueType) {
    case IColumnValueType.Boolean:
      return [FilterValueSelectorOptions.IS, FilterValueSelectorOptions.IS_NULL, FilterValueSelectorOptions.IS_NOT_NULL];
    case IColumnValueType.Date:
      return [
        FilterValueSelectorOptions.HAPPENS_BETWEEN,
        FilterValueSelectorOptions.HAPPENS_BEFORE,
        FilterValueSelectorOptions.HAPPENS_AFTER,
        FilterValueSelectorOptions.IS_NULL,
        FilterValueSelectorOptions.IS_NOT_NULL,
      ];
    case IColumnValueType.Number:
      return [
        FilterValueSelectorOptions.BETWEEN,
        FilterValueSelectorOptions.GREATER_THAN,
        FilterValueSelectorOptions.LESS_THAN,
        FilterValueSelectorOptions.IS_NULL,
        FilterValueSelectorOptions.IS_NOT_NULL,
      ];
    case IColumnValueType.String:
    default:
      return [
        FilterValueSelectorOptions.IS,
        FilterValueSelectorOptions.IS_NOT,
        FilterValueSelectorOptions.EQUALS,
        FilterValueSelectorOptions.NOT_EQUALS,
        FilterValueSelectorOptions.IS_NULL,
        FilterValueSelectorOptions.IS_NOT_NULL,
      ];
  }
}

// TODO - mapping for localization
export enum FilterValueSelectorOptions {
  IS = "Is",
  IS_NOT = "Is not",
  EQUALS = "Equals",
  NOT_EQUALS = "Not equals",
  IS_NULL = "Is null",
  IS_NOT_NULL = "Is not null",
  GREATER_THAN = "Greater than",
  LESS_THAN = "Less than",
  BETWEEN = "Between",
  HAPPENS_BEFORE = "Happens before",
  HAPPENS_AFTER = "Happens after",
  HAPPENS_BETWEEN = "Happens between",
}

interface IFilterValueSelectorProps extends IPopoverContentProps {
  objectId: string;
  column: IColumnDef;
  initialValues: (string | number | boolean)[] | undefined;
  initialState?: IFilterSelectorInitialState;

  handleRemoveSelectedColumn: () => void;
  handleClosePopover: () => void;
}

export const FilterValueSelector = (props: IFilterValueSelectorProps) => {
  const { objectId, column, initialValues, initialState, handleRemoveSelectedColumn, handleClosePopover } = props;

  const { reset, getValues } = useFormContext();

  const expressionKind: IExpressionType | undefined = getValues("kind");

  const columnValueType: IColumnValueType = React.useMemo(() => {
    return column.value_def.col_value_type;
  }, [column]);

  const filterValueTypeOptions: FilterValueSelectorOptions[] = React.useMemo(() => {
    return getFilterValueSelectorOptions(columnValueType);
  }, [columnValueType]);

  const [selectedFilterValueTypeOption, setSelectedFilterValueTypeOption] = React.useState<FilterValueSelectorOptions>(
    initialState ? getFilterValueTypeFromColumn(initialState.rowFilter, column) : filterValueTypeOptions[0] ?? ""
  );

  const isInputDisabled: boolean = React.useMemo(() => {
    return selectedFilterValueTypeOption === FilterValueSelectorOptions.IS_NULL || selectedFilterValueTypeOption === FilterValueSelectorOptions.IS_NOT_NULL;
  }, [selectedFilterValueTypeOption]);

  const handleFilterValueTypeOptionChange = React.useCallback((event: SelectChangeEvent) => {
    setSelectedFilterValueTypeOption(event.target.value as FilterValueSelectorOptions);

    if (event.target.value === FilterValueSelectorOptions.IS_NULL) {
      reset(buildNullRelationalBooleanExpressionFromCol({ obj_id: objectId, col_id: column.col_id }, false));
    } else if (event.target.value === FilterValueSelectorOptions.IS_NOT_NULL) {
      reset(buildNullRelationalBooleanExpressionFromCol({ obj_id: objectId, col_id: column.col_id }, true));
    }
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", minHeight: "5.2rem" }}>
        <IconButton onClick={handleRemoveSelectedColumn}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="body2">{column.col_name}</Typography>
        <IconButton onClick={handleClosePopover}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Select
        sx={{ display: "flex", flex: "1 1 auto", ".MuiOutlinedInput-notchedOutline": { border: "none" } }}
        defaultValue={selectedFilterValueTypeOption}
        onChange={handleFilterValueTypeOptionChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        {filterValueTypeOptions.map((filterValueTypeOption: FilterValueSelectorOptions) => {
          return (
            <MenuItem key={`filter-value-type-option-${filterValueTypeOption}`} value={filterValueTypeOption}>
              <Typography variant="body2">{filterValueTypeOption}...</Typography>
            </MenuItem>
          );
        })}
      </Select>
      <Divider />
      {!isInputDisabled && (
        <>
          {(columnValueType === IColumnValueType.String || columnValueType === IColumnValueType.Id) && (
            <AutocompleteFilterInput column={column} filterValueSelectorOption={selectedFilterValueTypeOption} initialValues={initialValues} />
          )}
          {columnValueType === IColumnValueType.Number && (
            <NumericFilterInput column={column} filterValueSelectorOption={selectedFilterValueTypeOption} initialValues={initialValues} />
          )}
          {columnValueType === IColumnValueType.Boolean && <BooleanFilterInput column={column} initialValues={initialValues} />}
          {columnValueType === IColumnValueType.Date && <DateFilterInput column={column} filterValueSelectorOption={selectedFilterValueTypeOption} initialValues={initialValues} />}
        </>
      )}
      <Divider />
      <Button sx={{ margin: "20px" }} variant="contained" type="submit" disabled={!expressionKind}>
        Apply
      </Button>
    </Box>
  );
};

export function getFilterValueTypeFromColumn(rowFilter: IRowFilter, column: IColumnDef): FilterValueSelectorOptions {
  const valueType: IColumnValueType = column.value_def.col_value_type;
  return getFilterValueTypeFromValueType(rowFilter, valueType);
}

export function getFilterValueTypeFromFilterInitialValues(rowFilter: IRowFilter, initialValues: (string | number | boolean)[]): FilterValueSelectorOptions {
  const valueType: IColumnValueType = getValueTypeFromInitialValues(initialValues);
  return getFilterValueTypeFromValueType(rowFilter, valueType);
}

export function getFilterValueTypeFromValueType(rowFilter: IRowFilter, valueType: IColumnValueType): FilterValueSelectorOptions {
  const relationalOp: IRelationalOp | undefined = getRelationalOp(rowFilter);

  if (rowFilter.kind === IExpressionType.RelationalBooleanExpression && rowFilter.right.kind === IExpressionType.NullExpression) {
    return relationalOp === IRelationalOp.NotEquals || relationalOp === IRelationalOp.IsNot ? FilterValueSelectorOptions.IS_NOT_NULL : FilterValueSelectorOptions.IS_NULL;
  } else if (valueType === IColumnValueType.Date) {
    if (relationalOp === IRelationalOp.LessThanOrEquals) {
      return FilterValueSelectorOptions.HAPPENS_BEFORE;
    } else if (relationalOp === IRelationalOp.GreaterThanOrEquals) {
      return FilterValueSelectorOptions.HAPPENS_AFTER;
    } else {
      return FilterValueSelectorOptions.HAPPENS_BETWEEN;
    }
  } else if (valueType === IColumnValueType.Number) {
    if (relationalOp === IRelationalOp.LessThan) {
      return FilterValueSelectorOptions.LESS_THAN;
    } else if (relationalOp === IRelationalOp.GreaterThan) {
      return FilterValueSelectorOptions.GREATER_THAN;
    } else {
      return FilterValueSelectorOptions.BETWEEN;
    }
  } else if (valueType === IColumnValueType.Boolean) {
    return FilterValueSelectorOptions.IS;
  } else {
    return relationalOp === IRelationalOp.NotEquals || relationalOp === IRelationalOp.IsNot ? FilterValueSelectorOptions.IS_NOT : FilterValueSelectorOptions.IS;
  }
}

export function getValueTypeFromInitialValues(initialValues: (string | number | boolean)[]): IColumnValueType {
  if (initialValues.length === 0 || typeof initialValues[0] === "string") {
    return IColumnValueType.String;
  } else if (typeof initialValues[0] === "boolean") {
    return IColumnValueType.Boolean;
  } else if (typeof initialValues[0] === "number") {
    const numberVal: number = initialValues[0];
    if (numberVal > 631152000000) {
      return IColumnValueType.Date;
    } else {
      return IColumnValueType.Number;
    }
  } else {
    return IColumnValueType.String;
  }
}
