import { Identifier } from "react-admin";
import { IBaseDataImportDescriptor, IDataImportDescriptor } from "../types/dataImport";
import { Conversions } from "./Conversions";

//-------------------------------------------------------------------------------
// Response Interfaces
//-------------------------------------------------------------------------------

export interface IGetDataImportsResponse {
  import_descriptors: IBaseDataImportDescriptor[];
}

//-------------------------------------------------------------------------------
// Conversions
//-------------------------------------------------------------------------------

export class DataImportConversions implements Conversions {
  public fromResponseMany(response: IGetDataImportsResponse): IDataImportDescriptor[] {
    return (response.import_descriptors ?? []).map((dataImport: IBaseDataImportDescriptor) => {
      return this.fromResponse(dataImport);
    });
  }

  public fromResponse(response: IBaseDataImportDescriptor): IDataImportDescriptor {
    return {
      ...response,
      id: response.resource_id,
    };
  }

  public fromCreateResponse(response: any): IDataImportDescriptor {
    throw new Error("Conversion not implemented");
  }

  public toRequest(resource: any): any {
    throw new Error("Conversion not implemented");
  }

  public toUpdateRequest(id: Identifier, resource: any): any {
    throw new Error("Conversion not implemented");
  }

  public toCreateRequest(resource: any): any {
    throw new Error("Conversion not implemented");
  }

  public toDeleteRequest(id: Identifier): any {
    throw new Error("Conversion not implemented");
  }
}
