import englishMessages from "ra-language-english";

// TODO: eventually clean this up to follow a consistent pattern
export const en = {
  ...englishMessages,
  login: {
    title: "Sign in to your account",
    emailLabel: "Type your email address",
    passwordLabel: "Type your password",
    submitButton: "Sign in",
    invalidInput: "Invalid email or password",
  },
  generic: {
    at: "at",
    and: "and",
    or: "or",
    as: "as",
    cancel: "Cancel",
    apply: "Apply",
    archive: "Archive",
    delete: "Delete",
    settings: "Settings",
    back: "Back",
    save: "Save",
    edit: "Edit",
    name: "Name",
    description: "Description",
    searchPlaceholder: "Search...",
    fields: {
      lastEdited: "Last edited",
      createdOn: "Created on",
      createdBy: "Created by",
    },
    findem: "Findem",
    unknown: "Unknown",
    selected: "Selected",
    category: "Category",
    all: "All",
    template: "Template",
    createdFromTemplate: "Created From Template",
    copy: "Copy",
    plural: "%{word}s",
    time: {
      days: "Days",
      weeks: "Weeks",
      months: "Months",
      quarters: "Quarters",
      years: "Years",
      granularity: "Time Granularity",
    },
    logout: "Logout",
    displayName: "Display Name",
    value: "Value",
    placeholder: "Enter %{value}...",
    ascending: "Ascending",
    descending: "Descending",
    title: "Title",
    description: "Description",
    icon: "Icon",
    column: "Column",
    candidateAttribute: "Candidate Attribute",
    selectAll: "Select All",
    feedback: "Feedback",
    fileSizeLimit: "File size should be less than %{limit}MB",
    fileTypeNotSupported: "%{unsupported} file type not supported. Please upload file of type %{types}",
    addColorPlaceholder: "Enter a hex code...",
    addColor: "Add Color",
    successfulImageUpload: "Image uploaded successfully",
  },
  pdf: {
    tooltip: "Export to PDF",
    pending: "Exporting to PDF...",
    success: "Sucessfully exported to PDF",
    error: "An error occurred while attempting to export. Please try again.",
  },
  joinEditor: {
    tooltip: "Edit Joins",
    title: "Edit Joins",
    empty: "Unable to edit joins as no object is selected",
    left: "Left",
    right: "Right",
    type: "Join Type",
    tableFunction: "Table Function - %{platFunction}",
    tableFunctionRelation: "Table Function Subrelation #%{index}",
    summary: "Summary: %{summary}",
    overall: "Overall",
    testRowCount: "Test Row Count",
    testRowCountResult: "Count: %{count}",
    testWithFilters: "With Filters",
    testWithoutFilters: "Without Filters",
  },
  resources: {
    report: {
      name: "Report",
      create: "New Report",
    },
    dashboard: {
      name: "Dashboard",
      create: "New Dashboard",
    },
    object: {
      name: "Object",
      create: "New Object",
    },
    role: {
      name: "Role",
      create: "New Role",
    },
    studies: {
      name: "Studies",
      create: "Add Study",
    },
    studyFilters: {
      name: "Talent Group",
      create: "Add Talent Group",
    },
    user: {
      name: "User",
    },
    function: {
      name: "Function",
    },
    application: {
      name: "Application",
    },
    applicationInstance: {
      name: "Application Instance",
    },
    error: {
      notification: "%{failedOperation} failed due to %{failedReason}",
      detailsButton: "See Details",
    },
    actions: {
      deleteResourceDialogTitle: "Are you sure you want to delete %{resource}?",
      deleteResourceDialogButton: "Yes, delete this %{resource}",
    },
    message: {
      afterSave: "%{resource} successfully saved",
      afterDelete: "%{resource} successfully deleted",
      afterDeleteError: "Failed to delete %{resource}",
    },
  },
  apps: {
    openButton: "Open App",
  },
  exitPrompt: {
    title: "Discard Changes?",
    body: "There are unsaved changes. Are you sure want to leave this page?",
    confirmButtonText: "Leave",
  },
  resource: {
    scope: {
      self: "Owned by me",
      shared: "Shared with me",
      all: "All",
      default: "Default",
    },
    owner: {
      any: "Any user",
    },
    account: {
      any: "Any account",
    },
    creation: {
      startFromTemplate: "Start from template",
      startFromScratch: "Start from scratch",
    },
  },
  report: {
    title: "Reports",
    subtitle: "Analyze your hiring and workforce data with Findem Reports",
    titlePlaceholder: "Enter a report title...",
    subtitlePlaceholder: "Enter a report description...",
    titleValidation: "Please enter a report title.",
    clone: "Clone",
    search: "Search Reports...",
    dummyReportTitle: "New Report Title",
    dummyReportDescription: "New report description",
    createReport: "Create Report",
    createFromScratch: "Start From Scratch",
    cloneReport: "Clone Report",
    selectTemplate: "Select a Report Template",
    link: "Open Link",
    joinTooltip: "Join to %{objectName}",
    icons: {
      recommended: "Recommended",
    },
    aggregation: {
      function: "Aggregation Function",
      distinct: "Distinct",
      distinctSubheader: "Calculate aggregations from the distinct values in a column",
    },
    creation: {
      categories: {
        existingReports: "Existing Reports",
        myReports: "My Reports",
        shared: "Shared with me",
        all: "All Reports",
        template: "Templates",
      },
    },
    fields: {
      object: "Object",
      metric: "Metric",
      column: "Columns",
      summary: "Row-Level Formula Columns",
      rowGroups: "Group Rows",
      columnGroups: "Group Columns",
      filter: "Filters",
      keywordFilter: "Keyword Filters",
      macroFilter: "Macro Filters",
      booleanLogic: "Boolean Logic",
      candidateFilter: "Attributes",
      time: "Time Context",
      boolean: {
        true: "True",
        false: "False",
      },
    },
    action: {
      addToDashboard: "Add to Dashboard",
      run: "Run",
      runAs: "Run as %{user}",
      addBucketColumn: "Add Bucket Column",
      addDerivedColumn: "Add Derived Column",
      addInlineExpression: "Add Inline Expression",
      addSummaryFormula: "Add Summary Formula",
      addRowLevelFormula: "Add Row-Level Formula",
      addBinaryExpression: "Add Binary Expression",
      removeAllColumns: "Remove All Columns",
      editColumn: "Edit Column",
      download: "Download CSV",
      downloading: "Downloading CSV...",
      downloadError: "An error occurred while attempting to download this report. Please try again.",
      downloadSuccess: "Report successfully downloaded",
      settings: "Report Settings",
      customize: "Customize",
    },
    custom: "Custom",
    message: {
      creatingFromTemplate: "Creating report from template.",
      createError: "An error occurred while attempting to create this report. Please try again.",
      afterClone: "Report successfully cloned",
      afterSave: "Report successfully saved",
      afterSaveError: "There was an error saving this Report. Please try again.",
      afterAddToDashboard: "Report successfully added to dashboard",
      runError: "Error running report",
      invalidVisualType: "%{before_visual_type} visual is incompatible with current Report parameters. Switching to %{after_visual_type}.",
      noViewAccess: "You do not have the permissions required to view this report.",
      noEditAccess: "You do not have the permissions required to edit this report.",
      dashboardShareWarning:
        "The dashboard is shared with your organization while this report is not. Please enabled org sharing on this report & save before adding it to the dashboard.",
      afterRemoveNumericAxis: "The column used as the chart's numeric axis was removed. Setting to Record Count.",
      noResultsHeader: "No report results",
      noResultsSubheader: "Modify the filters to see results",
      internalErrorHeader: "An error occurred",
      internalErrorSubheader: "", // TODO
    },
    settings: {
      header: "Report Settings",
      subheader: "Configure your report",
      tabs: {
        preferences: {
          header: "Preferences",
          layout: "Layout",
          series: "Series",
          seriesSubheader: "Assign a column to the numeric axis",
          compare: "Compare",
          details: "Details",
          onlyAggregates: "Aggregate Values",
          onlyAggregatesSubheader: "Only show aggregate values",
          hideSubtotals: "Hide Subtotals",
          hideSubtotalsDescription: "Hides subtotal rows in the report table",
          renderAsPercent: "Render as Percentage",
          renderAsPercentSubheader: "Calculate numeric values as a percentage",
          breakdownByColumns: "Breakdown by Columns",
          breakdownByColumnsSubheader: "Group content by columns",
          advanced: "Advanced",
          bypassCache: "Bypass Cache",
          bypassCacheSubheader: "Force a rerun of report execution",
          units: "Units",
          unitsSubheader: "Adds a unit suffix to the visual's numeric axis",
        },
        display: {
          header: "Display",
          thresholds: "Thresholds",
          thresholdEditor: "Threshold Editor",
          allColumns: "All Columns",
          rule: "rule",
          thresholdRule: "Threshold Rule",
          rule: "Rule",
          thresholdRuleDesc: "If %{type}",
          thresholdDisplay: "Value is",
          thresholdRowGroupMessage:
            "The number of row groups applied prevents thresholds from being using on your selected visual layout. Please reduce the number of row groups to one to view your thresholds.",
          thresholdLayoutMessage: "The select visual layout prevents your thresholds from being applied. Please modify the layout to view your thresholds.",
          referenceLines: "Reference Lines",
          referenceLineEditor: "Reference Line Editor",
          referenceLineRule: "Reference Line Definition",
          referenceLineDisplay: "value =",
          referenceLineColor: "Line Color",
          referenceLineLayoutMessage: "The select visual layout prevents your reference lines from being applied. Please modify the layout to view your reference lines.",
          displayOptions: "Display Options",
          sorting: "Table Sorting",
          targetColumn: "Target Column",
          targetColumnPlaceholder: "Select a column...",
          sortBy: "Sort by",
          sortByPlaceholder: "Select sorting...",
          sortOn: "Sort on",
          sortOnPlaceholder: "Select sort on...",
          chartSorting: "Chart Sorting",
          groups: "Groups",
          maxGroups: "Max number of groups to display",
          maxGroupsPlaceholder: "Enter max number of groups to display in the chart...",
          chartSorting: "Chart Sorting",
          includeOther: "Include Others",
          includeOtherSubheader: "Bucket all truncated groups into 'Others'",
          chartSpecificSettings: "%{chart} Settings",
          hideSummary: "Hide Summary Value",
          hideSummarySubheader: "Hide the overall aggregate value displayed on the chart",
          hundredPercentStacked: "100% Stacked",
          hundredPercentStackedSubheader: "Show the relative percentage of multiple data series in stacked bars",
          seriesColorMapping: "Series Color Mapping",
          seriesColorMappingEditor: "Series Color Mapping Editor",
          seriesName: "Series Name",
          seriesColor: "Series Color",
        },
        columns: {
          header: "Columns",
          subheader: "Review and customize columns",
          breadcrumb: "Column: %{alias}",
          link: "Column Link",
          linkSubheader: "Define the static and dynamic components of your link. Dynamic components reference existing column names within the report.",
          linkSubheaderExample: "Example: https://app-next.findem.ai/searches?joid={column_name}",
          actions: "Actions",
          actionsSubheader: "Define actions taken when events on a report are triggered by a user.",
          filterAction: "Filter Report",
          filterActionSubheader: "Filter the report when an aggregate value cell is clicked.",
          settings: "Settings",
          hide: "Hide",
          hideSubheader: "Hides this column from being displayed in the report table.",
          hideOnAction: "Hide on Action",
          hideOnActionSubheader: "Hides this column after a report action is performed.",
          formatting: "Formatting",
        },
      },
      reportSettings: "Report Settings",
      shareWithOrg: "Share Report with your organization",
      chartSettings: "Chart Settings",
      tableSettings: "Table Settings",
      linkSettings: "Link Settings",
      linkUrl: "Link URL",
      linkText: "Link Text",
      onlyAggregates: "Only Display Aggregate Values",
      numericAxis: "Numeric Axis",
      comparatorProjections: "Comparisons",
      groupByAxis: "Group by Axis",
      breakdownByColumns: "Breakdown by Columns",
      shareSettings: "Sharing Settings",
      renderAsPercentage: "Render as Percentage",
      bypassCache: "Bypass Cache",
      distinctRows: "Distinct Rows",
    },
  },
  dashboard: {
    title: "Dashboards",
    subtitle: "Visually organize your reports so you can make informed decisions about your hiring and workforce planning",
    addReport: {
      add: "Add Report",
      addExisting: "Add Existing Report",
      createNew: "Create New Report",
      lastEdited: "Last Edited",
      name: "Name",
      filterByName: "Filter Reports by name...",
    },
    create: {
      titlePlaceholder: "Enter dashboard title...",
      descriptionPlaceholder: "Enter dashboard description...",
    },
    clone: {
      title: 'Clone "%{name}"',
      name: "Name",
      description: "Description",
      cloningMessage: "Cloning Dashboard",
      afterError: "An error occured while attempting to clone this dashboard. Please contact Findem Support.",
      afterSuccess: "Dashboard successfully cloned.",
    },
    openReport: "Open Report",
    search: "Search Dashboards...",
    viewInsights: "Ask AI",
    message: {
      afterSave: "Dashboard successfully saved",
      afterSaveError: "There was an error saving this Dashboard. Please try again.",
      noViewAccess: "You do not have the permissions required to view this dashboard.",
      noEditAccess: "You do not have the permissions required to edit this dashboard.",
      runError: "An error occured while attempting to run this dashboard. Please contact Findem Support.",
    },
    settings: {
      shareSettings: "Sharing Settings",
      shareWithOrg: "Share Dashboard with your organization",
      shareWarning: "Please enabled org sharing on the following reports before sharing the dashboard:",
      shareAddReportsWarning: "This dashboard is shared with your org. Please enable org sharing on the following reports before adding them to the dashboard:",
    },
  },
  object: {
    title: "Objects",
    schemaDownload: "object_schema",
    actions: {
      addColumn: "Add Column",
      deleteColumn: "Delete Column(s)",
      deleteObjectDialogTitle: "Are you sure you want to delete %{object}?",
      deleteObjectDialogButton: "Yes, delete this object",
    },
    create: {
      titlePlaceholder: "Enter object title...",
      descriptionPlaceholder: "Enter object description...",
    },
    display: {
      id: "Object ID",
      version: "Version",
      lastUpdated: "Last update",
      baseCollection: "Base Collection",
    },
    message: {
      afterSave: "Object successfully saved",
      afterDelete: "Object successfully deleted",
      afterDeleteError: "Failed to delete object",
    },
    qualifier: {
      addRule: "Add Qualifier Rule",
      none: "None",
      recent: "Recent",
      current: "Current",
      past: "Past",
      custom: "Custom",
    },
    extLink: {
      display: "Ext Link",
      object: "Object",
      column: "Column",
    },
    bucketId: {
      addSearchString: "Add the current search input as the bucket ID",
    },
    columnFormula: {
      name: "Bucket Formula",
      add: "Add Bucket Formula",
      edit: "Edit Bucket Formula",
      view: "View Bucket Formula",
    },
  },
  formula: {
    columnName: "Column Name",
    description: "Description",
    outputType: "Formula Output Type",
    decimalPoints: "Decimal Points",
    formula: "Formula",
    formulaPlaceholder: "Enter your formula here...",
    fields: "Fields",
    searchFields: "Search Fields...",
    searchFunctions: "Search Functions...",
    functions: "Functions",
    projections: "Projections",
    text: "Text",
    parameterization: "Parameterization",
    editAggregations: "Edit Aggregations",
    editDerivedColumn: "Edit Derived Column",
    editTimeGranularity: "Change Time Granularity",
    insert: "Insert",
    copiedToClipboard: "Copied to Clipboard",
    derivedColumn: {
      createTitle: "Create Derived Column",
      editTitle: "Edit Derived Column",
      columnName: "Column Name",
      basic: "Basic",
      advanced: "Advanced",
      columnUsage: "Column Usage",
      function: "Function",
      functionParams: "Function Parameters",
      buildError: "Unable to build derived column",
      caseExpression: "SQL Case Expression",
      caseExpressionTooltip:
        'To use a SQL case expression with object IDs as table identifiers and column IDs as column identifiers, use the format "object ID"."column ID".\n\nFor example, if you have an object called "Employees" with ID "123" and columns "Name" and "Department ID" with IDs "name" and "dept_id", respectively, you can reference the "Name" column using the expression "123"."name" in the case statement.',
    },
    summaryFormula: {
      title: "Edit Summary-Level Formula Column",
      number: "Number",
      percent: "Percent",
      currency: "Currency",
    },
    bucketColumn: {
      title: "Edit Bucket Column",
    },
    rowFormula: {
      title: "Edit Row-Level Formula Column",
      formulaUsageType: "Formula Usage",
      rowGroup: "Row Group",
      columnGroup: "Column Group",
      column: "Column",
    },
    inlineExpression: {
      title: "Add Inline Expression",
    },
    binaryExpression: {
      title: "Add Binary Expression",
      projections: "Projections",
      columns: "Columns",
      numeric: "Numeric",
      parameter: "Parameter",
    },
    higherOrderProjection: {
      title: "Add Higher Order Expression",
      expressionName: "Expression Name",
      expressionKind: "Expression Kind",
      types: {
        kOrder: "Kth Order",
      },
      baseExpression: "Base Expression",
      existingExpressions: "Existing Expressions",
      rank: "Rank",
    },
  },
  accessInfo: {
    buttonText: "Share",
    dialogTitle: 'Share "%{resourceName}"',
    accessRestriction: "Restrict read access of this resource to internal admins",
    user: {
      subtitle: "People with access",
      addPlaceholder: "Add people",
      permissions: {
        view: "Viewer",
        edit: "Editor",
      },
      remove: "Remove access",
    },
    general: {
      subtitle: "General access",
      permissions: {
        user: {
          title: "Restricted",
          subtitle: "Only people with access can open with the link",
        },
        app: {
          title: "Application",
          subtitle: "Anyone with access to this app can open with the link",
        },
        org: {
          title: "Your Organization",
          subtitle: "Anyone in your organization can open the link",
        },
        global: {
          title: "Global",
          subtitle: "Anyone with access to the platform can open the link",
        },
      },
    },
    copyLink: "Copy link",
    copyLinkNotification: "Link copied",
    noEditAccess: "You do not have the permissions required to edit",
    afterApplyMessage: "Sharing settings updated",
    dependenciesErrorMessageDash:
      "The general access scope %{accessScope} is not compatible with the access scope of the reports it contains. Please update the access scope of the reports to %{accessScope} to apply the changes.",
    generalErrorMessage: "An error occurred while attempting to update sharing settings",
  },
  settings: {
    sectionTitles: {
      account: "Account Management",
      organization: "Organization Management",
      platform: "Platform",
      integrations: "Integrations",
    },
    pageTitles: {
      users: {
        header: "Users",
        subheader: "Manage your team and assign roles",
      },
      roles: {
        header: "Roles",
        subheader: "Create roles and manage role permissions",
      },
      permissions: {
        header: "Permissions",
        subheader: "Create permissions, manage permissions sections and permissions ACLs",
      },
      companySettings: {
        header: "Company Settings",
        subheader: "",
      },
      companyPersonalization: {
        header: "Personalization",
        subheader: "",
      },
      identity: {
        header: "Identity",
        subheader: "",
      },
      security: {
        header: "Security",
        subheader: "Manage your company security settings",
      },
      companySecurity: {
        header: "Company Security",
        subheader: "Manage your company security settings",
      },
      applications: {
        header: "Applications",
        subheader: "Supercharge your hiring process with Findem applications",
      },
      objects: {
        header: "Objects",
        subheader: "",
      },
      functions: {
        header: "Functions",
        subheader: "",
      },
      dataIntegrations: {
        header: "Data Integrations",
        subheader: "",
      },
      emailIntegrations: {
        header: "Email Integrations",
        subheader: "",
      },
      accounts: {
        header: "Accounts",
        subheader: "View and create tenant accounts",
      },
      csvUploads: {
        header: "File Uploads",
        subheader: "Upload csv files containing data for analysis",
      },
      aboutMe: {
        header: "About Me",
        subheader: "",
      },
      userSecurity: {
        header: "Security",
        subheader: "",
      },
    },
    header: "Manage %{pageName}",
    applications: {
      configure: "Configure App",
      contact: "Contact Sales",
    },
    objects: {
      creation: {
        newObject: "New Object",
      },
    },
    companyPersonalization: {
      logo: "Company Logo",
      logoDescription: "Your company logo will be used on all presentation exports.",
      logoInfo: "Upload a PNG. For best results, we recommend a 1:1 or 3:1 aspect ratio. Files cannot exceed a size of 5MB.",
      theme: "Analytics Custom Theme",
      enableTheme: "Enable custom theme",
      enabledThemeDescription: "Enabling the custom theme allows presentation style editing.",
      primaryFont: "Primary Font",
      primaryFontDescription: "Your primary font will be used for all headings",
      primaryFontPlaceholder: "Enter primary font...",
      secondaryFont: "Secondary Font",
      secondaryFontDescription: "Your secondary font will be used for body, label, and value text",
      secondaryFontPlaceholder: "Enter secondary font...",
      textColor: "Text Color",
      textColorDescription: "Your text color will be displayed against your primary and secondary colors. Ensure that it will be readable when placed upon those colors.",
      primaryColor: "Primary Color",
      primaryColorDescription: "Your primary color will be used to generate your color palette.",
      secondaryColor: "Secondary Color",
      secondaryColorDescription: "Your secondary color will be used to generate your color palette.",
      additionalColors: "Additional Colors (Optional)",
      additionalColorsDescription: "Up to %{limit} additional colors can be incorporated into your color palette.",
      addColor: "Add Color",
      colorPalette: "Color Palette",
      yourPalette: "Your color palette:",
      defaultFont: "Default Font",
      updating: "Updating company personalization settings.",
      updateError: "An error occurred while updating your company personalization settings. Please try again.",
      updateSuccess: "Company personalization settings successfully updated.",
    },
    users: {
      table: {
        toolbar: {
          selected: " Selected",
          assignSeat: "Assign Seat",
          assignRole: "Assign User Role",
        },
        headers: {
          name: "Users",
          admin: "Admin",
          sourcingSeat: "Sourcing Seat",
          role: "Role",
        },
        cells: {
          moreRoles: "+%{numRoles} more",
        },
        actions: {
          searchPlaceholder: "Search users",
          anyRoles: "Any Roles",
        },
      },
      actions: {
        edit: "Edit User",
        unlink: "Unlink",
        resetPassword: "Reset Password",
        delete: "Delete",
        sourcingSeats: "%{seatsUsed}/%{totalSeats} Sourcing Seats",
      },
      creation: {
        newUser: "New User",
        addUser: "Add User",
        modalTitle: "Add New User",
        userName: "User Name",
        userNamePlaceholder: "Enter user name...",
        email: "Email Address",
        emailPlaceholder: "Enter email address...",
        jobTitle: "Job Title",
        jobTitlePlaceholder: "Enter job title....",
        roles: "Roles",
        featureFlags: "Feature Flags",
        bulkRoles: "Select roles for these users:",
        bulkFeatureFlags: "Select feature flags for these users:",
        roleDescription: "Roles define users permissions",
        applications: "Application Access",
        bulkApplications: "Toggle app access for these users:",
        applicationDescription: "Review all apps that the user has access to",
        afterSuccess: "User %{username} was successfully created.",
        afterError: "An error occurred, please try again.",
      },
      update: {
        afterSuccess: "User %{username} was successfully updated.",
        afterError: "An error occurred, please try again.",
        editUser: "Save User",
      },
      resetPass: {
        title: "Reset password for %{userName}?",
        confirmButton: "Reset",
        afterSuccess: "Password reset link sent to %{userEmail}",
        afterError: "An error occurred, please try again.",
      },
      deleteUser: {
        title: "Delete user %{userName}?",
        confirmButton: "Delete",
        afterSuccess: "User %{userName} was successfully deleted",
        afterError: "An error occurred, please try again.",
      },
      bulkAssignRole: {
        title: "Bulk assign roles for %{numUsers} users",
        confirmButton: "Assign Roles",
        afterSuccess: "Roles successfully updated.",
        afterError: "An error occurred, please try again.",
        warning: "This will overwrite roles for %{numUsers} users",
      },
      bulkAssignApplication: {
        title: "Bulk assign application access for %{numUsers} users",
        confirmButton: "Assign Apps",
        afterSuccess: "App assignments successfully updated.",
        afterError: "An error occurred, please try again.",
        warning: "This will overwrite application access for %{numUsers} users",
      },
    },
    fileUpload: {
      table: {
        headers: {
          name: "Name",
          description: "Description",
          baseCollection: "Base Collection",
          tags: "Tags",
          numImports: "# Imports",
          numRows: "# Rows",
          fileName: "File Name",
          uploadedAt: "Uploaded At",
          uploadedBy: "Uploaded By",
        },
        actions: {
          searchPlaceholder: "Search imports",
        },
      },
      actions: {
        newImportCollection: "New Import Collection",
        newImportInstance: "New Import Instance",
        newObjectDef: "Create New Object",
      },
      importModal: {
        title: "New Import Collection",
        submit: "Submit",
        browseFileHeader: "Upload a file",
        browseFileHeaderTooltip: "Only UTF-8 encoded .csv files are accepted",
        browseFile: "Browse File",
        extensionWarning: "We only accept .csv file extensions.",
        missingFileWarning: "Please select a file.",
        afterSuccess: "File imported successfully.",
        afterFailure: "An error occured while trying to import your file. Please contact Findem support.",
        importInstanceTitle: "New Import Instance",
      },
      deleteCollection: {
        buttonText: "Delete Import Collection",
        title: 'Delete import collection "%{importCollectionName}"?',
        warningMessage: "Deleting this import collection will delete all associated uploaded files & remove the ability to create an Object from this collection.",
        afterSuccess: "Import collection was successfully deleted.",
        afterError: "An error occurred, please try again.",
      },
      deleteInstance: {
        title: "Delete %{numImports} import(s)?",
        confirmButton: "Delete",
        afterSuccess: "%{numImports} imports were successfully deleted.",
        afterError: "An error occurred, please try again.",
      },
      createObject: {
        title: 'Create Object from "%{importCollectionName}"',
        confirmButton: "Create Object",
        afterSuccess: 'New Object "%{objectName}" successfully created.',
        afterError: "An error occurred, please try again.",
      },
    },
    roles: {
      table: {
        headers: {
          name: "Roles",
          permissions: "Permissions",
          description: "Description",
          actions: "Actions",
          type: "Type",
        },
        cells: {
          type: {
            system: "System",
            custom: "Custom",
          },
        },
      },
      tabs: {
        permissions: "Permissions",
        acls: "ACLs",
        dashboards: {
          name: "Dashboards",
          description: "Select the default dashboards for this role",
          message: {
            success: "Default dashboards successfully saved",
            error: "An error occurred while attempting to save the default dashboards. Please contact Findem support.",
          },
        },
      },
      actions: {
        view: "View Role",
        edit: "Edit Role",
        delete: "Delete",
        search: "Search...",
      },
      creation: {
        newRole: "New Role",
        newPermission: "New Permission",
        acls: "Findem ACLs",
        addRole: "Add Role",
        addAcl: "Add ACLs",
        roleNamePlaceholder: "Enter name...",
        descriptionPlaceholder: "Enter description...",
        adminSection: "For Findem Admins",
        sectionTag: "Section Tag",
      },
      deletion: {
        title: 'Delete "%{roleName}"?',
        confirmButton: "Delete",
        afterSuccess: '"%{roleName}" was successfully deleted',
        afterError: "An error occurred, please try again.",
      },
      bulkDeletion: {
        title: "Delete %{numRoles} role(s)?",
        permissionsTitle: "Delete %{numPermissions} permission(s)?",
        confirmButton: "Delete",
        afterSuccess: "%{numRoles} role(s) were successfully deleted",
        afterSuccessPermissions: "%{numPermissions} permission(s) were successfully deleted",
        afterError: "An error occurred, please try again.",
      },
      editAppPerms: {
        viewAccessOnly: "View Only Access",
        viewAccessApp: "Allow users to view data in %{appName} app",
        editAccess: "Edit Access",
        editAccessApp: "Allow users to edit data in %{appName} app",
      },
      message: {
        afterSave: "Role successfully saved",
        afterSaveError: "An error occurred while saving this Role. Please try again.",
        afterCreate: "Role successfully created",
        afterCreateError: "An error occurred while creating this Role. Please try again.",
      },
    },
    security: {
      title: "Security",
      subtitle: "Define security preferences that effect all users in your organization",
      mfa: "Multi-Factor Authentication",
      mfaSubtitle: "All users in your organization will have MFA enabled",
      sso: "Okta SSO",
      ssoSubtitle: "Connect Okta to allow quick user authentication",
      ssoButton: "Connect Okta SSO",
      ssoConnected: "Okta SSO Connected",
      save: "Save updates",
      messages: {
        updateInProgress: "Updating company security settings.",
        afterSuccess: "Company security settings sucessfully updated.",
        afterError: "An error occurred while updating company security settings. Please contact Findem Support.",
      },
      ssoForm: {
        domain: "Domain",
        issuer: "Issuer",
        clientId: "Client ID",
        clientSecret: "Client Secret",
        accessManagement: "Enable API Access Management",
      },
    },
    aboutMe: {
      labels: {
        name: "Name",
        title: "Title",
        email: "Email Address",
        phone: "Phone Number",
        companyName: "Company Name",
        companyWebsite: "Company Website",
      },
      placeholders: {
        title: "Eg, Recruiter",
        phone: "Eg, 555.555.5555",
      },
      messages: {
        updating: 'Updating "About Me" settings.',
        afterSuccess: '"About Me" settings sucessfully updated.',
        afterError: 'An error occurred while updating "About Me" settings. Please contact Findem Support.',
      },
    },
    accounts: {
      table: {
        headers: {
          accounts: "Accounts",
          accountId: "Account ID",
          createdOn: "Created On",
          users: "Users",
          mode: "Mode",
          type: "Type",
        },
        cells: {
          type: {
            customer: "Customer",
            prospect: "Prospect",
            test: "Test",
            deactivated: "Deactivated",
          },
        },
      },
      creation: {
        newAccount: "New Account",
      },
      update: {
        createTitle: "Create New Account",
        editTitle: "Edit Account",
        nameLabel: "Company Name",
        namePlaceholder: "Enter company name...",
        domainLabel: "Company Domain",
        domainPlaceholder: "Enter company domain...",
        typeLabel: "Account Type",
        modeLabel: "Account Mode",
        createButton: "Create Account",
        editButton: "Update Account",
        messages: {
          afterCreateSuccess: "Account successfully created",
          afterUpdateSuccess: "Account successfully updated",
          afterCreateError: "An error occured while attempting to create this account",
          afterUpdateError: "An error occured while attempting to update this account",
        },
      },
      account: {
        subtitle: "Created on %{createdDate}",
        tabs: {
          users: "Users",
          applications: "Applications",
        },
        applications: {
          messages: {
            updating: "Updating application status",
            afterSuccess: "Application status successfully updated",
            afterError: "An error occurred while attempting to update the application status",
          },
        },
      },
      deleteAccount: {
        title: 'Delete account "%{accountName}"?',
        confirmButton: "Delete",
        afterSuccess: 'Account "%{accountName}" was successfully deleted',
        afterError: "An error occurred, please try again.",
      },
    },
  },
  parameterization: {
    type: {
      column: "Column",
      derivedColumn: "Derived Column",
      time: "Time",
      ats: "ATS User ID",
      findem: "Findem User ID",
    },
    displayName: {
      ats: "ATS User",
      findem: "Findem User",
    },
    filter: {
      noneSelected: "No %{filterName}",
      allSelected: "Any %{filterName}",
      search: "Filter %{paramName}",
      unappliedPartOneSingular: "1 filter set ",
      unappliedPartOnePlural: "%{numFilterSets} filter sets ",
      unappliedPartTwo: "could not be applied to this report:",
    },
    name: {
      timeStart: "Time Filter Start",
      timeEnd: "Time Filter End",
    },
  },
  impersonation: {
    startSession: "Enter User Impersonation",
    workingIn: "Working in",
    endSession: "End Session",
    endUserSession: "End User Impersonation",
    showBanner: "Show Banner",
    hideBanner: "Hide Banner",
  },
  chatbox: {
    reportInsights: {
      title: "Findem AI Assistant",
      error: "I'm sorry, an error has occurred. Please try again later.",
    },
    online: "Online",
  },
  insights: {
    header: "Findem Insights",
    studies: {
      title: "Studies",
      subtitle: "",
      creating: "Creating Study",
      afterCreate: "Study successfully created",
      afterCreateError: "Failed to create Study",
      saving: "Saving Study",
      afterSave: "Study successfully saved",
      afterSaveError: "Failed to save Study",
    },
    studyFilters: {
      title: "Talent Groups",
      subtitle: "",
      creating: "Creating Talent Group",
      editing: "Editing Talent Group",
      afterSave: "Talent Group successfully saved",
      afterDelete: "Talent Group successfully deleted",
      afterDeleteError: "Failed to delete Talent Group",
      afterSaveError: "There was an error saving this Talent Group. Please try again.",
    },
  },
  ["Last update"]: "Last update",
  ["Assigned Apps"]: "Assigned Apps",
  ["Last seen"]: "Last seen",
  ["Tags"]: "Tags",
  ["Recruiter"]: "Recruiter",
};

export default en;
