import { v4 as uuidv4 } from "uuid";
import { IProjectionExpression, IRelation, ISetMembershipBooleanExpression } from "../conversions/ReportConversions";
import { FilterValueSelectorOptions } from "../reports/Selectors/Filter/FilterSelector";
import {
  IAggregateFunctionType,
  IAliasedExpression,
  IBinaryArithmeticOp,
  IBinaryLogicalExpression,
  IBooleanExpression,
  IBooleanOp,
  IColumnIdentifier,
  IExpression,
  IExpressionType,
  ILiteralExpression,
  IRelationalBooleanExpression,
  IRelationalOp,
} from "./../conversions/ReportConversions";
import { IColumnDef, IObjectDef } from "./../types/object";
import { IColumnEntry, IColumnSettings, IRowFilter } from "./../types/report";
import { getNameFromAggregationType } from "./chartSettingsUtils";
import { isTableFunctionIdentifier } from "./functionUtils";
import { getParameterizationNameDisplayName } from "./parameterizationUtils";

export const ALIAS_SEPARATOR_TOKEN: string = "_alias_separator_";
export const ALIAS_DISPLAY_NAME_SEPARATOR_TOKEN: string = "_display_name_separator_";

//-----------------------------------------------------------------------
// ID / Alias retrieval
//-----------------------------------------------------------------------

export function getProjectionExpressionId(expression: IProjectionExpression): string {
  return getAliasedExpressionId(expression.col);
}

export function getProjectionColumnId(expression: IProjectionExpression): string {
  return getExpressionId(expression.col.expr);
}

export function getAliasedExpressionId(expression: IAliasedExpression): string {
  return expression.alias ?? getExpressionId(expression.expr);
}

export function getAliasedExpressionGroupId(expression: IAliasedExpression): string {
  return expression.alias ? expression.alias.split(ALIAS_SEPARATOR_TOKEN)[0] : getExpressionId(expression.expr);
}

export function getExpressionAlias(expression: IExpression): string | undefined {
  switch (expression?.kind) {
    case IExpressionType.AliasedExpression:
      return expression.alias ?? getExpressionAlias(expression.expr);
    case IExpressionType.FunctionExpression:
    case IExpressionType.InlineExpression:
      return expression.alias;
    case IExpressionType.AggregateExpression:
    case IExpressionType.SetMembershipBooleanExpression:
      return getExpressionAlias(expression.expr);
    case IExpressionType.RelationalBooleanExpression:
    case IExpressionType.BinaryLogicalExpression:
      return getExpressionAlias(expression.left) ?? getExpressionAlias(expression.right);
    default:
      return undefined;
  }
}

export function getExpressionId(expression: IExpression): string {
  switch (expression?.kind) {
    case IExpressionType.ColumnIdentifier:
      return expression.col.col_id;
    case IExpressionType.BinaryExpression:
      return "Binary Expression Name TODO";
    case IExpressionType.LiteralExpression:
      return `${expression.value}`;
    case IExpressionType.FunctionExpression:
      return expression.alias ?? expression.id;
    case IExpressionType.AliasedExpression:
      return getAliasedExpressionId(expression);
    case IExpressionType.ProjectionExpression:
      return getProjectionExpressionId(expression);
    case IExpressionType.AggregateExpression:
      return getExpressionId(expression.expr);
    case IExpressionType.InlineExpression:
      return expression.alias ?? expression.value;
    case IExpressionType.RelationalBooleanExpression:
      return getExpressionId(expression.left) ?? getExpressionId(expression.right);
    case IExpressionType.SetMembershipBooleanExpression:
      return getExpressionId(expression.expr);
    case IExpressionType.BinaryLogicalExpression:
      return getExpressionId(expression.left) ?? getExpressionId(expression.right);
    default:
      return `Unimplemented expression type: ${expression?.kind}`;
  }
}

export function getExpressionObjectIds(expression: IExpression): string[] {
  switch (expression?.kind) {
    case IExpressionType.ColumnIdentifier:
      return [expression.col.obj_id];
    case IExpressionType.RelationalBooleanExpression:
    case IExpressionType.BinaryExpression:
    case IExpressionType.BinaryLogicalExpression:
      return getExpressionObjectIds(expression.left).concat(getExpressionObjectIds(expression.right));
    case IExpressionType.LiteralExpression:
      return [];
    case IExpressionType.FunctionExpression:
      return expression.operands.flatMap((operand) => getExpressionObjectIds(operand));
    case IExpressionType.AliasedExpression:
    case IExpressionType.AggregateExpression:
    case IExpressionType.SetMembershipBooleanExpression:
      return getExpressionObjectIds(expression.expr);
    case IExpressionType.InlineExpression:
      return expression.source_obj_columns.map((value) => value.obj_id);
    default:
      return [];
  }
}

export function getExpressionColEntry(expression: IExpression): IColumnEntry | undefined {
  switch (expression.kind) {
    case IExpressionType.ColumnIdentifier:
      return expression.col;
    case IExpressionType.AggregateExpression:
    case IExpressionType.AliasedExpression:
      return getExpressionColEntry(expression.expr);
    case IExpressionType.ProjectionExpression:
      return getExpressionColEntry(expression.col);
    case IExpressionType.RelationalBooleanExpression:
      return getExpressionColEntry(expression.left) ?? getExpressionColEntry(expression.right);
    case IExpressionType.SetMembershipBooleanExpression:
      return getExpressionColEntry(expression.expr);
    case IExpressionType.BinaryLogicalExpression:
      return getExpressionColEntry(expression.left) ?? getExpressionColEntry(expression.right);
    case IExpressionType.FunctionExpression:
      const colEntries: IColumnEntry[] = expression.operands.flatMap((value: IExpression) => {
        const colEntry: IColumnEntry | undefined = getExpressionColEntry(value);
        return colEntry ? [colEntry] : [];
      });
      return colEntries?.at(0);
    case IExpressionType.InlineExpression:
      return expression.source_obj_columns?.at(0);
    default:
      return undefined;
  }
}

/**
 * Returns a {IColumnEntry} from the filter, if the filter is operating on an {IObjectDef} column
 * & not a derived column.
 *
 * @param filter
 * @returns
 */
export function getTopLevelRowFilterColEntry(filter: IRowFilter): IColumnEntry | undefined {
  switch (filter.kind) {
    case IExpressionType.RelationalBooleanExpression:
      if (filter.left.kind === IExpressionType.AliasedExpression) {
        return filter.left.expr.kind === IExpressionType.ColumnIdentifier ? filter.left.expr.col : undefined;
      } else if (filter.left.kind === IExpressionType.ColumnIdentifier) {
        return filter.left.col;
      } else if (filter.left.kind == IExpressionType.SetMembershipBooleanExpression && filter.right.kind === IExpressionType.LiteralExpression) {
        return getTopLevelRowFilterColEntry(filter.left);
      } else {
        return undefined;
      }
    case IExpressionType.SetMembershipBooleanExpression:
      if (filter.expr.kind === IExpressionType.AliasedExpression) {
        return filter.expr.expr.kind === IExpressionType.ColumnIdentifier ? filter.expr.expr.col : undefined;
      } else if (filter.expr.kind === IExpressionType.ColumnIdentifier) {
        return filter.expr.col;
      } else {
        return undefined;
      }
    case IExpressionType.BinaryLogicalExpression:
      return getTopLevelRowFilterColEntry(filter.left) ?? getTopLevelRowFilterColEntry(filter.right);
    default:
      return undefined;
  }
}

/**
 * Returns a {IColumnEntry} from the filter, if the filter is operating on an {IObjectDef} column
 * & not a derived column.
 *
 * @param filter
 * @returns
 */
export function getRowFilterAliasedExpression(filter: IRowFilter): IAliasedExpression | undefined {
  switch (filter.kind) {
    case IExpressionType.RelationalBooleanExpression:
      if (filter.left.kind === IExpressionType.AliasedExpression) {
        return filter.left;
      } else if (instanceOfRowFilter(filter.left)) {
        return getRowFilterAliasedExpression(filter.left);
      } else if (instanceOfRowFilter(filter.right)) {
        return getRowFilterAliasedExpression(filter.right);
      } else {
        return undefined;
      }
    case IExpressionType.SetMembershipBooleanExpression:
      return filter.expr.kind === IExpressionType.AliasedExpression ? filter.expr : undefined;
    case IExpressionType.BinaryLogicalExpression:
      return getRowFilterAliasedExpression(filter.left) ?? getRowFilterAliasedExpression(filter.right);
    default:
      return undefined;
  }
}

export function getExpressionValues(expression: IExpression): (string | number | boolean)[] {
  switch (expression.kind) {
    case IExpressionType.LiteralExpression:
      return [expression.value];
    case IExpressionType.AliasedExpression:
      return getExpressionValues(expression.expr);
    case IExpressionType.ProjectionExpression:
      return getExpressionValues(expression.col);
    case IExpressionType.RelationalBooleanExpression:
      if (expression.op === IRelationalOp.NotEquals && expression.right.kind === IExpressionType.LiteralExpression && expression.right.value === true) {
        return getExpressionValues(expression.left);
      } else {
        return getExpressionValues(expression.left).concat(getExpressionValues(expression.right));
      }
    case IExpressionType.SetMembershipBooleanExpression:
      return Array.isArray(expression.values) ? expression.values.flatMap((literalExpression: ILiteralExpression) => getExpressionValues(literalExpression)) : [];
    case IExpressionType.BinaryLogicalExpression:
      return getExpressionValues(expression.left).concat(getExpressionValues(expression.right));
    case IExpressionType.InlineExpression:
    case IExpressionType.FunctionExpression:
    case IExpressionType.BinaryExpression:
    case IExpressionType.ColumnIdentifier:
    default:
      return [];
  }
}

export function getRelationalOp(expression: IExpression): IRelationalOp | undefined {
  switch (expression.kind) {
    case IExpressionType.RelationalBooleanExpression:
      return expression.op;
    case IExpressionType.BinaryLogicalExpression:
      return getRelationalOp(expression.left) ?? getRelationalOp(expression.right);
    case IExpressionType.SetMembershipBooleanExpression:
    default:
      return undefined;
  }
}

export function doAllColumnExpressionsMatch(expression: IExpression, colEntry: IColumnEntry): boolean {
  switch (expression.kind) {
    case IExpressionType.SetMembershipBooleanExpression:
      const entry: IColumnEntry | undefined = getExpressionColEntry(expression.expr);
      return JSON.stringify(colEntry) === JSON.stringify(entry) && doAllColumnExpressionsMatch(expression.expr, colEntry);
    case IExpressionType.RelationalBooleanExpression:
    case IExpressionType.BinaryLogicalExpression:
      const leftEntry: IColumnEntry | undefined = getExpressionColEntry(expression.left);
      const rightEntry: IColumnEntry | undefined = getExpressionColEntry(expression.right);
      return (
        (!leftEntry || JSON.stringify(leftEntry) === JSON.stringify(colEntry)) &&
        (!rightEntry || JSON.stringify(rightEntry) === JSON.stringify(colEntry)) &&
        doAllColumnExpressionsMatch(expression.left, colEntry) &&
        doAllColumnExpressionsMatch(expression.right, colEntry)
      );
    case IExpressionType.LiteralExpression:
      return true;
    default:
      const defaultEntry: IColumnEntry | undefined = getExpressionColEntry(expression);
      return (
        (!defaultEntry || JSON.stringify(colEntry) === JSON.stringify(defaultEntry)) &&
        expression.kind !== IExpressionType.InlineExpression &&
        expression.kind !== IExpressionType.FunctionExpression
      );
  }
}

export function getParameterizedExpressionNames(expression: IExpression): string[] {
  const expressionNames: string[] = [];

  switch (expression.kind) {
    case IExpressionType.RelationalBooleanExpression:
      if (expression.left.kind === IExpressionType.ParameterizedExpression) {
        expressionNames.push(expression.left.parameter_name);
      }

      if (expression.right.kind === IExpressionType.ParameterizedExpression) {
        expressionNames.push(expression.right.parameter_name);
      }
      break;
    case IExpressionType.SetMembershipBooleanExpression:
      if (!Array.isArray(expression.values)) {
        expressionNames.push(expression.values.parameter_name);
      }
      break;
    case IExpressionType.BinaryLogicalExpression:
      expressionNames.push(...getParameterizedExpressionNames(expression.left));
      expressionNames.push(...getParameterizedExpressionNames(expression.right));
      break;
    case IExpressionType.AliasedExpression:
    case IExpressionType.AggregateExpression:
      expressionNames.push(...getParameterizedExpressionNames(expression.expr));
      break;
    case IExpressionType.ParameterizedExpression:
    case IExpressionType.ParameterizedArrayExpression:
      expressionNames.push(expression.parameter_name);
      break;
    case IExpressionType.FunctionExpression:
      expressionNames.push(...expression.operands.flatMap((operand: IExpression) => getParameterizedExpressionNames(operand)));
      break;
    default:
      break;
  }

  return expressionNames;
}

//-----------------------------------------------------------------------
// Expression Name Retrieval
//-----------------------------------------------------------------------

export function getProjectionExpressionName(expression: IProjectionExpression, objects: IObjectDef[]): string {
  return getAliasedExpressionName(expression.col, objects);
}

export function getAliasedExpressionName(expression: IAliasedExpression, objects: IObjectDef[]): string {
  return expression.alias ? expression.alias.split(ALIAS_SEPARATOR_TOKEN)[0] : getExpressionName(expression.expr, objects);
}

export function getExpressionName(expression: IExpression, objects: IObjectDef[]): string {
  switch (expression.kind) {
    case IExpressionType.ColumnIdentifier:
      return getColumnNameFromIdentifier(expression, objects);
    case IExpressionType.BinaryExpression:
      return `${getExpressionName(expression.left, objects)} ${getBinaryArithmeticOpString(expression.op)} ${getExpressionName(expression.right, objects)}`;
    case IExpressionType.LiteralExpression:
      return `${expression.value}`;
    case IExpressionType.FunctionExpression:
      return expression.alias ?? expression.id;
    case IExpressionType.InlineExpression:
      return expression.alias ?? expression.value;
    case IExpressionType.AliasedExpression:
      return getAliasedExpressionName(expression, objects);
    case IExpressionType.ProjectionExpression:
      return getProjectionExpressionName(expression, objects);
    case IExpressionType.AggregateExpression:
      // TODO: localize
      return `${getNameFromAggregationType(expression.function)} of ${getExpressionName(expression.expr, objects)}`;
    case IExpressionType.ParameterizedExpression:
    case IExpressionType.ParameterizedArrayExpression:
      return getParameterizationNameDisplayName(expression.parameter_name);
    default:
      return `Unimplemented expression type: ${expression.kind}`;
  }
}

export function getColumnNameFromIdentifier(colIdentifier: IColumnIdentifier, objects: IObjectDef[]): string {
  return (
    objects.find((object: IObjectDef) => object.resource_id === colIdentifier.col.obj_id)?.default_cols?.find((column: IColumnDef) => column.col_id === colIdentifier.col.col_id)
      ?.col_name ?? colIdentifier.col.col_id
  );
}

//-----------------------------------------------------------------------
// Display Name Retrieval
//-----------------------------------------------------------------------

export function getDisplayNameFromAlias(alias: string, allColumnSettings: Record<string, IColumnSettings>): string {
  const displayName: string = allColumnSettings[alias]?.displayName ?? alias;
  if (displayName.includes(ALIAS_DISPLAY_NAME_SEPARATOR_TOKEN) && displayName.split(ALIAS_DISPLAY_NAME_SEPARATOR_TOKEN).length === 2) {
    return `${displayName.split(ALIAS_DISPLAY_NAME_SEPARATOR_TOKEN)[1]} (${displayName.split(ALIAS_DISPLAY_NAME_SEPARATOR_TOKEN)[0]})`;
  } else {
    return displayName;
  }
}

//-----------------------------------------------------------------------
// Column Identifier Retrieval
//-----------------------------------------------------------------------

export function getColumnEntriesFromExpressions(expressions: IExpression[]): IColumnEntry[] {
  return expressions.flatMap((expression: IExpression) => getColumnEntriesFromExpression(expression));
}

export function getColumnEntriesFromExpression(expression: IExpression): IColumnEntry[] {
  switch (expression?.kind) {
    case IExpressionType.ColumnIdentifier:
      return [expression.col];
    case IExpressionType.BinaryExpression:
      return [...getColumnEntriesFromExpression(expression.left), ...getColumnEntriesFromExpression(expression.right)];
    case IExpressionType.LiteralExpression:
      return [];
    case IExpressionType.FunctionExpression:
      return expression.operands.flatMap((operand: IExpression) => getColumnEntriesFromExpression(operand));
    case IExpressionType.AliasedExpression:
      return getColumnEntriesFromExpression(expression.expr);
    case IExpressionType.AggregateExpression:
      return getColumnEntriesFromExpression(expression.expr);
    case IExpressionType.InlineExpression:
      return expression.source_obj_columns;
    case IExpressionType.RelationalBooleanExpression:
      return [...getColumnEntriesFromExpression(expression.left), ...getColumnEntriesFromExpression(expression.right)];
    case IExpressionType.SetMembershipBooleanExpression:
      return getColumnEntriesFromExpression(expression.expr);
    case IExpressionType.BinaryLogicalExpression:
      return [...getColumnEntriesFromExpression(expression.left), ...getColumnEntriesFromExpression(expression.right)];
    default:
      return [];
  }
}

//-----------------------------------------------------------------------
// Validation
//-----------------------------------------------------------------------

export function getNumericOutputProjections(projections: IAliasedExpression[]): IAliasedExpression[] {
  return projections.filter((projection: IAliasedExpression) => aliasedExpressionHasNumericOutput(projection));
}

export function aliasedExpressionHasNumericOutput(projection: IAliasedExpression): boolean {
  return projection.expr.kind === IExpressionType.AggregateExpression || projection.expr.kind === IExpressionType.BinaryExpression;
}

export function isProjectionExpressionEditable(projection: IProjectionExpression, relation: IRelation | undefined): boolean {
  return isAliasedExpressionEditable(projection.col, relation);
}

export function isAliasedExpressionEditable(expression: IAliasedExpression, relation: IRelation | undefined): boolean {
  return isExpressionEditable(expression.expr, relation);
}

export function isExpressionEditable(expression: IExpression, relation: IRelation | undefined): boolean {
  return (
    expression.kind === IExpressionType.BinaryExpression ||
    expression.kind === IExpressionType.FunctionExpression ||
    expression.kind === IExpressionType.InlineExpression ||
    (expression.kind === IExpressionType.AggregateExpression && isExpressionEditable(expression.expr, relation)) ||
    (expression.kind === IExpressionType.ColumnIdentifier && isTableFunctionIdentifier(expression.col.obj_id, relation))
  );
}

//-----------------------------------------------------------------------
// Expression builders
//-----------------------------------------------------------------------

export function buildProjectionExpression(colEntry: IColumnEntry): IProjectionExpression {
  return {
    kind: IExpressionType.ProjectionExpression,
    col: buildAliasedExpression(colEntry),
  };
}

export function buildProjectionExpressionFromDef(colDef: IColumnDef): IProjectionExpression {
  return {
    kind: IExpressionType.ProjectionExpression,
    col: buildAliasedExpressionFromDef(colDef),
  };
}

export function buildAliasedExpression(colEntry: IColumnEntry): IAliasedExpression {
  return {
    kind: IExpressionType.AliasedExpression,
    expr: {
      kind: IExpressionType.ColumnIdentifier,
      col: colEntry,
    },
  };
}

export function buildLiteralExpression(value: string | number | boolean): ILiteralExpression {
  return {
    kind: IExpressionType.LiteralExpression,
    value: value,
  };
}

export function buildAliasedExpressionFromDef(colDef: IColumnDef): IAliasedExpression {
  return {
    kind: IExpressionType.AliasedExpression,
    expr: colDef.value_def.col_formula ?? buildColumnIdentifierFromDef(colDef),
    alias: uuidv4(),
  };
}

export function buildAggregateAliasedExpressionFromDef(colDef: IColumnDef, aggegationType: IAggregateFunctionType): IAliasedExpression {
  return {
    kind: IExpressionType.AliasedExpression,
    expr: {
      kind: IExpressionType.AggregateExpression,
      function: aggegationType,
      expr: buildColumnIdentifierFromDef(colDef),
    },
    // TODO: localize
    alias: `${getNameFromAggregationType(aggegationType)} of ${colDef.col_name}`,
  };
}

export function buildCountAliasedExpression(expression: IAliasedExpression): IAliasedExpression {
  return {
    kind: IExpressionType.AliasedExpression,
    expr: {
      kind: IExpressionType.AggregateExpression,
      function: IAggregateFunctionType.Count,
      expr: expression.expr,
    },
    alias: `${expression.alias}${ALIAS_SEPARATOR_TOKEN}count`,
  };
}

export function buildColumnIdentifierFromDef(colDef: IColumnDef): IColumnIdentifier {
  return {
    kind: IExpressionType.ColumnIdentifier,
    col: {
      obj_id: colDef.obj_id,
      col_id: colDef.col_id,
    },
  };
}

//-----------------------------------------------------------------------
// Replacement
//-----------------------------------------------------------------------

export function replaceExpressionsObjectIds<T extends IExpression>(expressions: T[], objectId: string): T[] {
  return expressions.map((expression: T) => replaceExpressionObjectIds(expression, objectId));
}

/**
 * Replaces the all {obj_id}s used in the expression (and underlying expressions) with the specified {objectId}
 */
export function replaceExpressionObjectIds<T extends IExpression>(expression: T, objectId: string): T {
  switch (expression?.kind) {
    case IExpressionType.ColumnIdentifier:
      return {
        ...expression,
        col: {
          ...expression.col,
          obj_id: objectId,
        },
      };
    case IExpressionType.FunctionExpression:
      return {
        ...expression,
        operands: expression.operands.map((operand: IExpression) => replaceExpressionObjectIds(operand, objectId)),
      };
    case IExpressionType.AliasedExpression:
    case IExpressionType.AggregateExpression:
    case IExpressionType.SetMembershipBooleanExpression:
      return {
        ...expression,
        expr: replaceExpressionObjectIds(expression.expr, objectId),
      };
    case IExpressionType.BinaryExpression:
    case IExpressionType.RelationalBooleanExpression:
    case IExpressionType.BinaryLogicalExpression:
      return {
        ...expression,
        left: replaceExpressionObjectIds(expression.left, objectId),
        right: replaceExpressionObjectIds(expression.right, objectId),
      };
    case IExpressionType.InlineExpression:
    case IExpressionType.LiteralExpression:
    case IExpressionType.NullExpression:
      return expression;
    default:
      throw Error(`Unimplemented expression type: ${expression?.kind}`);
  }
}

/**
 * Replaces the all {col_ids}s used in the expression with the parent {AliasedExpression}'s alias
 */
export function replaceExpressionColIdsWithAlias<T extends IExpression>(expression: T, alias?: string): T {
  switch (expression?.kind) {
    case IExpressionType.ColumnIdentifier:
      return alias ? { ...expression, col: { ...expression.col, col_id: alias } } : expression;
    case IExpressionType.FunctionExpression:
      return {
        ...expression,
        operands: expression.operands.map((operand: IExpression) => replaceExpressionColIdsWithAlias(operand)),
      };
    case IExpressionType.AliasedExpression:
      return {
        ...expression,
        expr: replaceExpressionColIdsWithAlias(expression.expr, expression.alias),
      };
    case IExpressionType.AggregateExpression:
    case IExpressionType.SetMembershipBooleanExpression:
      return {
        ...expression,
        expr: replaceExpressionColIdsWithAlias(expression.expr),
      };
    case IExpressionType.BinaryExpression:
    case IExpressionType.RelationalBooleanExpression:
    case IExpressionType.BinaryLogicalExpression:
      return {
        ...expression,
        left: replaceExpressionColIdsWithAlias(expression.left),
        right: replaceExpressionColIdsWithAlias(expression.right),
      };
    case IExpressionType.InlineExpression:
    case IExpressionType.LiteralExpression:
    case IExpressionType.NullExpression:
      return expression;
    default:
      throw Error(`Unimplemented expression type: ${expression?.kind}`);
  }
}

export function replaceExpressionsIds<T extends IExpression>(expressions: T[], oldObjId: string, newObjId: string, oldColId: string, newColId: string): T[] {
  return expressions.map((expression: T) => replaceExpressionIds(expression, oldObjId, newObjId, oldColId, newColId));
}

/**
 * Traverses the {expression} to find and replace any {obj_id} which matches {oldObjId} with {newObjId} - or {col_id} which matches {oldColId} with {newColId}
 */
export function replaceExpressionIds<T extends IExpression>(expression: T, oldObjId: string, newObjId: string, oldColId: string, newColId: string): T {
  switch (expression?.kind) {
    case IExpressionType.ColumnIdentifier:
      return {
        ...expression,
        col: { obj_id: expression.col.obj_id === oldObjId ? newObjId : expression.col.obj_id, col_id: expression.col.col_id === oldColId ? newColId : expression.col.col_id },
      };
    case IExpressionType.FunctionExpression:
      return {
        ...expression,
        operands: expression.operands.map((operand: IExpression) => replaceExpressionIds(operand, oldObjId, newObjId, oldColId, newColId)),
      };
    case IExpressionType.AliasedExpression:
    case IExpressionType.AggregateExpression:
    case IExpressionType.SetMembershipBooleanExpression:
      return {
        ...expression,
        expr: replaceExpressionIds(expression.expr, oldObjId, newObjId, oldColId, newColId),
      };
    case IExpressionType.RelationalBooleanExpression:
    case IExpressionType.BinaryExpression:
    case IExpressionType.BinaryLogicalExpression:
      return {
        ...expression,
        left: replaceExpressionIds(expression.left, oldObjId, newObjId, oldColId, newColId),
        right: replaceExpressionIds(expression.right, oldObjId, newObjId, oldColId, newColId),
      };
    case IExpressionType.LiteralExpression:
    case IExpressionType.InlineExpression:
    case IExpressionType.NullExpression:
      return expression;
    default:
      throw Error(`Unimplemented expression type: ${expression?.kind}`);
  }
}

//-----------------------------------------------------------------------
// Aggregations
//-----------------------------------------------------------------------

export function buildAggregationAlias(baseProjectionAlias: string, aggregationType: IAggregateFunctionType): string {
  return `${baseProjectionAlias}${ALIAS_SEPARATOR_TOKEN}${aggregationType}`;
}

export function getBaseAliasFromAggregationKey(key: string): string {
  return key.split(ALIAS_SEPARATOR_TOKEN)[0];
}

export function getAggregationDisplayNameFromAggregationKey(key: string): string | undefined {
  const splitKey: string[] = key.split(ALIAS_SEPARATOR_TOKEN);

  if (splitKey.length === 2) {
    return getDisplayNameFromAggregateFunctionType(splitKey[1]);
  } else {
    return undefined;
  }
}

export function getAggregationFunctionTypeFromAlias(alias: string): IAggregateFunctionType | undefined {
  const splitAlias: string[] = alias.split(ALIAS_SEPARATOR_TOKEN);

  if (splitAlias.length === 2) {
    return splitAlias[1] as IAggregateFunctionType;
  } else {
    return undefined;
  }
}

// TODO: pass in translate & localize
export function getDisplayNameFromAggregateFunctionType(value: string): string | undefined {
  if (instanceOfAggregateFunctionType(value)) {
    switch (value) {
      case IAggregateFunctionType.Avg:
        return "Avg";
      case IAggregateFunctionType.Min:
        return "Min";
      case IAggregateFunctionType.Max:
        return "Max";
      case IAggregateFunctionType.Sum:
        return "Sum";
      case IAggregateFunctionType.Count:
        return "Count";
      default:
        return undefined;
    }
  } else {
    return undefined;
  }
}

export function instanceOfAggregateFunctionType(value: string): value is IAggregateFunctionType {
  return Object.values(IAggregateFunctionType).includes(value as IAggregateFunctionType);
}

//-----------------------------------------------------------------------
// Filters
//-----------------------------------------------------------------------

export function buildSetMembershipBooleanExpression(expression: IExpression, values: string[], option: FilterValueSelectorOptions): IBooleanExpression {
  if (option === FilterValueSelectorOptions.IS || option === FilterValueSelectorOptions.IS_NOT) {
    const setMembershipBooleanExpression: ISetMembershipBooleanExpression = {
      kind: IExpressionType.SetMembershipBooleanExpression,
      expr: expression,
      values: values.map((value: string) => {
        return {
          kind: IExpressionType.LiteralExpression,
          value: value,
        };
      }),
    };

    return option === FilterValueSelectorOptions.IS_NOT ? negateBooleanExpression(setMembershipBooleanExpression) : setMembershipBooleanExpression;
  } else {
    const expressions: IRelationalBooleanExpression[] = values.map((value: string) => {
      return buildFilterRelationalBooleanExpression(expression, value, option);
    });
    return combineBooleanExpressions(expressions, IBooleanOp.Or) as IBooleanExpression;
  }
}

export function combineBooleanExpressions(booleanExpressions: IRelationalBooleanExpression[], operation: IBooleanOp): IRelationalBooleanExpression | IBinaryLogicalExpression {
  if (booleanExpressions.length === 1) {
    return booleanExpressions[0];
  }

  var booleanExp: Partial<IBooleanExpression> = {
    kind: IExpressionType.BinaryLogicalExpression,
    op: operation,
  };

  booleanExpressions.reduce((acc: Partial<IBinaryLogicalExpression>, expression: IBooleanExpression, index: number) => {
    if (!acc.left) {
      acc.left = expression;
    } else if (index === booleanExpressions.length - 1) {
      acc.right = expression;
    } else {
      acc.right = {
        kind: IExpressionType.BinaryLogicalExpression,
        op: operation,
        left: expression,
      } as IBinaryLogicalExpression;

      return acc.right;
    }

    return acc;
  }, booleanExp);

  return booleanExp as IBinaryLogicalExpression;
}

export function buildRelationalBooleanExpression(expression: IExpression, value: number, isGreaterThan: boolean, inclusive: boolean = true): IRelationalBooleanExpression {
  let op: IRelationalOp;

  if (inclusive) {
    if (isGreaterThan) {
      op = IRelationalOp.GreaterThanOrEquals;
    } else {
      op = IRelationalOp.LessThanOrEquals;
    }
  } else {
    if (isGreaterThan) {
      op = IRelationalOp.GreaterThan;
    } else {
      op = IRelationalOp.LessThan;
    }
  }

  return {
    kind: IExpressionType.RelationalBooleanExpression,
    op: op,
    left: expression,
    right: {
      kind: IExpressionType.LiteralExpression,
      value: value,
    },
  };
}

export function buildFilterRelationalBooleanExpression(expression: IExpression, value: any, option: FilterValueSelectorOptions): IRelationalBooleanExpression {
  return {
    kind: IExpressionType.RelationalBooleanExpression,
    op: convertFilterValueSelectorOptions(option),
    left: expression,
    right: {
      kind: IExpressionType.LiteralExpression,
      value: value,
    },
  };
}

export function buildNullRelationalBooleanExpressionFromCol(colEntry: IColumnEntry, exclude: boolean): IRelationalBooleanExpression {
  return {
    kind: IExpressionType.RelationalBooleanExpression,
    op: exclude ? IRelationalOp.IsNot : IRelationalOp.Is,
    left: {
      kind: IExpressionType.ColumnIdentifier,
      col: colEntry,
    },
    right: {
      kind: IExpressionType.NullExpression,
    },
  };
}

export function buildNullRelationalBooleanExpression(expression: IExpression, exclude: boolean): IRelationalBooleanExpression {
  return {
    kind: IExpressionType.RelationalBooleanExpression,
    op: exclude ? IRelationalOp.IsNot : IRelationalOp.Is,
    left: expression,
    right: {
      kind: IExpressionType.NullExpression,
    },
  };
}

export function isRowFilterParameterized(rowFilter: IBooleanExpression): boolean {
  switch (rowFilter.kind) {
    case IExpressionType.RelationalBooleanExpression:
      return rowFilter.left.kind === IExpressionType.ParameterizedExpression || rowFilter.right.kind === IExpressionType.ParameterizedExpression;
    case IExpressionType.SetMembershipBooleanExpression:
      return !Array.isArray(rowFilter.values);
    case IExpressionType.BinaryLogicalExpression:
      return isRowFilterParameterized(rowFilter.left) || isRowFilterParameterized(rowFilter.right);
    default:
      return false;
  }
}

//-----------------------------------------------------------------------
// Instance Of
//-----------------------------------------------------------------------

export function instanceOfIExpression(object: any): object is IExpression {
  return "kind" in object && Object.values(IExpressionType).includes(object.kind as IExpressionType);
}

//-----------------------------------------------------------------------
// Conversions
//-----------------------------------------------------------------------

// TODO: need to fix some of these options
export function convertFilterValueSelectorOptions(option: FilterValueSelectorOptions): IRelationalOp {
  switch (option) {
    case FilterValueSelectorOptions.IS:
      return IRelationalOp.Is;
    case FilterValueSelectorOptions.IS_NOT:
      return IRelationalOp.IsNot;
    case FilterValueSelectorOptions.EQUALS:
      return IRelationalOp.Equals;
    case FilterValueSelectorOptions.NOT_EQUALS:
      return IRelationalOp.NotEquals;
    case FilterValueSelectorOptions.LESS_THAN:
      return IRelationalOp.LessThan;
    case FilterValueSelectorOptions.HAPPENS_BEFORE:
      return IRelationalOp.LessThanOrEquals;
    case FilterValueSelectorOptions.GREATER_THAN:
      return IRelationalOp.GreaterThan;
    case FilterValueSelectorOptions.HAPPENS_AFTER:
      return IRelationalOp.GreaterThanOrEquals;
    default:
      return IRelationalOp.Is;
  }
}

export function getBinaryArithmeticOpString(op: IBinaryArithmeticOp): string {
  switch (op) {
    case IBinaryArithmeticOp.Add:
      return "+";
    case IBinaryArithmeticOp.Divide:
      return "/";
    case IBinaryArithmeticOp.Multiply:
      return "*";
    case IBinaryArithmeticOp.Subtract:
    default:
      return "-";
  }
}

//----------------------------------------------------------------------
// Instance Of
//----------------------------------------------------------------------

export function instanceOfRowFilter(expression: IExpression): expression is IRowFilter {
  return (
    expression.kind === IExpressionType.RelationalBooleanExpression ||
    expression.kind === IExpressionType.SetMembershipBooleanExpression ||
    expression.kind === IExpressionType.BinaryLogicalExpression
  );
}
function negateBooleanExpression(setMembershipBooleanExpression: ISetMembershipBooleanExpression): IBooleanExpression {
  return {
    kind: IExpressionType.RelationalBooleanExpression,
    left: setMembershipBooleanExpression,
    right: {
      kind: IExpressionType.LiteralExpression,
      value: true,
    },
    op: IRelationalOp.NotEquals,
  };
}
