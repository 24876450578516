import { QueryClient } from "react-query";

interface IReactQueryOptions {
  cacheTime?: number;
  staleTime?: number;
}

export const DEFAULT_QUERY_OPTIONS: IReactQueryOptions = {
  cacheTime: 30 * 60 * 1000, // 30 minutes
  staleTime: Infinity,
};

export const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...DEFAULT_QUERY_OPTIONS,
    },
  },
});
