//---------------------------------------------------------------
// Timestamp to date
//---------------------------------------------------------------

export enum IFunctionNames {
  TimestampToDate = "timestamp_to_date",
  GenerateTimeSeries = "generate_time_series",
  FillMissingValues = "fill_missing_values",
  MakeSubquery = "make_subquery",
}

export enum ITimestampToDateOptions {
  Days = "days",
  Weeks = "weeks",
  Months = "months",
  Quarters = "quarters",
  Years = "years",
}

export const TIME_SERIES_ALIAS: string = "timeseries";

export const TIME_FILLED_TABLE_ALIAS: string = "time_filled_table";

export const TIME_BUCKETS_COLUMN_ALIAS: string = "time_buckets";
