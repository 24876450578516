import { Identifier } from "react-admin";
import { Conversions } from "../Conversions";
import { IPlatformResource } from "../../types/resources";

export abstract class InsightsBaseConversions<T extends IPlatformResource> implements Conversions {
  public fromResponseMany(response: any): T[] {
    return (response.resources ?? []).map((resource: T) => {
      return this.fromResponse(resource);
    });
  }

  public fromResponse(response: T): T {
    return {
      ...response,
      id: response.resource_id,
    };
  }

  public fromCreateResponse(response: any): T {
    return this.fromResponse(response.resource_create);
  }

  public toRequest(resource: T): T {
    return resource;
  }

  public toUpdateRequest(id: Identifier, resource: Partial<T>): { resource_id: Identifier; payload: Partial<T> } {
    return {
      resource_id: id,
      payload: this.toRequestPartial(resource),
    };
  }

  public toCreateRequest(resource: Partial<T>): { payload: Partial<T> } {
    return {
      payload: this.toRequestPartial(resource),
    };
  }

  public toDeleteRequest(id: Identifier): { resource_id: Identifier } {
    return {
      resource_id: id,
    };
  }

  public toRequestPartial(resource: Partial<T>): Partial<T> {
    return resource;
  }
}
