import { Identifier } from "react-admin";
import { AllDash } from "../types";
import { IAccessInfo } from "./../types/accessInfo";
import { IPlatformResource } from "./../types/resources";
import { Conversions } from "./Conversions";

//-------------------------------------------------------------------------------
// Response Interfaces
//-------------------------------------------------------------------------------

export interface IDashboardsResponse {
  dashboards: IDashboardResponse[];
}

export interface IDashboardResponse extends IPlatformResource {
  created_at: string;
  dash_owner_uid: string;
  dash_reports: Identifier[];
  default_for_roles?: string[];
  dash_ui_ctx?: any;
  updated_at: string;
  access_info: IAccessInfo;
}

interface ICreateDashboardResponse {
  dash_create: IDashboardResponse;
}

//-------------------------------------------------------------------------------
// Request Interfaces
//-------------------------------------------------------------------------------

export interface ICreateDashboardRequest {
  payload: Partial<IDashboardResponse>;
}

export interface IUpdateDashboardRequest {
  resource_id: Identifier;
  payload: Partial<IDashboardResponse>;
}

interface IDeleteDashboardRequest {
  resource_id: Identifier;
}

//-------------------------------------------------------------------------------
// Conversions
//-------------------------------------------------------------------------------

export class DashboardConversions implements Conversions {
  public fromResponseMany(response: IDashboardsResponse): AllDash[] {
    return (response.dashboards ?? []).map((dashboard: IDashboardResponse) => {
      return this.fromResponse(dashboard);
    });
  }

  public fromResponse(response: IDashboardResponse): AllDash {
    return {
      id: response.resource_id,
      resource_name: response.resource_name,
      resource_description: response.resource_description,
      resource_id: response.resource_id,
      resource_type: response.resource_type,
      default_for_roles: response.default_for_roles,
      schema_version: response.schema_version,
      access_info: response.access_info,
      created_email: "",
      created_uid: response.dash_owner_uid,
      first_seen: response.created_at,
      updated_at: response.updated_at,
      created_at: response.created_at,
      tags: [],
      sales_id: response.dash_owner_uid,
      nb_notes: 0,
      default: false,
      reports: response.dash_reports,
      dashboardUIContext: response.dash_ui_ctx ?? { contentSizes: {} },
    };
  }

  public fromCreateResponse(response: ICreateDashboardResponse): AllDash {
    return this.fromResponse(response.dash_create);
  }

  public toRequest(dashboard: AllDash): Partial<IDashboardResponse> {
    return {
      created_at: dashboard.first_seen,
      resource_description: dashboard.resource_description,
      resource_id: dashboard.id.toString(),
      resource_name: dashboard.resource_name,
      dash_owner_uid: dashboard.created_uid.toString(),
      dash_reports: dashboard.reports.map((reportId: Identifier) => reportId.toString()),
      updated_at: dashboard.updated_at,
      default_for_roles: dashboard.default_for_roles,
    };
  }

  public toUpdateRequest(id: Identifier, dashboard: Partial<AllDash>): IUpdateDashboardRequest {
    return {
      resource_id: id,
      payload: this.toRequestPartial(dashboard),
    };
  }

  public toCreateRequest(dashboard: Partial<AllDash>): ICreateDashboardRequest {
    return {
      payload: this.toRequestPartial(dashboard),
    };
  }

  public toDeleteRequest(id: Identifier): IDeleteDashboardRequest {
    return {
      resource_id: id,
    };
  }

  private toRequestPartial(dashboard: Partial<AllDash>): Partial<IDashboardResponse> {
    return {
      ...(dashboard.id && { resource_id: dashboard.id.toString() }),
      ...(dashboard.resource_name && { resource_name: dashboard.resource_name }),
      ...(dashboard.resource_description && { resource_description: dashboard.resource_description }),
      ...(dashboard.reports && { dash_reports: dashboard.reports }),
      ...(dashboard.dashboardUIContext && { dash_ui_ctx: dashboard.dashboardUIContext }),
      ...(dashboard.default_for_roles && { default_for_roles: dashboard.default_for_roles }),
    };
  }
}
