import { Box } from "@mui/material";
import generatePicker from "antd/lib/date-picker/generatePicker";
import dayjs from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import React from "react";
import { useTranslate } from "react-admin";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { IBooleanExpression, IBooleanOp, IExpression, IRelationalBooleanExpression } from "../../../../conversions/ReportConversions";
import { IColumnDef, INumericalRange } from "../../../../types/object";
import { buildColumnIdentifierFromDef, buildRelationalBooleanExpression, combineBooleanExpressions } from "../../../../utils/expressionUtils";
import { FilterValueSelectorOptions } from "../FilterSelector";

const DatePicker = generatePicker(dayjsGenerateConfig);
const { RangePicker } = DatePicker;

interface IDateFilterInputProps {
  expression?: IExpression;
  column?: IColumnDef;
  filterValueSelectorOption: FilterValueSelectorOptions;
  initialValues: (string | number | boolean)[] | undefined;
}

export const DateFilterInput = (props: IDateFilterInputProps) => {
  const { column, expression, filterValueSelectorOption, initialValues } = props;

  const translate = useTranslate();

  const { reset, getValues } = useFormContext();

  const [value1, setValue1] = React.useState<dayjs.Dayjs | null>(getInitialValue(initialValues, 0));
  const [value2, setValue2] = React.useState<dayjs.Dayjs | null>(getInitialValue(initialValues, 1));

  React.useEffect(() => {
    if (column) {
      reset(buildFilterExpression(filterValueSelectorOption, buildColumnIdentifierFromDef(column), value1, value2));
    } else if (expression) {
      reset(buildFilterExpression(filterValueSelectorOption, expression, value1, value2));
    }
  }, [value1, value2, filterValueSelectorOption, expression, column]);

  const handleValueChange = React.useCallback(
    (value: dayjs.Dayjs | null) => {
      setValue1(value?.startOf("day") ?? null);
    },
    [filterValueSelectorOption]
  );

  const handleRangeChange = React.useCallback((rangeValues: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (rangeValues) {
      const [start, end] = rangeValues;
      setValue1(start?.startOf("day") ?? null);
      setValue2(end?.startOf("day") ?? null);
    } else {
      setValue1(null);
      setValue2(null);
    }
  }, []);

  const checkDisabledDate = React.useCallback(
    (value: dayjs.Dayjs) => {
      const numericalRange: INumericalRange | undefined = !!column?.filter_def?.numerical_range?.length ? column.filter_def.numerical_range[0] : undefined;
      const minDate: number = numericalRange?.start ?? -Infinity;
      const maxDate: number = numericalRange?.end ?? Infinity;
      return value.isBefore(minDate) || value.isAfter(maxDate);
    },
    [column]
  );

  return (
    <Box  display="flex" justifyContent="center" height="4.8rem">
      {filterValueSelectorOption !== FilterValueSelectorOptions.HAPPENS_BETWEEN ? (
        <StyledDatePicker disabledDate={checkDisabledDate} bordered={false} popupStyle={{ zIndex: "1301" }} value={value1} onChange={handleValueChange} />
      ) : (
        <RangePicker disabledDate={checkDisabledDate} bordered={false} popupStyle={{ zIndex: "1301" }} value={[value1, value2]} onChange={handleRangeChange} />
      )}
    </Box>
  );
};

const StyledDatePicker = styled(DatePicker)`
  display: flex;
  flex: 1 1 auto;
`;

function getInitialValue(initialValues: (string | number | boolean)[] | undefined, index: number): dayjs.Dayjs | null {
  if (initialValues && initialValues.length > index) {
    const value: string | number | boolean = initialValues[index];
    return typeof value === "number" || typeof value === "string" ? dayjs(value) : null;
  } else {
    return null;
  }
}

function buildFilterExpression(
  filterValueSelectorOption: FilterValueSelectorOptions,
  expression: IExpression,
  value1: dayjs.Dayjs | null,
  value2: dayjs.Dayjs | null
): IRelationalBooleanExpression | IBooleanExpression {
  if (filterValueSelectorOption === FilterValueSelectorOptions.HAPPENS_AFTER) {
    return buildRelationalBooleanExpression(expression, value1?.valueOf()!, true);
  } else if (filterValueSelectorOption === FilterValueSelectorOptions.HAPPENS_BEFORE) {
    return buildRelationalBooleanExpression(expression, value1?.valueOf()!, false);
  } else {
    return combineBooleanExpressions(
      [buildRelationalBooleanExpression(expression, value1?.valueOf()!, true), buildRelationalBooleanExpression(expression, value2?.valueOf()!, false)],
      IBooleanOp.And
    );
  }
}
