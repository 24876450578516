import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Stack, TextField, Typography, createFilterOptions } from "@mui/material";
import React from "react";
import { SearchableTextField } from "../../common/Common.styled";
import { IMenuItem } from "./SelectField";

interface IAutocompleteSelectFieldProps {
  options: IMenuItem[];
  selectedValue: IMenuItem | undefined | null;
  onSelectChange: (selected: string) => void;
  onClear?: () => void;

  // Whether the text input field will be rounded or square
  rounded?: boolean;
  // Whether to group by the field {IMenuItem.group}
  grouped?: boolean;
  size?: "small" | "medium";
  error?: boolean;
  disabled?: boolean;
  variant?: "filled" | "outlined" | "standard";
  placeholder?: string;
  startAdornment?: React.ReactNode;
  disableClearable?: boolean;
}

export const AutocompleteSelectField = (props: IAutocompleteSelectFieldProps) => {
  const {
    options,
    selectedValue,
    onSelectChange,
    rounded = true,
    grouped = false,
    size = "small",
    error = false,
    disabled = false,
    variant = "outlined",
    placeholder,
    startAdornment,
    disableClearable = true,
    onClear,
  } = props;

  const handleSelectChange = React.useCallback(
    (event: React.SyntheticEvent, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<IMenuItem> | undefined) => {
      if (reason === "selectOption" && details?.option) {
        onSelectChange(details.option.key);
      }
      if (reason === "clear" && onClear) {
        onClear();
      }
    },
    [onSelectChange, onClear]
  );

  return (
    <Autocomplete
      size={size}
      disabled={disabled}
      value={selectedValue as IMenuItem | undefined}
      fullWidth
      onChange={handleSelectChange}
      options={options}
      getOptionLabel={(option: IMenuItem) => option.name}
      groupBy={grouped ? (option: IMenuItem) => option.group ?? "" : undefined}
      isOptionEqualToValue={(option: IMenuItem, value: IMenuItem) => option?.key === value?.key}
      disableClearable={disableClearable}
      filterOptions={filterOptions}
      renderOption={(props, option: IMenuItem) => {
        return (
          <li {...props} key={option.key}>
            <Stack direction="column">
              <Typography variant="body1">{option.name}</Typography>
              {option.description && <Typography variant="body2">{option.description}</Typography>}
            </Stack>
          </li>
        );
      }}
      renderInput={(params) =>
        rounded ? (
          <SearchableTextField
            {...params}
            variant={variant}
            error={error}
            placeholder={placeholder}
            InputProps={{ ...params.InputProps, ...(startAdornment && { startAdornment: startAdornment }) }}
          />
        ) : (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, ...(startAdornment && { startAdornment: startAdornment }) }}
            variant={variant}
            error={error}
            placeholder={placeholder}
          />
        )
      }
    />
  );
};

const filterOptions = createFilterOptions({
  stringify: (option: IMenuItem) => option.name + option.key + option.description,
});
