//---------------------------------------------------------------
// Time Range
//---------------------------------------------------------------

export const TIME_RANGE_START_PARAMETER_NAME: string = "_auto_parameter_time_range_start_";

export const TIME_RANGE_END_PARAMETER_NAME: string = "_auto_parameter_time_range_end_";

//---------------------------------------------------------------
// User
//---------------------------------------------------------------

export const FINDEM_USER_ID_PARAMETER_NAME: string = "_auto_parameter_findem_user_id_";

export const ATS_USER_ID_PARAMETER_NAME: string = "_auto_parameter_ats_user_id_";

//---------------------------------------------------------------
// All Names
//---------------------------------------------------------------

export const AUTO_PARAMETER_NAMES: string[] = [TIME_RANGE_START_PARAMETER_NAME, TIME_RANGE_END_PARAMETER_NAME, FINDEM_USER_ID_PARAMETER_NAME, ATS_USER_ID_PARAMETER_NAME];
