import { IStudyRequest } from "../../insights/insightsTypes";
import { InsightsBaseConversions } from "./InsightsBaseConversions";

export class StudyRequestConversions extends InsightsBaseConversions<IStudyRequest> {
  public fromResponseMany(response: { study_requests: IStudyRequest[] }): IStudyRequest[] {
    return (response.study_requests ?? []).map((resource: IStudyRequest) => {
      return this.fromResponse(resource);
    });
  }

  public fromResponse(response: IStudyRequest): IStudyRequest {
    return {
      ...response,
      id: response.resource_id,
    };
  }

  public toRequestPartial(request: Partial<IStudyRequest>): Partial<IStudyRequest> {
    return {
      ...request,
      id: request.resource_id,
    };
  }

  public fromCreateResponse(response: { study_request_create: IStudyRequest }): IStudyRequest {
    return this.fromResponse(response.study_request_create);
  }
}
