import { createTheme, Theme, ThemeOptions } from "@mui/material";
import { colors } from "..";

export const PLATFORM_THEME_OPTIONS: ThemeOptions = {
  typography: {
    fontSize: 20,
    h1: {
      color: colors.black,
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "25px",
    },
    h4: {
      color: colors["grey-700"],
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "25px",
    },
    h5: {
      color: colors["grey-700"],
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "25.6px",
    },
    subtitle1: {
      color: colors["grey-700"],
      fontSize: "14px",
      fontWeight: 500,
    },
    subtitle2: {
      color: colors["grey-600"],
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "14px",
    },
    body1: {
      color: colors["grey-600"],
      fontSize: "14px",
      fontWeight: 400,
    },
    body2: {
      color: colors["grey-500"],
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    button: {
      color: colors.white,
      fontSize: "14px",
      textTransform: "none",
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: colors["blue-300"],
      contrastText: colors.white,
    },
    secondary: {
      main: colors["grey-700"],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "13px",
        },
      },
    },
  },
};

export const PLATFORM_THEME: Theme = createTheme(PLATFORM_THEME_OPTIONS);
