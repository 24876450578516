import { Identifier } from "react-admin";
import { AllDash } from "../types";
import { IBasePlatformRole, IPlatformRole } from "./../types/settings";
import { Conversions } from "./Conversions";

//-------------------------------------------------------------------------------
// Response Interfaces
//-------------------------------------------------------------------------------

export interface IGetPlatformRolesResponse {
  platform_roles: IBasePlatformRole[];
}

export interface ICreatePlatformRoleResponse {
  role_create: IBasePlatformRole;
}

//-------------------------------------------------------------------------------
// Request Interfaces
//-------------------------------------------------------------------------------

export interface ICreatePlatformRoleRequest {
  payload: Partial<IBasePlatformRole>;
}

export interface IUpdatePlatformRoleRequest {
  resource_id: Identifier;
  payload: Partial<IPlatformRole>;
}

interface IDeletePlatformRoleRequest {
  resource_id: Identifier;
}

//-------------------------------------------------------------------------------
// Conversions
//-------------------------------------------------------------------------------

export class PlatformRoleConversions implements Conversions {
  public fromResponseMany(response: IGetPlatformRolesResponse): IPlatformRole[] {
    return (response.platform_roles ?? []).map((platformRole: IBasePlatformRole) => {
      return this.fromResponse(platformRole);
    });
  }

  public fromResponse(response: IBasePlatformRole): IPlatformRole {
    return {
      ...response,
      id: response.resource_id,
    };
  }

  public fromCreateResponse(response: ICreatePlatformRoleResponse): IPlatformRole {
    return this.fromResponse(response.role_create);
  }

  public toRequest(platformRole: IPlatformRole): IBasePlatformRole {
    return platformRole;
  }

  public toUpdateRequest(id: Identifier, role: Partial<IPlatformRole>): IUpdatePlatformRoleRequest {
    return {
      resource_id: id,
      payload: this.toRequestPartial(role),
    };
  }

  public toCreateRequest(platformRole: Partial<IPlatformRole>): ICreatePlatformRoleRequest {
    return {
      payload: this.toRequestPartial(platformRole),
    };
  }

  public toDeleteRequest(id: Identifier): IDeletePlatformRoleRequest {
    return {
      resource_id: id,
    };
  }

  private toRequestPartial(platformRole: Partial<IPlatformRole>): Partial<IPlatformRole> {
    return platformRole;
  }
}
