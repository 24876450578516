import { ScopedCssBaseline, Theme, ThemeProvider, createTheme } from "@mui/material";
import { merge } from "lodash";
import { ReactNode, useContext, useEffect, useMemo } from "react";
import { I18nContextProvider } from "react-admin";
import { QueryClientProvider, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { USER_IMPERSONATION_QUERY_PARAM, invalidateQueries } from "../components/UserImpersonation/UserImpersonationSwitcher";
import { PLATFORM_THEME, PLATFORM_THEME_OPTIONS } from "../constants/themes/platformTheme";
import LoggedInUserContext from "../context/loggedInUserContext";
import UserContext from "../context/userContext";
import analyticsDataProviderInternal from "../dataProvider/analyticsDataProviderInternal";
import { IGNORE_IMPERSONATION_FLAG } from "../dataProvider/customDataProvider";
import i18nProvider from "../i18n/i18nProvider";
import { DEFAULT_QUERY_OPTIONS, queryClient } from "../queryClient";
import { IEmbedMode } from "../types/embed";
import { Resources } from "../types/resources";
import { IPlatformUser } from "../types/settings";
import { ICurrentUser } from "../types/users";
import { isStaticEmbedMode } from "../utils/embedUtils";

interface IEmbeddedAppProvidersProps {
  embedMode: IEmbedMode;
  children: ReactNode;
}

export const EmbeddedAppProviders = (props: IEmbeddedAppProvidersProps) => {
  const { embedMode, children } = props;

  const isStaticEmbed: boolean = useMemo(() => {
    return !!embedMode && isStaticEmbedMode(embedMode);
  }, [embedMode]);

  const theme: Theme = useMemo(() => {
    return embedMode.themeOverrides ? createTheme(merge({ ...PLATFORM_THEME_OPTIONS }, embedMode.themeOverrides)) : PLATFORM_THEME;
  }, [embedMode]);

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <QueryClientProvider client={queryClient}>
          <I18nContextProvider value={i18nProvider}>
            {isStaticEmbed ? children : <IEmbeddedAppProvidersImpersonationProvider>{children}</IEmbeddedAppProvidersImpersonationProvider>}
          </I18nContextProvider>
        </QueryClientProvider>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
};

interface IEmbeddedAppImpersonationProviderProps {
  children: ReactNode;
}

export const IEmbeddedAppProvidersImpersonationProvider = (props: IEmbeddedAppImpersonationProviderProps) => {
  const { children } = props;

  const [queryParams] = useSearchParams();
  const targetUserId: string | null = queryParams.get(USER_IMPERSONATION_QUERY_PARAM) ?? queryParams.get("joid");

  const { setUser } = useContext(UserContext);
  const loggedInUser: ICurrentUser = useContext(LoggedInUserContext);

  const { data: platformUsers, isLoading: isPlatformUsersLoading } = useQuery({
    ...DEFAULT_QUERY_OPTIONS,
    queryKey: [`platform-users-list`],
    queryFn: () => analyticsDataProviderInternal.getList<IPlatformUser>(Resources.PLATFORM_USERS, { meta: { [IGNORE_IMPERSONATION_FLAG]: true } }),
  });

  const selectedUser: IPlatformUser | undefined = useMemo(() => {
    return (platformUsers ?? []).find((platformUser: IPlatformUser) => platformUser.resource_id === targetUserId);
  }, [targetUserId, platformUsers]);

  useEffect(() => {
    if (selectedUser) {
      setUser({
        uid: selectedUser.resource_id,
        account_id: selectedUser.account_id,
        email: selectedUser.local.email,
        name: selectedUser.resource_name,
        roles: selectedUser.roles,
        company: selectedUser.local.company_name,
        domain: "",
        prid: "",
        account_creation: "",
      });
      invalidateQueries(queryClient);
    } else if (!isPlatformUsersLoading) {
      setUser(loggedInUser);
    }
  }, [selectedUser?.resource_id, loggedInUser, isPlatformUsersLoading]);

  return <>{children}</>;
};
