export enum ChartType {
  Bar = "Bar",
  NewBar = "NewBar",
  Column = "Column",
  Line = "Line",
  Pie = "Pie",
  NewPie = "NewPie",
  Table = "Table",
  Summary = "Summary",
  SparklineSummary = "Sparkline Summary",
  Funnel = "Funnel",
  FunnelTable = "Funnel Table",
  TreeMap = "TreeMap",
  RequirementsSummary = "RequirementsSummary",
}

export interface IChartDataPoint {
  name: string | number;
  [id: string]: number | string;
}
