import { Identifier } from "react-admin";
import { IColumnDef, IColumnFilterDef, IColumnOwnerType, IColumnValueType, IObjectDef } from "../types/object";
import { IColumnEntry } from "../types/report";
import { IAccessInfo } from "./../types/accessInfo";
import { IQualifier } from "./../types/object";
import { IBasePlatformResource } from "./../types/resources";
import { Conversions } from "./Conversions";
import { IExpression } from "./ReportConversions";

//-------------------------------------------------------------------------------
// Response Interfaces
//-------------------------------------------------------------------------------

interface IObjectsResponse {
  objects: IObjectResponse[];
}

export interface IObjectResponse extends IBasePlatformResource {
  version: number; // version number

  // Extensible object definition for each app.
  // Each App - can create its own object definition.
  app_id?: string;
  app_ver?: string;

  // Indicating the template is system template.
  system_template: boolean;
  org_template?: boolean;

  access_info: IAccessInfo;

  uid: string; // User identifier creating the object definition.
  acc_id: string; // Account Identifier.

  // Base object identifier.
  base_obj_id?: string; // In case of extended schema.
  base_collection: string;

  // Default columns.
  default_cols: IColumnDefResponse[];

  // Extensions can be user defined or specific app installed.
  ext_cols?: IColumnDefResponse[];

  updated_at: string;
  base_obj_filters?: any;
}

export interface IColumnDefResponse {
  col_id: string;
  col_name: string;

  ext_link?: IColumnEntry;
  // If there is no app_id or uid override for the column, object entity owner
  // will the person responsible.
  // User identifier who updated the column.
  uid?: string;

  // Account identifier.
  acc_id?: string;

  // Application that added this column.
  app_id?: string;

  // Hidden column.
  hidden?: boolean;

  // System created.
  col_owner_type: IColumnOwnerType;

  // Definition of value.
  value_def: IColumnValueDefResponse;

  // Filtering definitions
  filter_def: IColumnFilterDef;
}

export interface IColumnValueDefResponse {
  // Column value data type.
  col_value_type: IColumnValueType;
  col_bucket_id: string;

  // In case of virtual columns, col_formula specifies formula to derive the column
  col_formula?: IExpression;

  // Aggregations or some other forumla if any over the bucket identifier.
  formula?: string;

  // Filter/Qualifier for selecting the bucket value.
  qualifier?: IQualifier;
}

export interface ICreateObjectResponse {
  plat_obj_create: IObjectResponse;
}

export interface IUpdateObjectResponse {
  plat_obj_update: IObjectResponse;
}

//-------------------------------------------------------------------------------
// Request Interfaces
//-------------------------------------------------------------------------------

export interface ICreateObjectRequest {
  payload: Partial<IObjectResponse>;
}

export interface IUpdateObjectRequest {
  resource_id: Identifier;
  payload: Partial<IObjectResponse>;
}

interface IDeleteObjectRequest {
  resource_id: Identifier;
}

//-------------------------------------------------------------------------------
// Conversions
//-------------------------------------------------------------------------------

export class ObjectConversions implements Conversions {
  public fromResponseMany(response: IObjectsResponse): IObjectDef[] {
    return (response?.objects ?? []).map((object: IObjectResponse) => this.fromResponse(object));
  }

  public fromResponse(response: IObjectResponse): IObjectDef {
    return {
      id: response.resource_id,
      ...response,
      default_cols: response.default_cols.map((colResponse: IColumnDefResponse) => toColDef(response.resource_id, colResponse)),
      ext_cols: (response.ext_cols ?? []).map((colResponse: IColumnDefResponse) => toColDef(response.resource_id, colResponse)),
    };
  }

  public fromCreateResponse(response: ICreateObjectResponse): IObjectDef {
    return this.fromResponse(response.plat_obj_create);
  }

  public toRequest(object: IObjectDef): IObjectResponse {
    return toRequest(object);
  }

  public toUpdateRequest(id: Identifier, object: Partial<IObjectDef>): IUpdateObjectRequest {
    return {
      resource_id: id,
      payload: toRequestPartial(object),
    };
  }

  public toCreateRequest(object: IObjectDef): ICreateObjectRequest {
    return {
      payload: toRequest(object),
    };
  }

  public toDeleteRequest(id: Identifier): IDeleteObjectRequest {
    return {
      resource_id: id,
    };
  }
}

function toRequest(object: IObjectDef): IObjectResponse {
  return {
    ...object,
    default_cols: (object.default_cols ?? []).map((col: IColumnDef) => toColDefResponse(col)),
    ext_cols: (object.ext_cols ?? []).map((col: IColumnDef) => toColDefResponse(col)),
  };
}

function toRequestPartial(object: Partial<IObjectDef>): Partial<IObjectResponse> {
  return {
    ...(object.resource_id && { resource_id: object.resource_id }),
    ...(object.version && { version: object.version }),
    ...(object.resource_name && { resource_name: object.resource_name }),
    ...(object.resource_description && { resource_description: object.resource_description }),
    ...(object.app_id && { app_id: object.app_id }),
    ...(object.app_ver && { app_ver: object.app_ver }),
    ...(object.uid && { uid: object.uid }),
    ...(object.acc_id && { acc_id: object.acc_id }),
    ...(object.base_obj_id && { base_obj_id: object.base_obj_id }),
    ...(object.base_collection && { base_collection: object.base_collection }),
    ...(object.default_cols && { default_cols: object.default_cols.map((col: IColumnDef) => toColDefResponse(col)) }),
    ...(object.ext_cols && { ext_cols: object.ext_cols.map((col: IColumnDef) => toColDefResponse(col)) }),
    ...(object.updated_at && { updated_at: object.updated_at }),
  };
}

function toColDef(objectId: string, colResponse: IColumnDefResponse): IColumnDef {
  return {
    ...colResponse,
    obj_id: objectId,
  };
}

function toColDefResponse(colDef: IColumnDef): IColumnDefResponse {
  return {
    ...colDef,
    value_def: {
      col_value_type: colDef.value_def.col_value_type,
      col_bucket_id: colDef.value_def.col_bucket_id,
      ...(colDef.value_def.formula && { formula: colDef.value_def.formula }),
      ...(colDef.value_def.qualifier && { qualifier: colDef.value_def.qualifier }),
      ...(colDef.value_def.col_formula && { col_formula: colDef.value_def.col_formula }),
    },
  };
}
