import { ATS_USER_ID_PARAMETER_NAME, FINDEM_USER_ID_PARAMETER_NAME, TIME_RANGE_END_PARAMETER_NAME, TIME_RANGE_START_PARAMETER_NAME } from "../constants/parameterization";
import {
  IAliasedExpression,
  IBooleanOp,
  IExpression,
  IExpressionType,
  IParameterizedArrayExpression,
  IParameterizedExpression,
  IRelationalOp,
} from "../conversions/ReportConversions";
import { IColumnDef, IColumnValueType } from "../types/object";
import { IParameterizationInfo, IParameterizationType } from "../types/parameterization";
import { IReportQuery, IRowFilter } from "../types/report";
import { buildColumnIdentifierFromDef } from "./expressionUtils";

export function getReportQueryParameterizationInfo(reportQuery: IReportQuery): IParameterizationInfo[] {
  return Object.values(
    [...(reportQuery?.rowFilters ?? []), ...(reportQuery?.projections ?? [])].reduce((acc: Record<string, IParameterizationInfo>, expression: IExpression) => {
      getParameterizedExpressionInfo(expression).forEach((paramInfo: IParameterizationInfo) => {
        if (!acc[paramInfo.parameterName]) {
          acc[paramInfo.parameterName] = paramInfo;
        }
      });
      return acc;
    }, {})
  );
}

export function getParameterizedExpressionInfo(expression: IExpression): IParameterizationInfo[] {
  const parmeterizationInfo: IParameterizationInfo[] = [];

  switch (expression.kind) {
    case IExpressionType.RelationalBooleanExpression:
      if (expression.left.kind === IExpressionType.ParameterizedExpression) {
        parmeterizationInfo.push({ parameterName: expression.left.parameter_name, expression: expression.right });
      }

      if (expression.right.kind === IExpressionType.ParameterizedExpression) {
        parmeterizationInfo.push({ parameterName: expression.right.parameter_name, expression: expression.left });
      }
      break;
    case IExpressionType.SetMembershipBooleanExpression:
      if (!Array.isArray(expression.values)) {
        parmeterizationInfo.push({ parameterName: expression.values.parameter_name, expression: expression.expr });
      }
      break;
    case IExpressionType.BinaryLogicalExpression:
      parmeterizationInfo.push(...getParameterizedExpressionInfo(expression.left));
      parmeterizationInfo.push(...getParameterizedExpressionInfo(expression.right));
      break;
    case IExpressionType.AliasedExpression:
    case IExpressionType.AggregateExpression:
      parmeterizationInfo.push(...getParameterizedExpressionInfo(expression.expr));
      break;
    case IExpressionType.ParameterizedExpression:
    case IExpressionType.ParameterizedArrayExpression:
      parmeterizationInfo.push({ parameterName: expression.parameter_name, expression: expression.default_parameter_value });
      break;
    case IExpressionType.FunctionExpression:
      parmeterizationInfo.push(...expression.operands.flatMap((operand: IExpression) => getParameterizedExpressionInfo(operand)));
      break;
    default:
      break;
  }

  return parmeterizationInfo;
}

export function getParameterizationTypeTranslationKey(paramType: IParameterizationType): string {
  switch (paramType) {
    case IParameterizationType.Column:
      return "parameterization.type.column";
    case IParameterizationType.DerivedColumn:
      return "parameterization.type.derivedColumn";
    case IParameterizationType.AtsUserId:
      return "parameterization.type.ats";
    case IParameterizationType.FindemUserId:
      return "parameterization.type.findem";
    case IParameterizationType.Time:
      return "parameterization.type.time";
    default:
      throw Error(`Unimplementated paramType ${paramType}`);
  }
}

export function getParameterizationNameTranslationKey(paramName: string): string {
  switch (paramName) {
    case TIME_RANGE_START_PARAMETER_NAME:
      return "parameterization.name.timeStart";
    case TIME_RANGE_END_PARAMETER_NAME:
      return "parameterization.name.timeEnd";
    case FINDEM_USER_ID_PARAMETER_NAME:
      return "parameterization.type.findem";
    case ATS_USER_ID_PARAMETER_NAME:
      return "parameterization.type.ats";
    default:
      throw Error(`Unimplementated paramName ${paramName}`);
  }
}

// @TODO: Pass in translate & use localization
export function getParameterizationNameDisplayName(paramName: string): string {
  switch (paramName) {
    case TIME_RANGE_START_PARAMETER_NAME:
      return "Time Filter Start";
    case TIME_RANGE_END_PARAMETER_NAME:
      return "Time Filter End";
    case FINDEM_USER_ID_PARAMETER_NAME:
      return "Findem User ID";
    case ATS_USER_ID_PARAMETER_NAME:
      return "ATS User ID";
    default:
      throw Error(`Unimplementated paramName ${paramName}`);
  }
}

export function getParameterizationTypeExpectedValueType(paramType: IParameterizationType): IColumnValueType | undefined {
  switch (paramType) {
    case IParameterizationType.Column:
    case IParameterizationType.DerivedColumn:
      return undefined;
    case IParameterizationType.AtsUserId:
    case IParameterizationType.FindemUserId:
      return IColumnValueType.String;
    case IParameterizationType.Time:
      return IColumnValueType.Date;
    default:
      throw Error(`Unimplementated paramType ${paramType}`);
  }
}

export function buildExpressionParameterizedRowFilter(expression: IAliasedExpression, expressionNameLookup: Record<string, string>): IRowFilter {
  return {
    kind: IExpressionType.SetMembershipBooleanExpression,
    expr: expression.expr,
    values: buildExpressionParameterizedExpression(expression, expressionNameLookup),
  };
}

export function buildParameterizedRowFilter(paramType: IParameterizationType, column: IColumnDef): IRowFilter {
  switch (paramType) {
    case IParameterizationType.Column:
      return {
        kind: IExpressionType.SetMembershipBooleanExpression,
        expr: buildColumnIdentifierFromDef(column),
        values: buildColumnDefParameterizedExpression(column),
      };
    case IParameterizationType.AtsUserId:
      return {
        kind: IExpressionType.SetMembershipBooleanExpression,
        expr: buildColumnIdentifierFromDef(column),
        values: buildAtsUserIdParameterizedExpression(),
      };
    case IParameterizationType.FindemUserId:
      return {
        kind: IExpressionType.SetMembershipBooleanExpression,
        expr: buildColumnIdentifierFromDef(column),
        values: buildFindemUserIdParameterizedExpression(),
      };
    case IParameterizationType.Time:
      return {
        kind: IExpressionType.BinaryLogicalExpression,
        op: IBooleanOp.And,
        left: {
          kind: IExpressionType.RelationalBooleanExpression,
          op: IRelationalOp.GreaterThanOrEquals,
          left: buildColumnIdentifierFromDef(column),
          right: buildTimeRangeStartParameterizedExpression(),
        },
        right: {
          kind: IExpressionType.RelationalBooleanExpression,
          op: IRelationalOp.LessThanOrEquals,
          left: buildColumnIdentifierFromDef(column),
          right: buildTimeRangeEndParameterizedExpression(),
        },
      };
    default:
      throw Error(`Unimplementated paramType ${paramType}`);
  }
}

export function buildAutoParameterizedExpression(parameterName: string): IParameterizedArrayExpression | IParameterizedExpression {
  switch (parameterName) {
    case ATS_USER_ID_PARAMETER_NAME:
      return buildAtsUserIdParameterizedExpression();
    case FINDEM_USER_ID_PARAMETER_NAME:
      return buildFindemUserIdParameterizedExpression();
    case TIME_RANGE_START_PARAMETER_NAME:
      return buildTimeRangeStartParameterizedExpression();
    case TIME_RANGE_END_PARAMETER_NAME:
      return buildTimeRangeEndParameterizedExpression();
    default:
      throw Error(`Unknown parameter name ${parameterName}`);
  }
}

export function buildColumnDefParameterName(column: IColumnDef): string {
  return column.col_name;
}

export function buildExpressionParameterizedExpression(expression: IAliasedExpression, expressionNameLookup: Record<string, string>): IParameterizedArrayExpression {
  return {
    kind: IExpressionType.ParameterizedArrayExpression,
    parameter_name: expressionNameLookup[expression.alias!] ?? expression.alias,
    default_parameter_value: {
      kind: IExpressionType.LiteralArrayExpression,
      values: [],
    },
  };
}

export function buildColumnDefParameterizedExpression(column: IColumnDef): IParameterizedArrayExpression {
  return {
    kind: IExpressionType.ParameterizedArrayExpression,
    parameter_name: buildColumnDefParameterName(column),
    default_parameter_value: {
      kind: IExpressionType.LiteralArrayExpression,
      values: [],
    },
  };
}

export function buildAtsUserIdParameterizedExpression(): IParameterizedArrayExpression {
  return {
    kind: IExpressionType.ParameterizedArrayExpression,
    parameter_name: ATS_USER_ID_PARAMETER_NAME,
    default_parameter_value: {
      kind: IExpressionType.LiteralArrayExpression,
      values: [],
    },
  };
}

export function buildFindemUserIdParameterizedExpression(): IParameterizedArrayExpression {
  return {
    kind: IExpressionType.ParameterizedArrayExpression,
    parameter_name: FINDEM_USER_ID_PARAMETER_NAME,
    default_parameter_value: {
      kind: IExpressionType.LiteralArrayExpression,
      values: [],
    },
  };
}

export function buildTimeRangeStartParameterizedExpression(): IParameterizedExpression {
  const start = new Date(Date.now());
  start.setMonth(0);
  start.setDate(1);
  start.setUTCHours(0, 0, 0, 0);

  return {
    kind: IExpressionType.ParameterizedExpression,
    parameter_name: TIME_RANGE_START_PARAMETER_NAME,
    default_parameter_value: {
      kind: IExpressionType.LiteralExpression,
      value: start.valueOf(),
    },
  };
}

export function buildTimeRangeEndParameterizedExpression(): IParameterizedExpression {
  const now = Date.now();
  return {
    kind: IExpressionType.ParameterizedExpression,
    parameter_name: TIME_RANGE_END_PARAMETER_NAME,
    default_parameter_value: {
      kind: IExpressionType.LiteralExpression,
      value: now,
    },
  };
}

export function getParameterizationInfoFromReportQuery(reportQuery?: IReportQuery): IParameterizationInfo[] {
  return Object.values(
    [...(reportQuery?.rowFilters ?? []), ...(reportQuery?.projections ?? [])].reduce((acc: Record<string, IParameterizationInfo>, expression: IExpression) => {
      getParameterizedExpressionInfo(expression).forEach((paramInfo: IParameterizationInfo) => {
        if (!acc[paramInfo.parameterName]) {
          acc[paramInfo.parameterName] = paramInfo;
        }
      });
      return acc;
    }, {})
  );
}
