import polyglotI18nProvider from "ra-i18n-polyglot";
import { resolveBrowserLocale } from "react-admin";
import en from "./en";

const messages: Record<string, any> = {
  en: en,
};

const i18nProvider = polyglotI18nProvider((locale: string) => {
  return messages[locale] ? messages[locale] : messages.en;
}, resolveBrowserLocale());

export default i18nProvider;
