import { Identifier, RaRecord } from "react-admin";
import {
  IAliasedExpression,
  IBinaryLogicalExpression,
  IBooleanOp,
  IExpression,
  IRelation,
  IRelationalBooleanExpression,
  IReportAnalysisResult,
  ISetMembershipBooleanExpression,
  ISortExpression,
  ISortOrder,
} from "../conversions/ReportConversions";
import { IAliasedTableFunctionRelation } from "./../conversions/ReportConversions";
import { ChartType } from "./chart";
import { IPlatformResource } from "./resources";

//---------------------------------------------------------------------
// Report
//---------------------------------------------------------------------

export interface Report extends RaRecord, IBaseReport, IPlatformResource {
  ownerId: string;

  // Metadata
  tags: Identifier[];

  report_ui_ctx?: IReportUIContext;

  analysis_result?: IReportAnalysisResult;
}

export interface IBaseReport {
  resource_name: string;
  resource_description?: string;
  report_ui_ctx?: IReportUIContext;
  query: IReportQuery;
}

export interface IColumnEntry {
  obj_id: string;
  col_id: string;
}

//---------------------------------------------------------------------
// UI Context
//---------------------------------------------------------------------

export interface IReportUIContext {
  icon?: string;
  chartType?: ChartType;
  aggregationType?: ReportAggregationType;
  booleanFilter?: string;
  chartSettings?: ChartSettings;
  linkSettings?: ILinkSettings;
  sortExpression?: ISortExpression;
  columnSettings?: Record<string, IColumnSettings>;
}

export interface IColumnSettings {
  columnLink?: string;
  actions?: IColumnActions;
  hide?: boolean;
  hideOnAction?: boolean;
  format?: IColumnFormat;
  displayName?: string;
}

export enum IColumnFormat {
  None = "None",
  Percent = "Percent",
  Fraction = "Fraction",
  Date = "Date",
}

export interface IColumnActions {
  filterReportAction?: boolean;
}

export interface ILinkSettings {
  linkUrl?: string;
  linkText?: string;
}

export interface IChartSettings {
  numericAxis?: INumericAxisType;
  comparatorProjections?: INumericAxisType[];
  primaryGroupAxis?: IAliasedExpression;
  breakdownByColumns?: boolean;
  onlyAggregates?: boolean;
  hideSubtotals?: boolean;
  renderAsPercentage?: boolean;
  hundredPercentStacked?: boolean; // @deprecated -- use IBarChartSettings
  trafficLightingRules?: ITrafficLightingRule[];
  referenceLines?: IReferenceLine[];
  units?: string;
  nonStacked?: boolean; // @deprecated -- use IBarChartSettings
  roundedBars?: boolean; // @deprecated -- use IBarChartSettings
  colorOverrides?: string[];
  numDecimals?: number;
  maxGroups?: number;
  sortOrder?: ISortOrder;
  sortOn?: ISortOn;
  includeOther?: string;
  seriesColorMappings?: ISeriesColorMapping[];
}

export enum ISortOn {
  Value = "Value",
  Label = "Label",
}

export interface ISeriesColorMapping {
  seriesName: string;
  color: string;
}

export type ChartSettings = IChartSettingsDefault | ISparklineChartSettings | IBarChartSettings | IPieChartSettings;

export interface IChartSettingsDefault extends IChartSettings {
  type?: Exclude<ChartType, ChartType.SparklineSummary | ChartType.Bar | ChartType.Column>;
}

export interface ISparklineChartSettings extends IChartSettings {
  type: ChartType.SparklineSummary;
  hideSummary?: boolean;
}

export interface IBarChartSettings extends IChartSettings {
  type: ChartType.Bar | ChartType.Column;
  hundredPercentStacked?: boolean;
  nonStacked?: boolean;
  roundedBars?: boolean; 
}

export interface IPieChartSettings extends IChartSettings {
  type: ChartType.Pie | ChartType.NewPie;
  showTotal?: boolean;
  showPercentages?: boolean;
}


export type INumericAxisType = IRecordCountNumericAxis | IProjectionNumericAxis;

export enum NumericAxisType {
  RecordCount = "record_count",
  Projection = "projection",
}

export interface IRecordCountNumericAxis {
  kind: NumericAxisType.RecordCount;
}

export interface IProjectionNumericAxis {
  kind: NumericAxisType.Projection;
  projection: IAliasedExpression;
}

export interface IGroupedTrafficLightingRules {
  displayName: string;
  rules: ITrafficLightingRule[];
}

export interface ITrafficLightingRule {
  threshold: IThreshold;
  color: ITrafficLightingColor;
  targetColumnAlias?: string;
  targetColumnDisplayName?: string;
}

export interface IThreshold {
  type: IThresholdType;
  value1: number;
  value2?: number;
}

export interface ITrafficLightReferenceAreaBounds {
  y1?: number;
  y2?: number;
  fill: string;
}

export enum ITrafficLightingColor {
  Red = "red",
  Yellow = "yellow",
  Green = "green",
}

export enum IThresholdType {
  LessThan = "Less than",
  LessThanOrEqualTo = "Less than or equal to",
  GreaterThan = "Greater than",
  GreaterThanOrEqualTo = "Greater than or equal to",
  Between = "Between",
}

export interface IReferenceLine {
  displayName: string;
  value: number;
  color: IChartColor;
}

export enum IChartColor {
  DarkBlue = "dark_blue",
  LightBlue = "light_blue",
  Purple = "purple",
  Cyan = "cyan",
  LightPurple = "light_purple",
  Green = "green",
  Red = "red",
  Yellow = "yellow",
  Grey = "grey",
}

//---------------------------------------------------------------------
// Expression Editors
//---------------------------------------------------------------------

export interface IDerivedColumnInitialInputs {
  alias: string;
  formulaUsageType: ExpressionUsageType;
  index: number;
}

export function instanceOfIInlineExpressionInitialInputs(initialInputs: IDerivedColumnInitialInputs): initialInputs is IInlineExpressionInitialInputs {
  return "inlineExpressionValue" in initialInputs;
}

export interface IInlineExpressionInitialInputs extends IDerivedColumnInitialInputs {
  inlineExpressionValue: string;
}

export function instanceOfIFunctionExpressionInitialInputs(initialInputs: IDerivedColumnInitialInputs): initialInputs is IFunctionExpressionInitialInputs {
  return "functionId" in initialInputs && "functionParams" in initialInputs;
}

export interface IFunctionExpressionInitialInputs extends IDerivedColumnInitialInputs {
  functionId: string;
  functionParams: IExpression[];
}

export function instanceOfITableFunctionExpressionInitialInputs(initialInputs: IDerivedColumnInitialInputs): initialInputs is ITableFunctionExpressionInitialInputs {
  return "relation" in initialInputs;
}

export interface ITableFunctionExpressionInitialInputs extends IDerivedColumnInitialInputs {
  relation: IAliasedTableFunctionRelation;
}

export enum ExpressionUsageType {
  COLUMN = "column",
  ROW_GROUPING = "row_grouping",
  COLUMN_GROUPING = "column_grouping",
}

//---------------------------------------------------------------------
// Aggregation Type
//---------------------------------------------------------------------

export enum ReportAggregationType {
  COUNT = "count",
  SUM = "sum",
  AVG = "avg",
  MIN = "min",
  MAX = "max",
}

//---------------------------------------------------------------------
// Query
//---------------------------------------------------------------------

export interface IReportQuery {
  namespace: string;
  objects: IRelation;
  projections: IAliasedExpression[];
  row_grouping: IAliasedExpression[];
  col_grouping?: IAliasedExpression[];
  row_ordering?: ISortExpression[];

  distinct_rows?: boolean;
  options?: IReportQueryOptions;
  advanced_options?: IAdvancedReportQueryOptions;

  // Divergent from BE
  indexFilters?: IICPRequirement[];
  rowFilters: IRowFilter[];
  booleanLogic: string;
}

export interface IReportQueryOptions {
  exclude_rollups?: boolean;
}

export interface IAdvancedReportQueryOptions {}

//---------------------------------------------------------------------
// Joins
//---------------------------------------------------------------------

export interface ITableJoin {
  left: IColumnEntry;
  right: IColumnEntry;
}

//---------------------------------------------------------------------
// Filters
//---------------------------------------------------------------------

export type IRowFilter = IRelationalBooleanExpression | ISetMembershipBooleanExpression | IBinaryLogicalExpression;

export interface IIndexFilter {
  op: IBooleanOp;
  keywords: string[];
  macros: string[];
  iicp_requirements?: IIndexFilter;
}

export interface IICPRequirement {
  // req_type?: string; // 'MH' or 'NTH' -> Must have or Nice To Have.(current)
  // is_negative?: boolean; // Deprecated. Use ! for negative.
  // macro_id?: string; // !<mid> for new API.
  bool_str: string; // (<mid> OR <kw>) AND (<kw> OR !<mid2>)
  // section_order?: number; // 0, 1, 2
  // section_name?: string; // Current Role etc.
  section_type: string; // 'MH' or 'NTH' -> Must have or Nice To Have.
  // section_id?: string; // identifier for the section.
  // weight?: number; // default is 1
  section_id?: string;
  // auto generated macro id for the requirement
  macro_id?: string;
  section_name?: string;
  section_description?: string;
  section_icon?: string;
}

//---------------------------------------------------------------------
// Group By
//---------------------------------------------------------------------

export interface IRowGroupBy {
  col: IColumnEntry;
}

//---------------------------------------------------------------------
// Time Context (unused by BE for now)
//---------------------------------------------------------------------

export interface ITimeContext {}

export function instanceOfIFixedTimeContext(object?: ITimeContext): object is IFixedTimeContext {
  return !!object && "startDate" in object && "endDate" in object;
}

export interface IFixedTimeContext extends ITimeContext {
  startDate: number;
  endDate: number;
}

export function instanceOfIRollingTimeContext(object?: ITimeContext): object is IRollingTimeContext {
  return !!object && "timeGranularity" in object && "timeUnitsCount" in object;
}

export interface IRollingTimeContext extends ITimeContext {
  // The granularity at which the "timeUnitsCount" & "endDateOffset" represent
  timeGranularity: TimeGranularity;
  // The number of units for the granularity
  // Ex: timeGranularity=TimeGranularity.M & timeUnitsCount=3 would mean a rolling time window of 3 months.
  timeUnitsCount: number;
  // The number of units to offset rolling time window by.
  // Ex: timeGranularity=TimeGranularity.D, timeUnitsCount=1 & offset=1 would represent a rolling time window for yesterday.
  offset?: number;
}

export enum TimeGranularity {
  D,
  M,
  Y,
}
