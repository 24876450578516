import { Identifier } from "react-admin";
import { IPlatformCollectionMetadata } from "../types/collections";
import { Conversions } from "./Conversions";

//-------------------------------------------------------------------------------
// Response Interfaces
//-------------------------------------------------------------------------------

export interface IGetPlatformCollectionMetadatasResponse {
  collection_names: IPlatformCollectionMetadata[];
}

//-------------------------------------------------------------------------------
// Conversions
//-------------------------------------------------------------------------------

export class PlatformCollectionMetadataConversions implements Conversions {
  public fromResponseMany(response: IGetPlatformCollectionMetadatasResponse): IPlatformCollectionMetadata[] {
    return (response.collection_names ?? []).map((collection: IPlatformCollectionMetadata) => {
      return this.fromResponse(collection);
    });
  }

  public fromResponse(response: IPlatformCollectionMetadata): IPlatformCollectionMetadata {
    return response;
  }

  public fromCreateResponse(response: any): IPlatformCollectionMetadata {
    throw new Error("Conversion not implemented");
  }

  public toRequest(resource: any): any {
    throw new Error("Conversion not implemented");
  }

  public toUpdateRequest(id: Identifier, resource: any): any {
    throw new Error("Conversion not implemented");
  }

  public toCreateRequest(resource: any): any {
    throw new Error("Conversion not implemented");
  }

  public toDeleteRequest(id: Identifier): any {
    throw new Error("Conversion not implemented");
  }
}
