import React from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import styled from "styled-components";

const LISTBOX_PADDING: number = 4; // px
const ITEM_SIZE: number = 42; // px

const OuterElementContext = React.createContext({});

// Adapter for react-window
export const VirtualizedListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;

  const itemData: React.ReactNode[] = React.Children.toArray(children);
  const itemCount: number = itemData.length;

  const listHeight: number = React.useMemo(() => {
    if (itemCount > 12) {
      return 12 * ITEM_SIZE + 2 * LISTBOX_PADDING;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0) + 2 * LISTBOX_PADDING;
  }, [itemCount, itemData]);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={listHeight}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType={StyledUL}
          itemSize={getChildSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

function getChildSize() {
  return ITEM_SIZE;
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
      display: "flex",
      alignItems: "center",
    },
  });
}

const OuterElementType = React.forwardRef<HTMLDivElement>((props: React.HTMLAttributes<HTMLElement>, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const StyledUL = styled.ul`
  padding-inline-start: 0;
`;
