import styled from "styled-components";
import { colors } from "../../constants";

export const EndSessionButton = styled.div`
  display: flex;
  align-items: center;
  color: ${colors["grey-100"]};
  border-radius: 4px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: white;
  }
`;
