import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton, InputAdornment, ListItem, Tooltip } from "@mui/material";
import React from "react";
import { ArrayField, RecordContextProvider, ReferenceField, SingleFieldList, useTranslate } from "react-admin";
import { useFormContext } from "react-hook-form";
import { SelectableText } from "../../../common/Common.styled";
import { GenericWithRecord } from "../../../common/GenericWithRecord";
import { colors } from "../../../constants";
import ObjectContext from "../../../context/objectContext";
import { IColumnDef, IColumnValueType, IObjectDef } from "../../../types/object";
import { Report } from "../../../types/report";
import { Resources } from "../../../types/resources";
import { getObjectIdsFromRelation } from "../../../utils/joinUtils";
import { getObjectsFromIds } from "../../../utils/objectUtils";

interface INestedColumnSelectorProps {
  column?: IColumnDef | undefined;
  searchString: string;
  handleSetSelectedColumn: (col: IColumnDef, useExtLink: boolean) => void;

  valueTypeFilter?: IColumnValueType;
}

export const NestedColumnSelector = (props: INestedColumnSelectorProps) => {
  const { column, searchString, handleSetSelectedColumn, valueTypeFilter } = props;

  const objectMap: Record<string, IObjectDef> = React.useContext(ObjectContext);

  const { getValues } = useFormContext();

  const report: Partial<Report> = getValues() as Partial<Report>;

  const topLevelObjectDef: IObjectDef | undefined = React.useMemo(() => {
    return report?.query?.objects ? getObjectsFromIds(getObjectIdsFromRelation(report.query.objects), objectMap)[0] : undefined;
  }, [report, objectMap]);

  return (
    <>
      {column ? (
        <RecordContextProvider value={column.ext_link}>
          <ReferenceField source="obj_id" reference={Resources.OBJECTS} link={false}>
            <ColumnSelector searchString={searchString} handleSetSelectedColumn={handleSetSelectedColumn} valueTypeFilter={valueTypeFilter} />
          </ReferenceField>
        </RecordContextProvider>
      ) : (
        <RecordContextProvider value={topLevelObjectDef}>
          <ReferenceField source="resource_id" reference={Resources.OBJECTS} link={false}>
            <ColumnSelector searchString={searchString} handleSetSelectedColumn={handleSetSelectedColumn} valueTypeFilter={valueTypeFilter} />
          </ReferenceField>
        </RecordContextProvider>
      )}
    </>
  );
};

interface IColumnSelectorProps {
  searchString: string;
  handleSetSelectedColumn: (col: IColumnDef, useExtLink: boolean) => void;

  valueTypeFilter?: IColumnValueType;
}

export const ColumnSelector = (props: IColumnSelectorProps) => {
  const { searchString, handleSetSelectedColumn, valueTypeFilter } = props;

  const translate = useTranslate();

  const objectMap: Record<string, IObjectDef> = React.useContext(ObjectContext);

  const handleColumnSelection = React.useCallback(
    (column: IColumnDef) => {
      handleSetSelectedColumn(column, false);
    },
    [handleSetSelectedColumn]
  );

  const handleExtLinkSelection = React.useCallback(
    (column: IColumnDef, event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      handleSetSelectedColumn(column, true);
    },
    [handleSetSelectedColumn]
  );

  return (
    <ArrayField source="default_cols">
      <SingleFieldList linkType={false} sx={{ display: "flex", flexDirection: "column", margin: 0 }}>
        <GenericWithRecord
          render={(column: IColumnDef) => {
            if (column.col_name.toLowerCase().includes(searchString) && (!valueTypeFilter || column.value_def.col_value_type === valueTypeFilter || !!column.ext_link)) {
              return (
                <ListItem disableGutters disablePadding>
                  <SelectableText as="div" onClick={handleColumnSelection.bind(this, column)}>
                    {column.col_name}
                    {column.ext_link && (
                      <InputAdornment position="end">
                        <Tooltip title={translate("report.joinTooltip", { objectName: objectMap[column.ext_link.obj_id]?.resource_name })} placement="right">
                          <IconButton size="small" sx={{ "&:hover": { bgcolor: colors["grey-400"] } }} onClick={handleExtLinkSelection.bind(this, column)}>
                            <ChevronRightIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )}
                  </SelectableText>
                </ListItem>
              );
            } else {
              return <></>;
            }
          }}
        />
      </SingleFieldList>
    </ArrayField>
  );
};
