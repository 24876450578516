import { Identifier } from "react-admin";
import { AllDash } from "../types";
import { IBasePlatformApp, IPlatformApp } from "../types/settings";
import { Conversions } from "./Conversions";

//-------------------------------------------------------------------------------
// Response Interfaces
//-------------------------------------------------------------------------------

export interface IGetPlatformAppsResponse {
  apps: IBasePlatformApp[];
}

export interface ICreatePlatformAppResponse {
  app_create: IBasePlatformApp;
}

//-------------------------------------------------------------------------------
// Request Interfaces
//-------------------------------------------------------------------------------

export interface ICreatePlatformAppRequest {
  payload: Partial<IBasePlatformApp>;
}

export interface IUpdatePlatformAppRequest {
  resource_id: Identifier;
  payload: Partial<IPlatformApp>;
}

interface IDeletePlatformAppRequest {
  resource_id: Identifier;
}

//-------------------------------------------------------------------------------
// Conversions
//-------------------------------------------------------------------------------

export class PlatformAppConversions implements Conversions {
  public fromResponseMany(response: IGetPlatformAppsResponse): IPlatformApp[] {
    return (response.apps ?? []).map((platformApp: IBasePlatformApp) => {
      return this.fromResponse(platformApp);
    });
  }

  public fromResponse(response: IBasePlatformApp): IPlatformApp {
    return {
      ...response,
      id: response.resource_id,
    };
  }

  public fromCreateResponse(response: ICreatePlatformAppResponse): IPlatformApp {
    return this.fromResponse(response.app_create);
  }

  public toRequest(platformApp: IPlatformApp): IBasePlatformApp {
    return platformApp;
  }

  public toUpdateRequest(id: Identifier, dashboard: Partial<AllDash>): IUpdatePlatformAppRequest {
    return {
      resource_id: id,
      payload: this.toRequestPartial(dashboard),
    };
  }

  public toCreateRequest(platformApp: Partial<IPlatformApp>): ICreatePlatformAppRequest {
    return {
      payload: this.toRequestPartial(platformApp),
    };
  }

  public toDeleteRequest(id: Identifier): IDeletePlatformAppRequest {
    return {
      resource_id: id,
    };
  }

  private toRequestPartial(platformApp: Partial<IPlatformApp>): Partial<IPlatformApp> {
    return platformApp;
  }
}
