import { IPlatformResource } from "./resources";
import { IAccessInfo, IHasAccessInfo } from "./accessInfo";
import { IColumnDefResponse, IObjectResponse } from "./../conversions/ObjectConversions";
import { RaRecord } from "react-admin";
import { IColumnEntry } from "./report";
import { IExpression } from "../conversions/ReportConversions";

export interface IObjectDef extends RaRecord, IPlatformResource {
  version: number; // version number

  // Extensible object definition for each app.
  // Each App - can create its own object definition.
  app_id?: string;
  app_ver?: string;

  // Indicating the template is system template.
  system_template: boolean;
  org_template?: boolean;

  uid: string; // User identifier creating the object definition.
  acc_id: string; // Account Identifier.

  // Base object identifier.
  base_obj_id?: string; // In case of extended schema.

  base_collection: string;

  // Default columns.
  default_cols: IColumnDef[];

  // Extensions can be user defined or specific app installed.
  ext_cols?: IColumnDef[];

  updated_at: string;
}

export interface IColumnDef {
  obj_id: string;
  col_id: string;
  col_name: string;

  ext_link?: IColumnEntry;
  // If there is no app_id or uid override for the column, object entity owner
  // will the person responsible.
  // User identifier who updated the column.
  uid?: string;

  // Account identifier.
  acc_id?: string;

  // Application that added this column.
  app_id?: string;

  // Hidden column.
  hidden?: boolean;

  // System created.
  col_owner_type: IColumnOwnerType;

  // Definition of value.
  value_def: IColumnValueDef;

  // Filtering definitions
  filter_def: IColumnFilterDef;
}

export enum IColumnOwnerType {
  System = 0,
  Application = 1,
  User = 2,
}

export interface IColumnValueDef {
  // Column value data type.
  col_value_type: IColumnValueType;
  col_bucket_id: string;

  // In case of virtual columns, col_formula specifies formula to derive the column
  col_formula?: IExpression;

  // Aggregations or some other forumla if any over the bucket identifier.
  formula?: string;

  // Filter/Qualifier for selecting the bucket value.
  qualifier?: IQualifier; // '(worexp.duration.to_present: true and (workexp.company.name: google or workexp.company.name: microsoft))'
}

export interface IQualifier {
  rules: Array<IQualifierRule>;
  // Note: Projection has to have bucket path common with the the rule.
  //
  // Examples:
  // rule could check for workexp.company.name == 'Instart Logic'
  // and workexp.
  // projection could be 'workexp' object to the macro layer (automatically
  // projects array index in case workexp is an array).
  // Macro on the other hand can evaluate further on the Instart Logic
  // experience for example: workexp.duration > 4 years etc.
  //
  // Note: If the projection path and rule path doesn't have any subtree in
  // common, then there is no need to use qualifier rule. This is akin to using
  // sub-select of SQL when there is no need to and query can be written flat.
  //
  // For example: If the rule checks for WorkExp.compnay.name == 'Instart Logic'
  // the, projection should not be education.education_details as both these
  // are independent and can be queried with 'AND' operation in a macro.
  //
  // TODO: Figureout if we even need this ? We can easily use common bucket
  // prefix between qualifier bucket id and macro rule bucket id as
  // projection.
  projection?: string;
}

export interface IQualifierRule {
  /**
   * Qualifier string would be one of the following:
   * "None" (No filter, default).
   * "Any" (Same as None for Legacy)
   * "Recent" (Duration.endDate is Present - 2 years in the past),
   * "Current" (Duraiton.endDate is Present - 6 months ago).
   * "Past" - Any time in the past (exluding the current one).
   * "Custom" - In which case qualifier definition is read.
   */
  qualifier_type: string; // RuleQualifer enum string.
  custom_rule?: IQualifierCustomRule;
  binary_op?: string; // 'and'/'or' with the next rule if any (default: 'and').
}

export interface IQualifierCustomRule {
  bucket_id: string;
  op: string;
  rhs: Array<string>;
  inverse?: boolean;
}

export enum IColumnValueType {
  Number,
  String,
  Date,
  Boolean,
  Id,
}

export interface IColumnFilterDef {
  // source for filter values for the column
  value_source_type: IColumnFilterValueSourceType;

  // for value_source_type == FixedList, the different allowed filter values are in the list
  // for example for boolean columns the list would be ["true", "false"]
  // For a field of an enum type or a categorical string, tdifferent possible values of enum/category
  // will be in the list, say gender, ethnicity
  valid_values: string[];

  // used for date and numeric datatypes
  numerical_range?: INumericalRange[];
}

export enum IColumnFilterValueSourceType {
  // IColumnFilterDef carries possible filter values for the column in a list
  EmbeddedList = "embedded_list",

  // Filter values are specified as a free text
  FreeText = "free_text",

  // Filter values are to be obtained using an API,
  // For example, list of recruiters
  FetchFromAPI = "fetch_from_api",
}

export interface INumericalRange {
  start?: number;
  end?: number;
}
