import { Autocomplete, AutocompleteRenderInputParams, Chip } from "@mui/material";
import React from "react";
import { useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { BorderlessTextField } from "../../../../common/Common.styled";
import { USER_IMPERSONATION_QUERY_PARAM } from "../../../../components/UserImpersonation/UserImpersonationSwitcher";
import { VirtualizedListboxComponent } from "../../../../components/Virtualization/VirtualizedListboxComponent";
import RunAsContext from "../../../../context/runAsContext";
import { IAliasedExpression, IBooleanExpression, IExpression, IExpressionType, IRelation, IRelationType, toReportQueryRequest } from "../../../../conversions/ReportConversions";
import { apiUrl, httpClient } from "../../../../dataProvider/customDataProvider";
import { DEFAULT_QUERY_OPTIONS } from "../../../../queryClient";
import { IColumnDef } from "../../../../types/object";
import { IReportQuery, Report } from "../../../../types/report";
import { IReportData, IReportResult } from "../../../../types/reportQuery";
import { buildAliasedExpressionFromDef, buildColumnIdentifierFromDef, buildSetMembershipBooleanExpression, getExpressionObjectIds } from "../../../../utils/expressionUtils";
import { findRelationWithId } from "../../../../utils/relationUtils";
import { decodeString } from "../../../../utils/textUtils";
import { FilterValueSelectorOptions } from "../FilterSelector";

interface IAutocompleteFilterInputProps {
  expression?: IAliasedExpression;
  column?: IColumnDef;
  filterValueSelectorOption: FilterValueSelectorOptions;
  initialValues: (string | number | boolean)[] | undefined;
}

export const AutocompleteFilterInput = (props: IAutocompleteFilterInputProps) => {
  const { expression, column, filterValueSelectorOption, initialValues } = props;

  const { reset } = useFormContext();
  const report: Partial<Report> = useRecordContext<Report>();

  const [currentQueryParameters] = useSearchParams();
  const targetUserId: string | null = currentQueryParameters.get(USER_IMPERSONATION_QUERY_PARAM) ?? currentQueryParameters.get("joid");

  const { runAsUserId } = React.useContext(RunAsContext);

  const [selectedValues, setSelectedValues] = React.useState<(string | { id: string; label: string })[]>(getInitialValues(initialValues));

  const reportQuery: IReportQuery | undefined = React.useMemo(() => {
    const objectIds: string[] = expression ? getExpressionObjectIds(expression) : column?.obj_id ? [column?.obj_id] : [];
    let aliasedExpression: IAliasedExpression | undefined;

    if (expression) {
      aliasedExpression = expression;
    } else if (column) {
      aliasedExpression = buildAliasedExpressionFromDef(column);
    }

    let relation: IRelation | undefined;

    if (objectIds.length === 1 && report?.query?.objects) {
      relation = findRelationWithId(report.query.objects, objectIds[0]) ?? {
        kind: IRelationType.TableIdentifier,
        source_obj_id: objectIds[0],
      };
    } else if (objectIds.length > 1) {
      relation = report?.query?.objects;
    }

    return !!relation && !!aliasedExpression
      ? {
          namespace: "filter_values",
          objects: relation,
          projections: [],
          row_grouping: [aliasedExpression],
          rowFilters: [],
          indexFilters: report?.query?.indexFilters ?? [],
          booleanLogic: "",
        }
      : undefined;
  }, [expression, column, report]);

  const { data } = useQuery({
    ...DEFAULT_QUERY_OPTIONS,
    queryKey: [expression?.alias ?? column?.col_id],
    queryFn: () =>
      httpClient(`${apiUrl}/run_report`, {
        method: "POST",
        body: JSON.stringify({
          report_query: toReportQueryRequest(reportQuery!),
          internal: {},
          ...(!!targetUserId && { target_user_id: targetUserId }),
          ...(!!runAsUserId && { remapped_user_id: runAsUserId }),
        }),
        credentials: "include",
      }).then(({ json }: { json: IReportResult }) => {
        return json;
      }),
    enabled: !!reportQuery,
  });

  const filterOptions: { id: string; label: string }[] = React.useMemo(() => {
    return (data?.report_data?.subgroups ?? []).flatMap((subgroup: IReportData) => {
      const value: string | undefined = Object.values(subgroup?.group_label ?? {})[0];
      return value
        ? [
            {
              id: value,
              label: decodeString(value),
            },
          ]
        : [];
    });
  }, [data]);

  React.useEffect(() => {
    if (initialValues && filterOptions && filterOptions.length > 0) {
      setSelectedValues(getInitialValues(initialValues, filterOptions));
    }
  }, [initialValues, filterOptions]);

  const booleanExpression: IBooleanExpression | undefined = React.useMemo(() => {
    if (selectedValues.length === 0) {
      return undefined;
    } else if (column) {
      const selectedIds: string[] = selectedValues.map((val: string | { id: string; label: string }) => {
        if (typeof val === "string") {
          return val;
        } else {
          return val.id;
        }
      });

      let columnExpression: IExpression;
      if (column.value_def.col_formula) {
        columnExpression = {
          kind: IExpressionType.AliasedExpression,
          alias: column.col_name,
          expr: column.value_def.col_formula.kind === IExpressionType.InlineExpression ? { ...column.value_def.col_formula, alias: column.col_name } : column.value_def.col_formula,
        };
      } else {
        columnExpression = buildColumnIdentifierFromDef(column);
      }

      return buildSetMembershipBooleanExpression(columnExpression, selectedIds, filterValueSelectorOption);
    } else if (expression) {
      const selectedIds: string[] = selectedValues.map((val: string | { id: string; label: string }) => {
        if (typeof val === "string") {
          return val;
        } else {
          return val.id;
        }
      });
      return buildSetMembershipBooleanExpression(expression, selectedIds, filterValueSelectorOption);
    }
  }, [selectedValues, expression, column, filterValueSelectorOption]);

  React.useEffect(() => {
    if (booleanExpression) {
      reset(booleanExpression);
    } else {
      reset({});
    }
  }, [booleanExpression]);

  const handleChange = React.useCallback((newVals: (string | { id: string; label: string })[]) => {
    setSelectedValues(newVals);
  }, []);

  return (
    <>
      <Autocomplete
        sx={{ maxWidth: "25.6rem" }}
        multiple
        options={filterOptions}
        freeSolo
        onChange={(event, item) => {
          handleChange(item);
        }}
        value={selectedValues}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        renderTags={(value: (string | { id: string; label: string })[], getTagProps) => {
          return value.map((option: string | { id: string; label: string }, index: number) => {
            if (typeof option === "string") {
              return <Chip variant="outlined" label={option} {...getTagProps({ index })} />;
            } else {
              return <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />;
            }
          });
        }}
        renderInput={(params: Partial<AutocompleteRenderInputParams>) => {
          return <BorderlessTextField size="small" variant="outlined" {...params} />;
        }}
        ListboxComponent={VirtualizedListboxComponent}
      />
    </>
  );
};

function getInitialValues(
  initialValues: (string | number | boolean)[] | undefined,
  options?: (string | { id: string; label: string })[] | undefined
): (string | { id: string; label: string })[] {
  return (initialValues ?? []).map((initialValue: string | number | boolean) => {
    const value: string = initialValue.toString();
    return (options ?? []).find((option: string | { id: string; label: string }) => typeof option !== "string" && option.id === value) ?? value;
  });
}

function getOptionLabel(option: string | { id: string; label: string }): string {
  return typeof option === "string" ? option : option.label;
}

function isOptionEqualToValue(option: string | { id: string; label: string }, value: string | { id: string; label: string }): boolean {
  return typeof option !== "string" && typeof value !== "string" ? option.id === value.id : option === value;
}
