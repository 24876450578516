import { Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import React from "react";
import { useTranslate } from "react-admin";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { BorderlessTextField } from "../../../../common/Common.styled";
import { combineBooleanExpressions, IBooleanExpression, IBooleanOp, IExpression, IRelationalBooleanExpression } from "../../../../conversions/ReportConversions";
import { IColumnDef } from "../../../../types/object";
import { buildColumnIdentifierFromDef, buildRelationalBooleanExpression } from "../../../../utils/expressionUtils";
import { FilterValueSelectorOptions } from "../FilterSelector";

interface INumericFilterInputProps {
  expression?: IExpression;
  column?: IColumnDef;
  filterValueSelectorOption: FilterValueSelectorOptions;
  initialValues: (string | number | boolean)[] | undefined;
}

export const NumericFilterInput = (props: INumericFilterInputProps) => {
  const { column, expression, filterValueSelectorOption, initialValues } = props;

  const translate = useTranslate();

  const { setValue, reset } = useFormContext();

  const [value1, setValue1] = React.useState<string | undefined>(getInitialValue(initialValues, 0));
  const [value2, setValue2] = React.useState<string | undefined>(getInitialValue(initialValues, 1));

  React.useEffect(() => {
    if (column) {
      reset(buildFilterExpression(filterValueSelectorOption, buildColumnIdentifierFromDef(column), Number(value1), Number(value2)));
    } else if (expression) {
      reset(buildFilterExpression(filterValueSelectorOption, expression, Number(value1), Number(value2)));
    }
  }, [column, expression, filterValueSelectorOption, value1, value2]);

  const minValue: number = React.useMemo(() => {
    return (column?.filter_def?.numerical_range?.length && column.filter_def.numerical_range[0].start) ?? -Infinity;
  }, [column]);

  const maxValue: number = React.useMemo(() => {
    return (column?.filter_def?.numerical_range?.length && column.filter_def.numerical_range[0].end) ?? Infinity;
  }, [column]);

  const isValidNumber = React.useCallback(
    (value: string) => {
      return isFinite(Number(value)) && Number(value) >= minValue && Number(value) <= maxValue;
    },
    [minValue, maxValue]
  );

  const handleSetValue1 = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue1(event.target.value.trim());
    },
    [setValue, isValidNumber, value2]
  );

  const handleSetValue2 = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue2(event.target.value.trim());
    },
    [setValue, isValidNumber, value1]
  );

  return (
    <>
      {filterValueSelectorOption !== FilterValueSelectorOptions.BETWEEN ? (
        <BorderlessTextField size="small" variant="outlined" required type="number" value={value1} onChange={handleSetValue1} />
      ) : (
        <Stack direction="row" alignItems="center">
          <BetweenTextField size="small" variant="outlined" required type="number" value={value1} onChange={handleSetValue1} />
          <Typography variant="body2">{translate("generic.and")}</Typography>
          <BetweenTextField size="small" variant="outlined" required type="number" value={value2} onChange={handleSetValue2} />
        </Stack>
      )}
    </>
  );
};

export const BetweenTextField = styled(TextField)<TextFieldProps>`
  display: flex;
  flex: 1 1 auto;

  width: 12.8rem;
  margin: 1.3rem;

  font-size: 14px;

  input {
    font-size: 14px;
  }
`;

function getInitialValue(initialValues: (string | number | boolean)[] | undefined, index: number): string | undefined {
  if (initialValues && initialValues.length > index) {
    const value: string | number | boolean = initialValues[index];
    return typeof value === "number" ? String(value) : undefined;
  } else {
    return undefined;
  }
}

function buildFilterExpression(
  filterValueSelectorOption: FilterValueSelectorOptions,
  expression: IExpression,
  value1: number | undefined,
  value2: number | undefined
): IRelationalBooleanExpression | IBooleanExpression {
  if (filterValueSelectorOption === FilterValueSelectorOptions.GREATER_THAN) {
    return buildRelationalBooleanExpression(expression, value1!, true, false);
  } else if (filterValueSelectorOption === FilterValueSelectorOptions.LESS_THAN) {
    return buildRelationalBooleanExpression(expression, value1!, false, false);
  } else {
    return combineBooleanExpressions([buildRelationalBooleanExpression(expression, value1!, true), buildRelationalBooleanExpression(expression, value2!, false)], IBooleanOp.And);
  }
}
