import { colors } from "../constants";
import { IOrgPrefs } from "../types/settings";

const COLOR_PALETTE_SIZE: number = 8;

export function createFullPalette(primaryColor?: string | null, secondaryColor?: string | null, additionalColors: string[] = []): string[] {
  const baseColors = [...(primaryColor ? [primaryColor] : [])].concat(secondaryColor ? [secondaryColor] : []).concat(additionalColors);
  const palette: string[] = [];
  let totalSlots = COLOR_PALETTE_SIZE;

  // Calculate initial slots allocation
  const slotsForEach = Array(baseColors.length).fill(Math.floor(totalSlots / baseColors.length));
  for (let i = 0; i < totalSlots % baseColors.length; i++) {
    slotsForEach[i] += 1; // Distribute remaining slots preferentially
  }

  // Generate colors based on slot allocation
  baseColors.forEach((color, index) => {
    const colorVariations = generateColorRange(color, slotsForEach[index]);
    palette.push(...colorVariations.slice(0, Math.min(colorVariations.length, totalSlots)));
    totalSlots -= colorVariations.length;
  });

  return palette;
}

export function generateColorRange(baseColor: string, count: number): string[] {
  const hexToRgb = (hex: string): [number, number, number] => {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  };

  const rgbToHex = (r: number, g: number, b: number): string => {
    const toHex = (x: number): string => x.toString(16).padStart(2, "0");
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  // Function to adjust colors subtly
  const adjustColor = (color: [number, number, number], factor: number, isLightening: boolean): string => {
    let [r, g, b] = color;
    if (isLightening) {
      r += (255 - r) * factor;
      g += (255 - g) * factor;
      b += (255 - b) * factor;
    } else {
      r *= 1 - factor;
      g *= 1 - factor;
      b *= 1 - factor;
    }
    return rgbToHex(Math.round(r), Math.round(g), Math.round(b));
  };

  const primaryRGB = hexToRgb(baseColor);
  const colors: string[] = [];
  const halfIndex = Math.floor((count - 1) / 2);
  const isEven: boolean = count % 2 === 0;
  const lighterCount = isEven ? halfIndex + 1 : halfIndex;

  // Generate lighter colors
  for (let i = 1; i <= lighterCount; i++) {
    const factor = 0.5 * (i / lighterCount);
    colors.unshift(adjustColor(primaryRGB, factor, true));
  }

  // Insert the base color in the middle
  colors.push(baseColor);

  // Generate darker colors
  for (let i = 1; i <= halfIndex; i++) {
    const factor = 0.5 * (i / halfIndex);
    colors.push(adjustColor(primaryRGB, factor, false));
  }

  return colors;
}

export function getChartColors(orgPrefs?: IOrgPrefs): string[] {
  if (
    orgPrefs?.theme_prefs?.analytics_theme_prefs?.theme_enabled &&
    orgPrefs.theme_prefs.analytics_theme_prefs.primary_color
  ) {
    return createFullPalette(
      orgPrefs.theme_prefs.analytics_theme_prefs.primary_color,
      orgPrefs.theme_prefs.analytics_theme_prefs.secondary_color,
      orgPrefs.theme_prefs.analytics_theme_prefs.additional_colors
    );
  } else {
    return colors.chart;
  }
}
