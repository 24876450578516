//-----------------------------------------------------------------------
// Course-Grained External Admin Roles
//-----------------------------------------------------------------------

import { Resources } from "../types/resources";

export const PlatformAccountAdmin = "PlatformAccountAdmin";

export const PlatformUpdateAccountScope = "PlatformUpdateAccountScope";

export function hasAccountAdminRole(roles: string[]): boolean {
  return roles.includes(PlatformAccountAdmin);
}

export function hasRoleToUpdateToAccountScope(roles: string[]): boolean {
  return roles.includes(PlatformUpdateAccountScope);
}

//-----------------------------------------------------------------------
// Course-Grained Internal Admin Roles
//-----------------------------------------------------------------------

export const PlatformInternalAnalyticsAdmin = "PlatformInternalAnalyticsAdmin";
export const PlatformInternalSystemAdmin = "PlatformInternalSystemAdmin";
export const PlatformInternalUpdateAnyScope = "PlatformInternalUpdateAnyScope";

export const PlatformInternalAnalyticsView = "PlatformInternalAnalyticsView";
export const PlatformInternalSystemView = "PlatformInternalSystemView";

export function hasInternalSystemAdminRole(roles: string[]): boolean {
  return roles.includes(PlatformInternalSystemAdmin);
}

export function hasInternalAnalyticsAdminRole(roles: string[]): boolean {
  return roles.includes(PlatformInternalAnalyticsAdmin);
}

export function hasRoleToUpdateToAnyScope(roles: string[]): boolean {
  return roles.includes(PlatformInternalUpdateAnyScope);
}

export function hasInternalAnalyticsViewAdminRole(roles: string[]): boolean {
  return roles.includes(PlatformInternalAnalyticsView);
}

export function hasInternalSystemViewAdminRole(roles: string[]): boolean {
  return roles.includes(PlatformInternalSystemView);
}

//-----------------------------------------------------------------------
// Accounts
//-----------------------------------------------------------------------

export function hasAccountsView(roles: string[]): boolean {
  return roles.includes("PlatformInternalAccountsView") || hasInternalSystemAdminRole(roles) || hasInternalSystemViewAdminRole(roles);
}

export function hasAccountsCreate(roles: string[]): boolean {
  return roles.includes("PlatformInternalAccountsCreate") || hasInternalSystemAdminRole(roles);
}

export function hasAccountsUpdate(roles: string[]): boolean {
  return roles.includes("PlatformInternalAccountsUpdate") || hasInternalSystemAdminRole(roles);
}

export function hasAccountsDelete(roles: string[]): boolean {
  return roles.includes("PlatformInternalAccountsDelete") || hasInternalSystemAdminRole(roles);
}

//-----------------------------------------------------------------------
// Users
//-----------------------------------------------------------------------

export function hasUsersView(roles: string[]): boolean {
  return roles.includes("PlatformUsersView") || hasInternalSystemAdminRole(roles) || hasInternalSystemViewAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasUsersCreate(roles: string[]): boolean {
  return roles.includes("PlatformUsersCreate") || hasInternalSystemAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasUsersUpdate(roles: string[]): boolean {
  return hasUsersUpdateProfile(roles) && hasUsersUpdateRoles(roles) && hasUsersUpdateApps(roles);
}

export function hasUsersUpdateProfile(roles: string[]): boolean {
  return roles.includes("PlatformUsersUpdateProfile") || hasInternalSystemAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasUsersUpdateRoles(roles: string[]): boolean {
  return roles.includes("PlatformUsersUpdateRoles") || hasInternalSystemAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasUsersUpdateApps(roles: string[]): boolean {
  return roles.includes("PlatformUsersUpdateApps") || hasInternalSystemAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasUsersDelete(roles: string[]): boolean {
  return roles.includes("PlatformUsersDelete") || hasInternalSystemAdminRole(roles) || hasAccountAdminRole(roles);
}

//-----------------------------------------------------------------------
// Applications
//-----------------------------------------------------------------------

export function hasApplicationsView(roles: string[]): boolean {
  return roles.includes("PlatformApplicationsView") || hasInternalSystemAdminRole(roles) || hasInternalSystemViewAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasApplicationsCreate(roles: string[]): boolean {
  return roles.includes("PlatformApplicationsCreate") || hasInternalSystemAdminRole(roles);
}

export function hasApplicationsUpdate(roles: string[]): boolean {
  return roles.includes("PlatformApplicationsUpdate") || hasInternalSystemAdminRole(roles);
}

export function hasApplicationsDelete(roles: string[]): boolean {
  return roles.includes("PlatformApplicationsDelete") || hasInternalSystemAdminRole(roles);
}

//-----------------------------------------------------------------------
// Application Instances - view is shared with hasApplicationsView
//-----------------------------------------------------------------------

export function hasApplicationInstancesCreate(roles: string[]): boolean {
  return roles.includes("PlatformApplicationinstancesCreate") || hasInternalSystemAdminRole(roles);
}

export function hasApplicationInstancesUpdate(roles: string[]): boolean {
  return roles.includes("PlatformApplicationinstancesUpdate") || hasInternalSystemAdminRole(roles);
}

export function hasApplicationInstancesDelete(roles: string[]): boolean {
  return roles.includes("PlatformApplicationinstancesDelete") || hasInternalSystemAdminRole(roles);
}

//-----------------------------------------------------------------------
// Roles
//-----------------------------------------------------------------------

export function hasRolesView(roles: string[]): boolean {
  return roles.includes("PlatformRolesView") || hasInternalSystemAdminRole(roles) || hasInternalSystemViewAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasRolesCreate(roles: string[]): boolean {
  return roles.includes("PlatformRolesCreate") || hasInternalSystemAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasRolesUpdate(roles: string[]): boolean {
  return roles.includes("PlatformRolesUpdate") || hasInternalSystemAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasRolesDelete(roles: string[]): boolean {
  return roles.includes("PlatformRolesDelete") || hasInternalSystemAdminRole(roles) || hasAccountAdminRole(roles);
}

//-----------------------------------------------------------------------
// Roles
//-----------------------------------------------------------------------

// TODO: this may need a dedicated role at some point
export function hasPermissionsView(roles: string[]): boolean {
  return hasInternalSystemAdminRole(roles);
}

//-----------------------------------------------------------------------
// Reports
//-----------------------------------------------------------------------

export function hasReportsView(roles: string[]): boolean {
  return roles.includes("PlatformReportsView") || hasInternalAnalyticsAdminRole(roles) || hasInternalAnalyticsViewAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasReportsCreate(roles: string[]): boolean {
  return roles.includes("PlatformReportsCreate") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasReportsUpdate(roles: string[]): boolean {
  return roles.includes("PlatformReportsUpdate") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasReportsDelete(roles: string[]): boolean {
  return roles.includes("PlatformReportsDelete") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

//-----------------------------------------------------------------------
// Dashboards
//-----------------------------------------------------------------------

export function hasDashboardsView(roles: string[]): boolean {
  return roles.includes("PlatformDashboardsView") || hasInternalAnalyticsAdminRole(roles) || hasInternalAnalyticsViewAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasDashboardsCreate(roles: string[]): boolean {
  return roles.includes("PlatformDashboardsCreate") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasDashboardsUpdate(roles: string[]): boolean {
  return roles.includes("PlatformDashboardsUpdate") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasDashboardsDelete(roles: string[]): boolean {
  return roles.includes("PlatformDashboardsDelete") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

//-----------------------------------------------------------------------
// Objects
//-----------------------------------------------------------------------

export function hasObjectsView(roles: string[]): boolean {
  return roles.includes("PlatformObjectsView") || hasInternalAnalyticsAdminRole(roles) || hasInternalAnalyticsViewAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasObjectsCreate(roles: string[]): boolean {
  return roles.includes("PlatformObjectsCreate") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasObjectsUpdate(roles: string[]): boolean {
  return roles.includes("PlatformObjectsUpdate") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasObjectsDelete(roles: string[]): boolean {
  return roles.includes("PlatformObjectsDelete") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

//-----------------------------------------------------------------------
// Data Imports
//-----------------------------------------------------------------------

export function hasDataImportsView(roles: string[]): boolean {
  return roles.includes("PlatformDataimportsView") || hasInternalAnalyticsAdminRole(roles) || hasInternalAnalyticsViewAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasDataImportsCreate(roles: string[]): boolean {
  return roles.includes("PlatformDataimportsCreate") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasDataImportsUpdate(roles: string[]): boolean {
  return roles.includes("PlatformDataimportsUpdate") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasDataImportsDelete(roles: string[]): boolean {
  return roles.includes("PlatformDataimportsDelete") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

//-----------------------------------------------------------------------
// Functions
//-----------------------------------------------------------------------

export function hasFunctionsView(roles: string[]): boolean {
  return roles.includes("PlatformFunctionsView") || hasInternalAnalyticsAdminRole(roles) || hasInternalAnalyticsViewAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasFunctionsCreate(roles: string[]): boolean {
  return roles.includes("PlatformFunctionsCreate") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasFunctionsUpdate(roles: string[]): boolean {
  return roles.includes("PlatformFunctionsUpdate") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

export function hasFunctionsDelete(roles: string[]): boolean {
  return roles.includes("PlatformFunctionsDelete") || hasInternalAnalyticsAdminRole(roles) || hasAccountAdminRole(roles);
}

//-----------------------------------------------------------------------
// Resource Permissions
//-----------------------------------------------------------------------

export function hasResourcePermissions(roles: string[]): boolean {
  return roles.includes("PlatformResourcePermissions") || hasRoleToUpdateToAnyScope(roles) || hasRoleToUpdateToAccountScope(roles);
}

//-----------------------------------------------------------------------
// Filtering Scope Access
//-----------------------------------------------------------------------

export function hasAccountFilteringRole(roles: string[]): boolean {
  return roles.includes(PlatformInternalAnalyticsAdmin) && hasAccountsView(roles);
}

export function hasUserFilteringRole(roles: string[]): boolean {
  return hasAccountAdminRole(roles) || hasInternalSystemAdminRole(roles) || hasInternalAnalyticsAdminRole(roles);
}

//-----------------------------------------------------------------------
// Impersonation Access
//-----------------------------------------------------------------------

export function hasImpersonationAccess(roles: string[]): boolean {
  const systemAdmin: boolean = hasInternalSystemAdminRole(roles);
  const analyticsAdmin: boolean = hasInternalAnalyticsAdminRole(roles);

  const systemAdminView: boolean = hasInternalSystemViewAdminRole(roles);
  const analyticsAdminView: boolean = hasInternalAnalyticsViewAdminRole(roles);

  return (systemAdmin && analyticsAdmin) || (systemAdmin && analyticsAdminView) || (systemAdminView && analyticsAdmin) || (systemAdminView && analyticsAdminView);
}

//-----------------------------------------------------------------------
// Run As Access
//-----------------------------------------------------------------------

export function hasRunAsAccess(roles: string[]): boolean {
  const analyticsAdmin: boolean = hasInternalAnalyticsAdminRole(roles);
  const analyticsAdminView: boolean = hasInternalAnalyticsViewAdminRole(roles);

  return analyticsAdmin || analyticsAdminView;
}

//-----------------------------------------------------------------------
// General Access
//-----------------------------------------------------------------------

interface IResourceAccessFunction {
  view: (roles: string[]) => boolean;
  create: (roles: string[]) => boolean;
  update: (roles: string[]) => boolean;
  delete: (roles: string[]) => boolean;
}

export function hasAccess(resource: Resources): IResourceAccessFunction {
  switch (resource) {
    case Resources.PLATFORM_ACCOUNTS:
      return { view: hasAccountsView, create: hasAccountsCreate, update: hasAccountsUpdate, delete: hasAccountsDelete };
    case Resources.USERS:
      return { view: hasUsersView, create: hasUsersCreate, update: hasUsersUpdate, delete: hasUsersDelete };
    case Resources.PLATFORM_APPS:
      return { view: hasApplicationsView, create: hasApplicationsCreate, update: hasApplicationsUpdate, delete: hasApplicationsDelete };
    case Resources.PLATFORM_APP_INSTANCES:
      return { view: hasApplicationsView, create: hasApplicationInstancesCreate, update: hasApplicationInstancesUpdate, delete: hasApplicationInstancesDelete };
    case Resources.PLATFORM_ROLES:
      return { view: hasRolesView, create: hasRolesCreate, update: hasRolesUpdate, delete: hasRolesDelete };
    case Resources.REPORTS:
      return { view: hasReportsView, create: hasReportsCreate, update: hasReportsUpdate, delete: hasReportsDelete };
    case Resources.DASHBOARDS:
      return { view: hasDashboardsView, create: hasDashboardsCreate, update: hasDashboardsUpdate, delete: hasDashboardsDelete };
    case Resources.OBJECTS:
      return { view: hasObjectsView, create: hasObjectsCreate, update: hasObjectsUpdate, delete: hasObjectsDelete };
    case Resources.DATA_IMPORT:
      return { view: hasDataImportsView, create: hasDataImportsCreate, update: hasDataImportsUpdate, delete: hasDataImportsDelete };
    default:
      return { view: (roles: string[]) => false, create: (roles: string[]) => false, update: (roles: string[]) => false, delete: (roles: string[]) => false };
  }
}

export function hasViewAccess(roles: string[], resource: Resources): boolean {
  return hasAccess(resource).view(roles);
}

export function hasCreateAccess(roles: string[], resource: Resources): boolean {
  return hasAccess(resource).create(roles);
}

export function hasUpdateAccess(roles: string[], resource: Resources): boolean {
  return hasAccess(resource).view(roles);
}
export function hasDeleteAccess(roles: string[], resource: Resources): boolean {
  return hasAccess(resource).view(roles);
}

export function hasResourceFullAccess(roles: string[], resource: Resources): boolean {
  const accessFunctions: IResourceAccessFunction = hasAccess(resource);
  return accessFunctions.view(roles) && accessFunctions.create(roles) && accessFunctions.update(roles) && accessFunctions.delete(roles);
}

//-----------------------------------------------------------------------
// Settings Access
//-----------------------------------------------------------------------

export function hasSystemResourceEditAccess(roles: string[]): boolean {
  return hasAccountAdminRole(roles) || hasInternalSystemAdminRole(roles);
}

export function hasSystemResourceViewAccess(roles: string[]): boolean {
  return hasInternalSystemViewAdminRole(roles) || hasSystemResourceEditAccess(roles);
}

//-----------------------------------------------------------------------
// Feature Specific Access
//-----------------------------------------------------------------------

export function hasEditJoinsAccess(roles: string[]): boolean {
  return roles.includes("PlatformEditJoins");
}