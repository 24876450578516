import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Divider, IconButton, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import ExpressionNameLookupContext from "../../../context/expressionNameLookupContext";
import { IAliasedExpression, IExpressionType } from "../../../conversions/ReportConversions";
import { IColumnValueType } from "../../../types/object";
import { buildNullRelationalBooleanExpression } from "../../../utils/expressionUtils";
import { IPopoverContentProps } from "../common/GenericReportField";
import { AutocompleteFilterInput } from "./FilterInputs/AutoCompleteFilterInput";
import { BooleanFilterInput } from "./FilterInputs/BooleanFilterInput";
import { DateFilterInput } from "./FilterInputs/DateFilterInput";
import { NumericFilterInput } from "./FilterInputs/NumericFilterInput";
import { FilterValueSelectorOptions, IFilterSelectorInitialState, getFilterValueTypeFromFilterInitialValues } from "./FilterSelector";

interface IAliasedExpressionFilterSelectorProps extends IPopoverContentProps {
  expression: IAliasedExpression;
  initialValues: (string | number | boolean)[] | undefined;
  initialState?: IFilterSelectorInitialState;
  handleRemoveSelectedColumn: () => void;
  handleClosePopover: () => void;
}

export const AliasedExpressionFilterSelector = (props: IAliasedExpressionFilterSelectorProps) => {
  const { expression, initialValues, initialState, handleRemoveSelectedColumn, handleClosePopover } = props;

  const { reset, getValues } = useFormContext();

  const expressionNameLookup: Record<string, string> = React.useContext(ExpressionNameLookupContext);

  const expressionKind: IExpressionType | undefined = getValues("kind");

  const [selectedFilterValueTypeOption, setSelectedFilterValueTypeOption] = React.useState<FilterValueSelectorOptions>(
    initialState && initialValues ? getFilterValueTypeFromFilterInitialValues(initialState.rowFilter, initialValues) : FilterValueSelectorOptions.IS
  );

  const implicitColumnValueType: IColumnValueType = React.useMemo(() => {
    switch (selectedFilterValueTypeOption) {
      case FilterValueSelectorOptions.GREATER_THAN:
      case FilterValueSelectorOptions.LESS_THAN:
      case FilterValueSelectorOptions.BETWEEN:
        return IColumnValueType.Number;

      case FilterValueSelectorOptions.HAPPENS_AFTER:
      case FilterValueSelectorOptions.HAPPENS_BEFORE:
      case FilterValueSelectorOptions.HAPPENS_BETWEEN:
        return IColumnValueType.Date;

      case FilterValueSelectorOptions.IS_NULL:
      case FilterValueSelectorOptions.IS_NOT_NULL:
        return IColumnValueType.Boolean;

      case FilterValueSelectorOptions.IS:
      case FilterValueSelectorOptions.IS_NOT:
      case FilterValueSelectorOptions.EQUALS:
      case FilterValueSelectorOptions.NOT_EQUALS:
      default:
        return IColumnValueType.String;
    }
  }, [selectedFilterValueTypeOption]);

  const isInputDisabled: boolean = React.useMemo(() => {
    return selectedFilterValueTypeOption === FilterValueSelectorOptions.IS_NULL || selectedFilterValueTypeOption === FilterValueSelectorOptions.IS_NOT_NULL;
  }, [selectedFilterValueTypeOption]);

  const handleFilterValueTypeOptionChange = React.useCallback(
    (event: SelectChangeEvent) => {
      setSelectedFilterValueTypeOption(event.target.value as FilterValueSelectorOptions);

      if (event.target.value === FilterValueSelectorOptions.IS_NULL) {
        reset(buildNullRelationalBooleanExpression(expression, false));
      } else if (event.target.value === FilterValueSelectorOptions.IS_NOT_NULL) {
        reset(buildNullRelationalBooleanExpression(expression, true));
      }
    },
    [expression]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", minHeight: "5.2rem" }}>
        <IconButton onClick={handleRemoveSelectedColumn}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="body2">{expressionNameLookup[expression.alias!] ?? expression.alias}</Typography>
        <IconButton onClick={handleClosePopover}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Select
        sx={{ display: "flex", flex: "1 1 auto", ".MuiOutlinedInput-notchedOutline": { border: "none" } }}
        defaultValue={selectedFilterValueTypeOption}
        onChange={handleFilterValueTypeOptionChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        {Object.values(FilterValueSelectorOptions).map((filterValueTypeOption: FilterValueSelectorOptions) => {
          return (
            <MenuItem key={`filter-value-type-option-${filterValueTypeOption}`} value={filterValueTypeOption}>
              <Typography variant="body2">{filterValueTypeOption}...</Typography>
            </MenuItem>
          );
        })}
      </Select>
      <Divider />
      {!isInputDisabled && (
        <>
          {implicitColumnValueType === IColumnValueType.String && (
            <AutocompleteFilterInput expression={expression} filterValueSelectorOption={selectedFilterValueTypeOption} initialValues={initialValues} />
          )}
          {implicitColumnValueType === IColumnValueType.Number && (
            <NumericFilterInput expression={expression} filterValueSelectorOption={selectedFilterValueTypeOption} initialValues={initialValues} />
          )}
          {implicitColumnValueType === IColumnValueType.Boolean && <BooleanFilterInput expression={expression} initialValues={initialValues} />}
          {implicitColumnValueType === IColumnValueType.Date && (
            <DateFilterInput expression={expression} filterValueSelectorOption={selectedFilterValueTypeOption} initialValues={initialValues} />
          )}
        </>
      )}
      <Divider />
      <Button sx={{ margin: "1.2rem" }} variant="contained" type="submit" disabled={!expressionKind}>
        Apply
      </Button>
    </Box>
  );
};
