import {
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetOneParams,
  Identifier,
  Options,
  RaRecord,
  UpdateManyParams,
  UpdateParams,
} from "react-admin";
import { USER_IMPERSONATION_QUERY_PARAM } from "../components/UserImpersonation/UserImpersonationSwitcher";
import { Conversions, getConversions } from "../conversions/Conversions";
import { instanceOfITimestampMetadata } from "../types/common";
import { instanceOfILastSeenFilter } from "../types/recordFilters";
import { IPlatformResource, Resources } from "../types/resources";
import { isWithinLastSeenFilter } from "../utils/recordFilterUtils";
import { adminUrl, analyticsUrl } from "findem-helpers";
import { IGNORE_IMPERSONATION_FLAG, httpClient } from "./customDataProvider";

//-------------------------------------------------------------------------------------------------------------------------------------
// Constants
//-------------------------------------------------------------------------------------------------------------------------------------

const REQUEST_OPTIONS: Options = {
  credentials: "include",
  redirect: "manual",
};

//-------------------------------------------------------------------------------------------------------------------------------------
// Primary Functions
//-------------------------------------------------------------------------------------------------------------------------------------

export default {
  //----------------------------------------------------------------------------------------------------------------------
  // Create
  //----------------------------------------------------------------------------------------------------------------------

  create: async <T extends RaRecord>(resource: Resources, params: CreateParams): Promise<T> => {
    const searchParams: URLSearchParams = new URLSearchParams(location.search);
    const impersonationUser: string | null = searchParams.get(USER_IMPERSONATION_QUERY_PARAM);
    return httpClient(`${getUrl(resource, params)}`, {
      method: "POST",
      body: JSON.stringify({
        ...getConversions(resource).toCreateRequest(params.data),
        ...(!!impersonationUser && { target_user_id: impersonationUser }),
      }),
      ...REQUEST_OPTIONS,
    }).then(({ json }) => {
      const record: RaRecord = getConversions(resource).fromCreateResponse(json);
      return record as T;
    });
  },

  //----------------------------------------------------------------------------------------------------------------------
  // Read
  //----------------------------------------------------------------------------------------------------------------------

  getOne: async <T extends RaRecord>(resource: Resources, params: GetOneParams): Promise<T> => {
    return httpClient(`${getResourceUrl(resource)}/${params.id}`, REQUEST_OPTIONS).then(({ json }) => {
      return getConversions(resource)
        .fromResponseMany(json)
        .find((record) => record.id == params.id) as T;
    });
  },

  getMany: async <T extends RaRecord>(resource: Resources, params: GetManyParams): Promise<T[]> => {
    return httpClient(`${getUrl(resource, params)}`, REQUEST_OPTIONS).then(({ json }) => {
      return getConversions(resource)
        .fromResponseMany(json)
        .filter((record) => params.ids.includes(record.id)) as T[];
    });
  },

  getList: async <T extends RaRecord>(resource: Resources, params: Partial<GetListParams>): Promise<T[]> => {
    return fetchList(resource, params).then((records: RaRecord[]) => {
      return records as T[];
    });
  },

  //----------------------------------------------------------------------------------------------------------------------
  // Update
  //----------------------------------------------------------------------------------------------------------------------

  update: async <T extends RaRecord>(resource: Resources, params: UpdateParams): Promise<T> => {
    const searchParams: URLSearchParams = new URLSearchParams(location.search);
    const impersonationUser: string | null = searchParams.get(USER_IMPERSONATION_QUERY_PARAM);
    return httpClient(`${getUrl(resource, params)}`, {
      method: "PUT",
      body: JSON.stringify({ ...getConversions(resource).toUpdateRequest(params.id, params.data), ...(!!impersonationUser && { target_user_id: impersonationUser }) }),
      ...REQUEST_OPTIONS,
    }).then(({ json }) => params.data as T); // TODO - parse actual response
  },

  updateMany: async <T extends RaRecord>(resource: Resources, params: UpdateManyParams): Promise<T[]> => {
    const conversions: Conversions = getConversions(resource);
    const searchParams: URLSearchParams = new URLSearchParams(location.search);
    const impersonationUser: string | null = searchParams.get(USER_IMPERSONATION_QUERY_PARAM);
    return await Promise.all(
      params.data.map((platformResource: IPlatformResource) => {
        return httpClient(`${getUrl(resource, params)}`, {
          method: "PUT",
          body: JSON.stringify({
            ...conversions.toUpdateRequest(platformResource.resource_id, platformResource),
            ...(!!impersonationUser && { target_user_id: impersonationUser }),
          }),
          ...REQUEST_OPTIONS,
        });
      })
    ).then(() => {
      return params.data as T[];
    });
  },

  //----------------------------------------------------------------------------------------------------------------------
  // Delete
  //----------------------------------------------------------------------------------------------------------------------

  delete: async (resource: Resources, params: DeleteParams): Promise<Identifier> => {
    const searchParams: URLSearchParams = new URLSearchParams(location.search);
    const impersonationUser: string | null = searchParams.get(USER_IMPERSONATION_QUERY_PARAM);
    return httpClient(`${getUrl(resource, params)}`, {
      method: "DELETE",
      body: JSON.stringify({ ...getConversions(resource).toDeleteRequest(params.id), ...(!!impersonationUser && { target_user_id: impersonationUser }) }),
      ...REQUEST_OPTIONS,
    }).then(({ json }) => params.id);
  },

  deleteMany: async (resource: Resources, params: DeleteManyParams): Promise<Identifier[]> => {
    const conversions: Conversions = getConversions(resource);
    const searchParams: URLSearchParams = new URLSearchParams(location.search);
    const impersonationUser: string | null = searchParams.get(USER_IMPERSONATION_QUERY_PARAM);
    return await Promise.all(
      params.ids.map((id: Identifier) => {
        return httpClient(`${getUrl(resource, params)}`, {
          method: "DELETE",
          body: JSON.stringify({ ...conversions.toDeleteRequest(id), ...(!!impersonationUser && { target_user_id: impersonationUser }) }),
          ...REQUEST_OPTIONS,
        });
      })
    ).then(() => {
      return params.ids.map((id: Identifier) => id);
    });
  },
};

//-------------------------------------------------------------------------------------------------------------------------------------
// Internal Helper Functions
//-------------------------------------------------------------------------------------------------------------------------------------

async function fetchList(resource: Resources, params: Partial<GetListParams>): Promise<RaRecord[]> {
  const url = `${getUrl(resource, params)}`;
  return httpClient(url, REQUEST_OPTIONS).then(({ json }) => {
    return filterAndSortRecords(getConversions(resource).fromResponseMany(json), params);
  });
}

function filterAndSortRecords(records: RaRecord[], params: Partial<GetListParams>): RaRecord[] {
  return sortRecords(filterRecords(records, params), params);
}

function filterRecords(records: RaRecord[], params: Partial<GetListParams>): RaRecord[] {
  if (params.filter && instanceOfILastSeenFilter(params.filter)) {
    return records.filter((record: RaRecord) => {
      if (instanceOfITimestampMetadata(record)) {
        return isWithinLastSeenFilter(record, params.filter);
      } else {
        return true;
      }
    });
  } else {
    return records;
  }
}

function sortRecords(records: RaRecord[], params: Partial<GetListParams>): RaRecord[] {
  if (params.sort) {
    const sortField: string = params.sort.field;
    return records.sort((a: RaRecord, b: RaRecord) => {
      return sortField in a && sortField in b
        ? params.sort!.order === "ASC"
          ? String(b[sortField]).localeCompare(String(a[sortField]))
          : String(a[sortField]).localeCompare(String(b[sortField]))
        : 1;
    });
  } else {
    return records;
  }
}

function getUrl(resource: Resources, params: Partial<GetListParams> | GetOneParams | GetManyParams | CreateParams | UpdateParams | DeleteParams | DeleteManyParams): string {
  const searchParams: URLSearchParams = new URLSearchParams(location.search);
  const impersonationUser: string | null = !params.meta?.[IGNORE_IMPERSONATION_FLAG] ? searchParams.get(USER_IMPERSONATION_QUERY_PARAM) ?? searchParams.get("joid") : null;

  const queryParams: Record<string, string> = {
    ...params.meta,
    ...(!!impersonationUser && { target_user_id: impersonationUser }),
  };

  return buildUrlWithQueryParams(getResourceUrl(resource), queryParams);
}

function buildUrlWithQueryParams(base: string, queryParams: Record<string, string>): string {
  const url = new URL(base);
  const searchParams = new URLSearchParams();

  for (const key in queryParams) {
    if (key !== IGNORE_IMPERSONATION_FLAG) {
      searchParams.append(key, queryParams[key]);
    }
  }

  url.search = searchParams.toString();

  return url.toString();
}

function getResourceUrl(resource: Resources): string {
  switch (resource) {
    case Resources.PLATFORM_ACCOUNTS:
      return `${adminUrl}/admin/api/${resource}`;
    case Resources.PLATFORM_USERS_ADMIN:
      return `${adminUrl}/admin/api/platform_users`;
    default:
      return `${analyticsUrl}/analytics/api/${resource}`;
  }
}
