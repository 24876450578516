import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import React from "react";
import { useTranslate } from "react-admin";
import { useFormContext } from "react-hook-form";
import { IExpression } from "../../../../conversions/ReportConversions";
import { IColumnDef } from "../../../../types/object";
import { buildColumnIdentifierFromDef, buildFilterRelationalBooleanExpression } from "../../../../utils/expressionUtils";
import { FilterValueSelectorOptions } from "../FilterSelector";

interface IBooleanFilterInputProps {
  expression?: IExpression;
  column?: IColumnDef;
  initialValues: (string | number | boolean)[] | undefined;
}

export const BooleanFilterInput = (props: IBooleanFilterInputProps) => {
  const { column, expression, initialValues } = props;

  const translate = useTranslate();

  const { reset } = useFormContext();

  const [value, setValue] = React.useState<boolean>(initialValues?.[0] || initialValues?.[0] === undefined ? true : false);

  const handleValueChange = React.useCallback(() => {
    setValue(!value);
  }, [value]);

  const valueLabel: string = React.useMemo(() => {
    return value ? translate("report.fields.boolean.true") : translate("report.fields.boolean.false");
  }, [value, translate]);

  React.useEffect(() => {
    if (column) {
      reset(buildFilterRelationalBooleanExpression(buildColumnIdentifierFromDef(column), value, FilterValueSelectorOptions.IS));
    } else if (expression) {
      reset(buildFilterRelationalBooleanExpression(expression, value, FilterValueSelectorOptions.IS));
    }
  }, [value]);

  return (
    <Box display="flex" justifyContent="center" height="4.8rem">
      <FormControlLabel control={<Switch checked={value} onChange={handleValueChange} />} label={<Typography variant="body2">{valueLabel}</Typography>} />
    </Box>
  );
};
