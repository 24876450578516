import { RaRecord } from "react-admin";
import { IAccessInfo } from "./accessInfo";
import { ITimestampMetadata } from "./common";

export enum Resources {
  DASHBOARDS = "dashboards",
  USERS = "users",
  METRICS = "metrics",
  OBJECTS = "objects",
  CONTACTS = "contacts",
  REPORTS = "reports",
  APPS = "apps",
  SETTINGS = "settings",
  INTEGRATIONS = "integrations",
  CONTACT_NOTES = "contactNotes",
  DEAL_NOTES = "dealNotes",
  USER_NOTES = "userNotes",
  TASKS = "tasks",
  SALES = "sales",
  TAGS = "tags",
  FUNCTIONS = "functions",
  // Settings
  PLATFORM_ROLES = "platform_roles",
  PLATFORM_ACLS = "platform_acls",
  PLATFORM_APPS = "apps",
  PLATFORM_APP_INSTANCES = "app_instances",
  PLATFORM_USERS = "platform_users",
  PLATFORM_COLLECTIONS = "collections",
  ORG_PREFS = "org_prefs",
  OKTA_SSO_CONFIG = "okta_sso_config",
  // Integrations
  DATA_IMPORT = "imports",
  // Admin
  PLATFORM_ACCOUNTS = "platform_accounts",
  PLATFORM_USERS_ADMIN = "platform_users_admin",
  // Misc
  ATS_USERS = "ats_users",
  FILTER_VALUES = "helpers",

  // Insights
  STUDY = "studies",
  STUDY_TEMPLATE = "study_templates",
  STUDY_FILTER = "study_filters",
  STUDY_REQUEST = "study_requests",
}

export enum ResourceType {
  Object = "Object",
  Report = "Report",
  Dashboard = "Dashboard",
  Function = "Function",
  Application = "Application",
  PlatformUser = "PlatformUser",
  PlatformRole = "PlatformRole",
  ApplicationInstance = "ApplicationInstance",
  // Insights
  Study = "study",
  StudyTemplate = "study_template",
  StudyFilter = "study_filter",
  StudyRequest = "StudyRequest",
}

export interface IPlatformResource extends RaRecord, IBasePlatformResource {}

export interface IBasePlatformResource extends ITimestampMetadata {
  resource_id: string;
  resource_name: string;
  resource_type: ResourceType;
  access_info: IAccessInfo;
  schema_version: string;
  resource_description?: string;
  resource_tags?: string[];
}

export enum ResourceScope {
  Self = "self",
  Shared = "shared",
  All = "all",
  Default = "default",
  AccountApplicable = "account_applicable",
}

export interface IPlatformResourceErrorResponse {
  details: IPlatformResourceError;
  message: string;
}

export interface IPlatformResourceError {
  failedOperation: string;
  failedReason: string;
  details: {
    resource: IPlatformResource;
    dependencies?: IPlatformResource[];
    dependents?: IPlatformResource[];
  };
}
