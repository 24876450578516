import { Identifier, RaRecord } from "react-admin";
import { Resources } from "../types/resources";
import { DashboardConversions } from "./DashboardConversions";
import { DataImportConversions } from "./DataImportConversions";
import { ObjectConversions } from "./ObjectConversions";
import { PlatformAccountConversions } from "./PlatformAccountConversions";
import { PlatformAppConversions } from "./PlatformAppConversions";
import { PlatformAppInstanceConversions } from "./PlatformAppInstanceConversions";
import { PlatformCollectionMetadataConversions } from "./PlatformCollectionMetadataConversions";
import { PlatformRoleConversions } from "./PlatformRoleConversions";
import { PlatformUserAdminConversions } from "./PlatformUserAdminConversions";
import { PlatformUserConversions } from "./PlatformUserConversions";
import { ReportConversions } from "./ReportConversions";
import { StudyConversions } from "./insights/StudyConversions";
import { StudyFilterConversions } from "./insights/StudyFilterConversions";
import { StudyTemplateConversions } from "./insights/StudyTemplateConversions";
import { FunctionConversions } from "./FunctionConversions";
import { StudyRequestConversions } from "./insights/StudyRequestConversions";

export interface Conversions {
  fromResponseMany(response: any): RaRecord[];

  fromResponse(response: any): RaRecord;

  fromCreateResponse(response: any): RaRecord;

  toRequest(record: RaRecord): any;

  toUpdateRequest(id: Identifier, record: Partial<RaRecord>): any;

  toCreateRequest(record: Partial<RaRecord>): any;

  toDeleteRequest(id: Identifier): any;
}

export function getConversions(resource: Resources): Conversions {
  switch (resource) {
    case Resources.REPORTS:
      return new ReportConversions();
    case Resources.DASHBOARDS:
      return new DashboardConversions();
    case Resources.OBJECTS:
      return new ObjectConversions();
    case Resources.PLATFORM_USERS:
      return new PlatformUserConversions();
    case Resources.PLATFORM_ROLES:
      return new PlatformRoleConversions();
    case Resources.PLATFORM_APPS:
      return new PlatformAppConversions();
    case Resources.PLATFORM_APP_INSTANCES:
      return new PlatformAppInstanceConversions();
    case Resources.DATA_IMPORT:
      return new DataImportConversions();
    case Resources.PLATFORM_ACCOUNTS:
      return new PlatformAccountConversions();
    case Resources.PLATFORM_USERS_ADMIN:
      return new PlatformUserAdminConversions();
    case Resources.PLATFORM_COLLECTIONS:
      return new PlatformCollectionMetadataConversions();
    case Resources.STUDY:
      return new StudyConversions();
    case Resources.STUDY_FILTER:
      return new StudyFilterConversions();
    case Resources.STUDY_TEMPLATE:
      return new StudyTemplateConversions();
    case Resources.FUNCTIONS:
      return new FunctionConversions();
    case Resources.STUDY_REQUEST:
      return new StudyRequestConversions();
    default:
      throw new Error(`Conversions not yet implemented for resource ${resource}`);
  }
}
