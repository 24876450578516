import { IColumnDef } from "../types/object";
import { INumericAxisType, IProjectionNumericAxis, NumericAxisType } from "../types/report";
import { IAggregateFunctionType, IAliasedExpression, IExpressionType } from "./../conversions/ReportConversions";
import { aliasedExpressionHasNumericOutput, ALIAS_SEPARATOR_TOKEN, getAliasedExpressionId } from "./expressionUtils";

export function getNumericAxisSelectionOptions(projections: IAliasedExpression[]): INumericAxisType[] {
  const projectionAxes: IProjectionNumericAxis[] = projections.flatMap((projection: IAliasedExpression) => {
    return aliasedExpressionHasNumericOutput(projection)
      ? [
          {
            kind: NumericAxisType.Projection,
            projection: projection,
          },
        ]
      : [];
  });
  return [...projectionAxes, { kind: NumericAxisType.RecordCount }];
}

export function getFilteredNumericAxisSelectionOptions(numericAxisSelectionOptions: INumericAxisType[], comparisons: INumericAxisType[]): INumericAxisType[] {
  // Filter out options that are already selected
  return numericAxisSelectionOptions.filter((numericAxisOption: INumericAxisType) => {
    const id: string = getIdFromNumericAxisType(numericAxisOption);
    return comparisons.every((selectedComparisonOption: INumericAxisType) => id !== getIdFromNumericAxisType(selectedComparisonOption));
  });
}

export function getIdFromNumericAxisType(value: INumericAxisType): string {
  switch (value.kind) {
    case NumericAxisType.Projection:
      return getIdFromNumericAxisIdFromProjection(value.projection);
    case NumericAxisType.RecordCount:
    default:
      return NumericAxisType.RecordCount;
  }
}

export function getIdFromNumericAxisIdFromProjection(projection: IAliasedExpression): string {
  return projection.expr.kind === IExpressionType.AggregateExpression
    ? `${getAliasedExpressionId(projection).split(ALIAS_SEPARATOR_TOKEN)[0]}-${projection.expr.function}`
    : getAliasedExpressionId(projection);
}

// TODO: localize
export function getNameFromNumericAxisType(value: INumericAxisType, columnIdToDef: Record<string, IColumnDef>, expressionNameLookup: Record<string, string>): string {
  switch (value.kind) {
    case NumericAxisType.Projection:
      const projectionId: string = getAliasedExpressionId(value.projection).split(ALIAS_SEPARATOR_TOKEN)[0];
      const columnDef: IColumnDef | undefined = columnIdToDef[projectionId];
      const projectionName: string = columnDef?.col_name ?? expressionNameLookup[projectionId] ?? projectionId;

      if (value.projection.expr.kind === IExpressionType.AggregateExpression) {
        return `${getNameFromAggregationType(value.projection.expr.function)} of ${projectionName}`;
      } else {
        return projectionName;
      }
    //TODO: localize
    case NumericAxisType.RecordCount:
    default:
      return "Record Count";
  }
}

// TODO: localize
export function getNameFromAggregationType(aggregationType: IAggregateFunctionType): string {
  switch (aggregationType) {
    case IAggregateFunctionType.Avg:
      return "Average";
    case IAggregateFunctionType.Max:
      return "Max";
    case IAggregateFunctionType.Min:
      return "Min";
    case IAggregateFunctionType.Sum:
      return "Sum";
    default:
      return "Count";
  }
}
