import { Identifier } from "react-admin";
import { AllDash } from "../types";
import { IBasePlatformAccount, IPlatformAccount } from "../types/settings";
import { Conversions } from "./Conversions";

//-------------------------------------------------------------------------------
// Response Interfaces
//-------------------------------------------------------------------------------

export interface IGetPlatformAccountsResponse {
  accounts: IBasePlatformAccount[];
}

export interface ICreatePlatformAccountResponse {
  account_info: IBasePlatformAccount;
}

//-------------------------------------------------------------------------------
// Request Interfaces
//-------------------------------------------------------------------------------

export interface ICreatePlatformAccountRequest {
  payload: Partial<IBasePlatformAccount>;
}

export interface IUpdatePlatformAccountRequest {
  account_id: Identifier;
  payload: Partial<IBasePlatformAccount>;
}

interface IDeletePlatformAccountRequest {
  account_id: Identifier;
}

//-------------------------------------------------------------------------------
// Conversions
//-------------------------------------------------------------------------------

export class PlatformAccountConversions implements Conversions {
  public fromResponseMany(response: IGetPlatformAccountsResponse): IPlatformAccount[] {
    return (response.accounts ?? []).map((platformAccount: IBasePlatformAccount) => {
      return this.fromResponse(platformAccount);
    });
  }

  public fromResponse(response: IBasePlatformAccount): IPlatformAccount {
    return {
      ...response,
      id: response.account_id,
    };
  }

  public fromCreateResponse(response: ICreatePlatformAccountResponse): IPlatformAccount {
    return this.fromResponse(response.account_info);
  }

  public toRequest(platformAccount: IPlatformAccount): IBasePlatformAccount {
    return platformAccount;
  }

  public toUpdateRequest(id: Identifier, dashboard: Partial<AllDash>): IUpdatePlatformAccountRequest {
    return {
      account_id: id,
      payload: this.toRequestPartial(dashboard),
    };
  }

  public toCreateRequest(platformAccount: Partial<IPlatformAccount>): ICreatePlatformAccountRequest {
    return {
      payload: this.toRequestPartial(platformAccount),
    };
  }

  public toDeleteRequest(id: Identifier): IDeletePlatformAccountRequest {
    return {
      account_id: id,
    };
  }

  private toRequestPartial(platformAccount: Partial<IPlatformAccount>): Partial<IPlatformAccount> {
    return platformAccount;
  }
}
