import { Box, Stack } from "@mui/material";
import React, { ReactNode } from "react";
import { useGetList } from "react-admin";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import LoggedInUserContext from "../../context/loggedInUserContext";
import UserContext from "../../context/userContext";
import { IGNORE_IMPERSONATION_FLAG } from "../../dataProvider/customDataProvider";
import { Resources } from "../../types/resources";
import { IPlatformUser } from "../../types/settings";
import { ICurrentUser } from "../../types/users";
import { hasImpersonationAccess, hasUsersView } from "../../utils/accessUtils";
import { USER_IMPERSONATION_QUERY_PARAM, UserImpersonationSwitcher, invalidateQueries } from "./UserImpersonationSwitcher";

export const HIDE_IMPERSONATION_BANNER_QUERY_PARAM: string = "hide_impersonation_banner";

interface IUserImpersonationOverlayProps {
  children: ReactNode;
}

export const UserImpersonationOverlay = (props: IUserImpersonationOverlayProps) => {
  const { children } = props;

  const loggedInUser: ICurrentUser = React.useContext(LoggedInUserContext);
  const { user, setUser } = React.useContext(UserContext);
  const queryClient = useQueryClient();
  const [queryParams, setSearchParams] = useSearchParams();

  const targetUserId: string | null = queryParams.get(USER_IMPERSONATION_QUERY_PARAM) ?? queryParams.get("joid");
  const hideImpersonationBanner: boolean = queryParams.has(HIDE_IMPERSONATION_BANNER_QUERY_PARAM);

  const hasUsersViewRole: boolean = React.useMemo(() => hasUsersView(loggedInUser.roles), [loggedInUser]);

  const { data: platformUsers, isLoading } = useGetList<IPlatformUser>(Resources.PLATFORM_USERS, { meta: { [IGNORE_IMPERSONATION_FLAG]: true } }, { enabled: hasUsersViewRole });

  const selectedUser: IPlatformUser | undefined = React.useMemo(() => {
    return (platformUsers ?? []).find((platformUser: IPlatformUser) => platformUser.resource_id === targetUserId);
  }, [targetUserId, platformUsers]);

  React.useEffect(() => {
    if (selectedUser) {
      setUser({
        uid: selectedUser.resource_id,
        account_id: selectedUser.account_id,
        email: selectedUser.local.email,
        name: selectedUser.resource_name,
        roles: selectedUser.roles,
        company: selectedUser.local.company_name,
        domain: "",
        prid: "",
        account_creation: "",
      });
      invalidateQueries(queryClient);
    } else if (!isLoading) {
      setUser(loggedInUser);
    }
  }, [selectedUser?.resource_id, loggedInUser, isLoading]);

  React.useEffect(() => {
    if (!hasImpersonationAccess(loggedInUser.roles) && queryParams.has(USER_IMPERSONATION_QUERY_PARAM)) {
      queryParams.delete(USER_IMPERSONATION_QUERY_PARAM);
      setSearchParams(queryParams);
    }
  }, [loggedInUser, queryParams]);

  return (
    <Stack direction="column" flex="1 1 auto" overflow="auto">
      {targetUserId && !hideImpersonationBanner && (
        <Stack direction="row" height="52px" minHeight="52px" position="sticky" top={0} zIndex={5} sx={{ background: "linear-gradient(90deg, rgba(68,84,102,1) 0%, rgba(130,83,210,1) 100%)" }}>
          <UserImpersonationSwitcher targetUserId={targetUserId} />
        </Stack>
      )}
      <Box display="flex" flex="1 1 auto" flexDirection="column">
        {children}
      </Box>
    </Stack>
  );
};
