import { StyledEngineProvider } from "@mui/material";
import { getEnvironmentDomain, matchesUrl } from "findem-helpers";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { REFETCH_USER_EVENT } from "../authProvider";
import AnalyticsThemeContext from "../context/analyticsThemeContext";
import EmbeddedContext from "../context/embeddedContext";
import LoggedInUserContext from "../context/loggedInUserContext";
import UserContext from "../context/userContext";
import { httpClient } from "../dataProvider/customDataProvider";
import nonAnalyticsDataProvider from "../dataProvider/nonAnalyticsDataProvider";
import { IEmbedMode, IEmbedModeType } from "../types/embed";
import { IAnalyticsTheme } from "../types/settings";
import { ICurrentUser } from "../types/users";
import { getChartColors } from "../utils/colorUtils";
import { isStaticEmbedMode } from "../utils/embedUtils";

interface IAppProvidersProps {
  embedMode?: IEmbedMode;

  children: ReactNode;
}

const AppProviders = (props: IAppProvidersProps) => {
  const { embedMode, children } = props;

  const isStaticEmbed: boolean = useMemo(() => {
    return !!embedMode && isStaticEmbedMode(embedMode);
  }, [embedMode]);

  if (isStaticEmbed) {
    return <AppProvidersStatic embedMode={embedMode}>{children}</AppProvidersStatic>;
  } else {
    return <AppProvidersMain embedMode={embedMode}>{children}</AppProvidersMain>;
  }
};

const AppProvidersMain = (props: IAppProvidersProps) => {
  const { embedMode, children } = props;

  const [cookies, setCookie] = useCookies(["noCaching", "staging"]);
  const [analyticsTheme, setAnalyticsTheme] = useState<IAnalyticsTheme>({ chartColors: getChartColors() });

  useEffect(() => {
    setCookie("noCaching", "1", { domain: `.${getEnvironmentDomain()}`, path: "/analytics" });
  }, []);

  const [user, setUser] = useState<ICurrentUser>({
    uid: "",
    roles: [] as string[],
  } as ICurrentUser);

  const [loggedInUser, setLoggedInUser] = useState<ICurrentUser>({
    uid: "",
    roles: [] as string[],
  } as ICurrentUser);

  const isEmbeddedIndividualResource: boolean = useMemo(() => {
    return !!embedMode && embedMode.type !== IEmbedModeType.App && embedMode.type !== IEmbedModeType.Insights;
  }, [embedMode]);

  useEffect(() => {
    const updateUserInfo = () =>
      httpClient(`${matchesUrl}/api/user_info`, {
        method: "GET",
        credentials: "include",
      }).then(({ json }) => {
        if (json) {
          setUser(json);
          setLoggedInUser(json);
        }
      });

    updateUserInfo();
    window.addEventListener(REFETCH_USER_EVENT, updateUserInfo);

    //Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener(REFETCH_USER_EVENT, updateUserInfo);
    };
  }, []);

  useEffect(() => {
    nonAnalyticsDataProvider
      .getOrgPrefs(user.uid ? user.uid : undefined)
      .then((orgPrefs) => {
        if (orgPrefs?.data) {
          setAnalyticsTheme({
            ...orgPrefs?.data.theme_prefs,
            chartColors: getChartColors(orgPrefs.data),
          });
        }
      })
      .catch(() => {});
  }, [user.uid]);

  return (
    <StyledEngineProvider injectFirst>
      <EmbeddedContext.Provider value={{ isEmbedded: !!embedMode && embedMode.type !== IEmbedModeType.Insights, isEmbeddedIndividualResource }}>
        <LoggedInUserContext.Provider value={loggedInUser}>
          <UserContext.Provider value={{ user, setUser }}>
            <AnalyticsThemeContext.Provider value={analyticsTheme}>{children}</AnalyticsThemeContext.Provider>
          </UserContext.Provider>
        </LoggedInUserContext.Provider>
      </EmbeddedContext.Provider>
    </StyledEngineProvider>
  );
};

const AppProvidersStatic = (props: IAppProvidersProps) => {
  const { embedMode, children } = props;

  const isEmbeddedIndividualResource: boolean = useMemo(() => {
    return !!embedMode && embedMode.type !== IEmbedModeType.App && embedMode.type !== IEmbedModeType.Insights;
  }, [embedMode]);

  return (
    <StyledEngineProvider injectFirst>
      <EmbeddedContext.Provider value={{ isEmbedded: !!embedMode && embedMode.type !== IEmbedModeType.Insights, isEmbeddedIndividualResource }}>
        {children}
      </EmbeddedContext.Provider>
    </StyledEngineProvider>
  );
};

export default AppProviders;
