import { ChartType } from "../../types/chart";
import { DashboardContentSize } from "../../types/dashboard";
import { IEmbedDisplayOptions } from "../../types/embed";
import { IStaticAdlib, IStaticDashboard } from "./types";

export const DEFAULT_AD_LIB: IStaticAdlib = {
  lines: [
    {
      content: [
        "We are a",
        {
          id: "series",
          placeholder: "select a series",
          options: [
            // { id: "seed", label: "Seed" },
            { id: "a", label: "Series A" },
            { id: "b", label: "Series B" },
            { id: "c", label: "Series C" },
            { id: "d", label: "Series D" },
            { id: "e", label: "Series E" },
          ],
        },
        "company in the",
        {
          id: "sector",
          placeholder: "select a sector",
          options: [
            { id: "b2b_entreprise_software", label: "B2B Enterprise Software (horizontal)" },
            { id: "fintech", label: "Fintech" },
            { id: "core_ai", label: "Core AI/ML" },
            { id: "health_tech", label: "Health Tech / Life Sciences" },
            // { id: "defense", label: "Defense/Resilience" },
            { id: "infrastructure", label: "Infrastructure/DevOps/Data/OS" },
            { id: "security", label: "Security" },
            { id: "vertical_software", label: "Vertical Software" },
            { id: "hardware", label: "Hardware" },
            { id: "mobile", label: "Mobile" },
          ],
        },
        "sector.",
      ],
    },
    {
      content: [
        "We have a",
        {
          id: "sales_motion",
          placeholder: "select a sales motion",
          options: [
            { id: "smb", label: "SMB" },
            { id: "product_lead_growth", label: "PLG" },
            { id: "enterprise", label: "Enterprise" },
          ],
        },
        "sales motion,",
        {
          id: "revenue_range",
          placeholder: "select revenue range",
          options: [
            { id: "1_5", label: "$0-5 million" },
            { id: "5_15", label: "$5-15 million" },
            { id: "15_30", label: "$15-30 million" },
            { id: "30_50", label: "$30-50 million" },
            { id: "50_100", label: "$50-100 million" },
            { id: "100_250", label: "$100-250 million" },
            { id: "250_500", label: "$250-500 million" },
            { id: "500", label: "$500+ million" },
          ],
        },
        "in revenue,",
      ],
    },
    {
      content: [
        "and a",
        {
          id: "valuation_range",
          placeholder: "select select valuation range",
          options: [
            { id: "0_25", label: "$0-25 million" },
            { id: "25_100", label: "$25-100 million" },
            { id: "100_500", label: "$100-500 million" },
            { id: "500_1000", label: "$500 million - $1 billion" },
            { id: "1000_5000", label: "$1-5 billion" },
            { id: "5000", label: "$5+ billion" },
          ],
        },
        "valuation.",
      ],
    },
  ],
  submitText: "SHOW BENCHMARK",
  redirectUrl: "https://felicis-ventures.webflow.io/benchmark-dashboard",
};

export const DEFAULT_STATIC_DASHBOARD: IStaticDashboard = {
  resource_name: "Your Benchmark analysis",
  resource_description: "This analysis compiles data from similar companies to provide a benchmark for how your company might grow in the future.",
  reports: [
    {
      resource_id: "s0_headcount",
      resource_name: "Headcount",
      icon: "groups",
      chartType: ChartType.Summary,
      contentSize: DashboardContentSize.MEDIUM,
      dataKeys: ["name"],
      dataPoints: [{ name: "2.5K" }],
      chartSettings: { numDecimals: 0 },
    },
    {
      resource_id: "hc_growth",
      resource_name: "Series A to B Headcount Growth",
      icon: "trending_up",
      chartType: ChartType.Summary,
      contentSize: DashboardContentSize.MEDIUM,
      dataKeys: ["name"],
      dataPoints: [{ name: "28%" }],
      chartSettings: { renderAsPercentage: true, numDecimals: 0 },
    },
    {
      resource_id: "hc_attrition",
      resource_name: "Series A to B Attrition Rate",
      icon: "waving_hand",
      chartType: ChartType.Summary,
      contentSize: DashboardContentSize.MEDIUM,
      dataKeys: ["waving_hand"],
      dataPoints: [{ name: "25%" }],
      chartSettings: { renderAsPercentage: true, numDecimals: 0 },
    },
    {
      resource_id: "s1_hc_by_function",
      resource_name: "Headcount by Function",
      icon: "scatter_plot",
      chartType: ChartType.NewPie,
      contentSize: DashboardContentSize.MEDIUM_HALF,
      dataKeys: ["Headcount"],
      dataPoints: [
        { name: "Product", Headcount: 50 },
        { name: "Engineering", Headcount: 100 },
        { name: "Sales", Headcount: 120 },
        { name: "Marketing", Headcount: 20 },
        { name: "CS", Headcount: 80 },
        { name: "Finance", Headcount: 30 },
      ],
      chartSettings: { colorOverrides: ["#E2D4F4", "#816B9D", "#ED843D", "#FFA451", "#FFCD6B", "#FFDFC2", "#F3EEE8", "#c1aa8e", "#333333", "#744a2c"], numDecimals: 0, showPercentages: true },
    },
    {
      resource_id: "hc_by_role",
      resource_name: "Management vs Individual Contributors",
      icon: "hdr_strong",
      chartType: ChartType.Column,
      contentSize: DashboardContentSize.MEDIUM_HALF,
      dataKeys: ["Series A", "Series B"],
      dataPoints: [
        { name: "Product", "Series A": 80, "Series B": 100 },
        { name: "Engineering", "Series A": 70, "Series B": 75 },
        { name: "Sales", "Series A": 50, "Series B": 60 },
        { name: "Marketing", "Series A": 40, "Series B": 45 },
        { name: "CS", "Series A": 30, "Series B": 35 },
        { name: "Finance", "Series A": 20, "Series B": 25 },
      ],
      chartSettings: { roundedBars: true, colorOverrides: ["#E2D4F4", "#816B9D"], numDecimals: 0 },
      infoMessage: "Directors and above are classified as management",
    },
    {
      resource_id: "hc_growth_by_function",
      resource_name: "Headcount Growth by Function",
      icon: "group_add",
      chartType: ChartType.Column,
      contentSize: DashboardContentSize.LARGE,
      dataKeys: ["Series A", "Series B"],
      dataPoints: [
        { name: "Product", "Series A": 80, "Series B": 100 },
        { name: "Engineering", "Series A": 70, "Series B": 75 },
        { name: "Sales", "Series A": 50, "Series B": 60 },
        { name: "Marketing", "Series A": 40, "Series B": 45 },
        { name: "CS", "Series A": 30, "Series B": 35 },
        { name: "Finance", "Series A": 20, "Series B": 25 },
      ],
      chartSettings: { nonStacked: true, roundedBars: true, colorOverrides: ["#FFDFC2", "#FFA451"], numDecimals: 0 },
    },
    {
      resource_id: "hc_attrition_by_function",
      resource_name: "Attrition by Function",
      icon: "group_remove",
      chartType: ChartType.Column,
      contentSize: DashboardContentSize.LARGE,
      dataKeys: ["Series A", "Series B"],
      dataPoints: [
        { name: "Product", "Series A": 80, "Series B": 100 },
        { name: "Engineering", "Series A": 70, "Series B": 75 },
        { name: "Sales", "Series A": 50, "Series B": 60 },
        { name: "Marketing", "Series A": 40, "Series B": 45 },
        { name: "CS", "Series A": 30, "Series B": 35 },
        { name: "Finance", "Series A": 20, "Series B": 25 },
      ],
      chartSettings: { nonStacked: true, roundedBars: true, colorOverrides: ["#c1aa8e", "#F4A85F"], numDecimals: 0 },
    },
  ],
  filters: [
    {
      id: "series",
      label: "Series",
      options: [
        // { id: "seed", label: "Seed", dataKey: "seed" },
        { id: "a", label: "Series A", airtableKey: "A", dataKey: "series a" },
        { id: "b", label: "Series B", airtableKey: "B", dataKey: "series b" },
        { id: "c", label: "Series C", airtableKey: "C", dataKey: "series c" },
        { id: "d", label: "Series D", airtableKey: "D", dataKey: "series d" },
        { id: "e", label: "Series E", airtableKey: "E+", dataKey: "series e" },
        { id: "e+", label: "Series E+", dataKey: "series e+" },
      ],
    },
    {
      id: "sector",
      label: "Sector",
      options: [
        { id: "b2b_entreprise_software", label: "B2B Enterprise Software (horizontal)", dataKey: "B2B Enterprise Software" },
        { id: "fintech", label: "Fintech", dataKey: "Fintech" },
        { id: "core_ai", label: "Core AI/ML", dataKey: "Core AI/ML" },
        { id: "health_tech", label: "Health Tech / Life Sciences", dataKey: "Health Tech/Life Sciences" },
        // { id: "defense", label: "Defense/Resilience", dataKey: "Defense/Resilience" },
        { id: "infrastructure", label: "Infrastructure/DevOps/Data/OS", dataKey: "Infrastructure/DevOps/Data/OS" },
        { id: "security", label: "Security", dataKey: "Security" },
        { id: "vertical_software", label: "Vertical Software", dataKey: "Vertical Software" },
        { id: "hardware", label: "Hardware", dataKey: "Hardware" },
        { id: "mobile", label: "Mobile", dataKey: "Mobile" },
      ],
    },
    {
      id: "sales_motion",
      label: "Sales Motion",
      options: [
        { id: "smb", label: "SMB", dataKey: "SMB" },
        { id: "product_lead_growth", label: "PLG", dataKey: "PLG" },
        { id: "enterprise", label: "Enterprise", dataKey: "ENT" },
      ],
    },
    {
      id: "revenue_range",
      label: "Revenue",
      options: [
        { id: "1_5", label: "$0-5 million", airtableKey: "1-5M" },
        { id: "5_15", label: "$5-15 million", airtableKey: "5-15M" },
        { id: "15_30", label: "$15-30 million", airtableKey: "15-30M" },
        { id: "30_50", label: "$30-50 million", airtableKey: "30-50M" },
        { id: "50_100", label: "$50-100 million", airtableKey: "50-100M" },
        { id: "100_250", label: "$100-250 million", airtableKey: "100-250M" },
        { id: "250_500", label: "$250-500 million", airtableKey: "250-500M" },
        { id: "500", label: "$500+ million", airtableKey: "500+" },
      ],
      adlibOnly: true,
    },
    {
      id: "valuation_range",
      label: "Valuation",
      options: [
        { id: "0_25", label: "$0-25 million", airtableKey: "0-25M" },
        { id: "25_100", label: "$25-100 million", airtableKey: "25-100M" },
        { id: "100_500", label: "$100-500 million", airtableKey: "100-500M" },
        { id: "500_1000", label: "$500 million - $1 billion", airtableKey: "500M-1B" },
        { id: "1000_5000", label: "$1-5 billion", airtableKey: "1B-5B" },
        { id: "5000", label: "$5+ billion", airtableKey: "5B+" },
      ],
      adlibOnly: true,
    },
  ],
  adlib: [
    "We are a ",
    { id: "series" },
    " company in the ",
    { id: "sector" },
    " sector. We have a ",
    { id: "sales_motion" },
    " sales motion, ",
    { id: "revenue_range" },
    " in revenue, and a ",
    { id: "valuation_range" },
    " valuation.",
  ],
  startOverUrl: "https://felicis-ventures.webflow.io/benchmark",
};

export const DEFAULT_AD_LIB_DISPLAY_OPTIONS: IEmbedDisplayOptions = {
  themeOverrides: {
    typography: {
      fontFamily: "DM Sans, sans-serif",
      body1: {
        fontSize: "24px",
        fontWeight: 500,
        color: "#453E39",
      },
    },
    palette: {
      primary: {
        main: "#654A96",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#E1894B",
        contrastText: "#FFFFFF",
      },
      text: {
        primary: "#453E39",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          textPrimary: {
            fontSize: "16px",
            fontWeight: 700,
            textTransform: "uppercase",
            color: "#333",
            "&:hover": {
              backgroundColor: "#F8F8F8",
              color: "#E1894B",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          standard: {
            lineHeight: "18px",
            minHeight: "unset",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: "24px",
          },
        },
      },
    },
  },
};

export const DEFAULT_DASHBOARD_DISPLAY_OPTIONS: IEmbedDisplayOptions = {
  themeOverrides: {
    typography: {
      fontFamily: "DM Sans, sans-serif",
      body2: {
        fontSize: "10px",
      }
    },
    palette: {
      primary: {
        main: "#654A96",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#E1894B",
        contrastText: "#FFFFFF",
      },
      text: {
        primary: "#453E39",
      },
      background: {
        default: "unset",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          textPrimary: {
            color: "#333",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          standard: {
            lineHeight: "18px",
            minHeight: "unset",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: "24px",
          },
        },
      },
    },
  },
};

export const FUNCTION_MAPPING: Record<string, string> = {
  engineering: "Engineering",
  it: "Engineering",
  "information technology": "Engineering",
  sales: "Sales",
  "business development": "Sales",
  "customer service and support": "CS",
  "customer success and support": "CS",
  legal: "Legal",
  marketing: "Marketing",
  "media and communications": "Marketing",
  product: "Product",
  "product management": "Product",
  "human resources": "HR",
  finance: "Finance",
  // accounting: "Finance",
  operations: "Operations",
  "project and program management": "Operations",
};
