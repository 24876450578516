import { IStudyFilter } from "../../insights/insightsTypes";
import { InsightsBaseConversions } from "./InsightsBaseConversions";

export class StudyFilterConversions extends InsightsBaseConversions<IStudyFilter> {
  public fromResponseMany(response: { study_filters: IStudyFilter[] }): IStudyFilter[] {
    return (response.study_filters ?? []).map((resource: IStudyFilter) => {
      return this.fromResponse(resource);
    });
  }

  public fromCreateResponse(response: { study_filter_create: IStudyFilter }): IStudyFilter {
    return this.fromResponse(response.study_filter_create);
  }
}
