import { Identifier } from "react-admin";
import { AllDash } from "../types";
import { IBasePlatformUser, IPlatformUser } from "../types/settings";
import { Conversions } from "./Conversions";

//-------------------------------------------------------------------------------
// Response Interfaces
//-------------------------------------------------------------------------------

export interface IGetPlatformUsersResponse {
  platform_users: IBasePlatformUser[];
}

export interface ICreatePlatformUserResponse {
  created_user: IBasePlatformUser;
}

//-------------------------------------------------------------------------------
// Request Interfaces
//-------------------------------------------------------------------------------

export interface ICreatePlatformUserRequest {
  payload: Partial<IBasePlatformUser>;
}

export interface IUpdatePlatformUserRequest {
  resource_id: Identifier;
  update_payload: Partial<IPlatformUser>;
}

interface IDeletePlatformUserRequest {
  resource_id: Identifier;
}

//-------------------------------------------------------------------------------
// Conversions
//-------------------------------------------------------------------------------

export class PlatformUserConversions implements Conversions {
  public fromResponseMany(response: IGetPlatformUsersResponse): IPlatformUser[] {
    return (response.platform_users ?? []).map((platformUser: IBasePlatformUser) => {
      return this.fromResponse(platformUser);
    });
  }

  public fromResponse(response: IBasePlatformUser): IPlatformUser {
    return {
      ...response,
      id: response.resource_id,
    };
  }

  public fromCreateResponse(response: ICreatePlatformUserResponse): IPlatformUser {
    return this.fromResponse(response.created_user);
  }

  public toRequest(platformUser: IPlatformUser): IBasePlatformUser {
    return platformUser;
  }

  public toUpdateRequest(id: Identifier, dashboard: Partial<IPlatformUser>): IUpdatePlatformUserRequest {
    return {
      resource_id: id,
      update_payload: this.toRequestPartial(dashboard),
    };
  }

  public toCreateRequest(platformUser: Partial<IPlatformUser>): ICreatePlatformUserRequest {
    return {
      payload: this.toRequestPartial(platformUser),
    };
  }

  public toDeleteRequest(id: Identifier): IDeletePlatformUserRequest {
    return {
      resource_id: id,
    };
  }

  private toRequestPartial(platformUser: Partial<IPlatformUser>): Partial<IPlatformUser> {
    return platformUser;
  }
}
