import { unescape as lodashUnescape } from "lodash";

/**
 * Computes a new string in which both hexadecimal and html escape sequeces are replaced with the character that it represents.
 *
 * Calls both lodash unescape & es5 unescape.
 * Lodash handles the unescaping of html entites: &, <, >, ", ', and `.
 * es5 handles the unescaping of hex escape sequences.
 *
 */
export function decodeString(value: string): string;
export function decodeString(value: undefined): undefined;
export function decodeString(value: string | undefined): string | undefined;
export function decodeString(value: string | undefined): string | undefined {
  return value ? lodashUnescape(unescape(value)) : value;
}

export function isNumeric(str: string | number): boolean {
  return !isNaN(parseFloat(String(str))) && isFinite(Number(str));
}

export function capitalizeWords(value: string): string {
  return value.replace(/\b\w/g, (char: string) => char.toUpperCase());
}
export function replaceLastInstance(originalString: string, searchValue: string, replaceValue: string): string {
  const lastIndex = originalString.lastIndexOf(searchValue);
  
  // If the searchValue is not found, return the original string
  if (lastIndex === -1) {
      return originalString;
  }
  
  // Slice the string into two parts and insert the replaceValue between them
  const start = originalString.substring(0, lastIndex);
  const end = originalString.substring(lastIndex + searchValue.length);
  
  return start + replaceValue + end;
}

export function capitalizeName(name: string | undefined): string {
  return (
    name
      ?.trim()
      .split(" ")
      .map((word) => (word === "ii" || word.length <= 1 ? word.toUpperCase() : word[0].toUpperCase() + word.slice(1)))
      .join(" ") ?? ""
  );
}
