import ColorsJson from "./colors.json";
import DimensionsJson from "./dimensions.json";
import TypographyJson from "./typography.json";

export const colors = {
  ...ColorsJson.colors,
};

export const typography = {
  ...TypographyJson.typography,
};

export const dimensions = {
  ...DimensionsJson.dimensions,
};
