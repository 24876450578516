/**
 * Returns true if all elements {arr2} are present in {arr1}
 *
 * @param arr1
 * @param arr2
 * @returns
 */
export function containsAllElements<T>(arr1: T[], arr2: T[]): boolean {
  return arr2.every((element: T) => arr1.includes(element));
}
