import { useRecordContext } from "react-admin";
import { ReactElement } from "react";

export const GenericWithRecord = ({ render }: GenericWithRecordProps) => {
  const record = useRecordContext();
  return record ? render(record) : null;
};

export interface GenericWithRecordProps {
  render: (record: any) => ReactElement;
}
