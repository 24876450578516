import { IExpression } from "../conversions/ReportConversions";

export enum IParameterizationType {
  Column = "column",
  DerivedColumn = "derived_column",
  FindemUserId = "findem_user_id",
  AtsUserId = "ats_user_id",
  Time = "time",
}

export interface IParameterizationInfo {
  parameterName: string;
  expression: IExpression;
}
